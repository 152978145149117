import { useApp, useRoute } from 'signals';
import { Foot, Grid10 } from 'components';
import { is } from 'aios';
import { findAiosNode } from 'app';
import { AppActionFootInput } from './AppActionFootInput';

export const AppActionFoot = (): JSX.Element => {
  const { root } = useApp();
  const { path } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (<div>AppFoot no path</div>);
  }
  const node = findAiosNode(root, path);
  if (!is(node)) {
    return (<div>AppFoot no node</div>);
  }
  return (
    <Foot>
      <Grid10>
        <AppActionFootInput node={node} />
      </Grid10>
    </Foot>
  );
};