import { cancelApi, startState, setApi, setStatus, setCache } from 'app';
import { type AiosNode } from 'app/node';
import { AppState } from 'signals/AppState/AppState';

export async function doCode(node: AiosNode, input?: string): Promise<void> {
  node.input = input;
  setStatus(node, { code: startState, working: true });
  setApi(node);
  await setCache(node);
  AppState.current.refresh();
}

export async function doCodeCancel(node: AiosNode): Promise<void> {
  cancelApi(node);
  setStatus(node, { code: startState, working: true });
  await setCache(node);
  AppState.current.refresh();
}

