import { is, AiosType, createAiosPath, AiosDataCommand, AiosStatus, type AiosException, createAiosDataInput, createAiosDataOutput, type AiosData, isFail } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus, setStatus } from '../../status';
import { setApi } from 'app';
import { createAiosClientApp } from 'utl';
import { setContent } from './setContent';
import { getContent } from './getContent';

export interface ChatProcess {
    text: string;
    files?: Array<{ name: string; text: string }>;
}

export async function chatNodeData(node: AiosNode): Promise<boolean> {
    const { chat, chatData } = getStatus(node);
    if (chatData?.do === false || is(chatData?.done)) {
        return false;
    }
    const text = node.input;
    const files = getContent(node);
    const obj = { text, files };
    const json = JSON.stringify(obj);
    const input = createAiosDataInput({
        type: AiosType.ActionChat,
        item: { id: 'user', text, json },
        command: AiosDataCommand.Chat
    });
    const output = createAiosDataOutput<AiosData>({
        type: AiosType.ActionChat,
        item: { id: 'aios', text: '...' },
        status: AiosStatus.Processing,
    });
    setApi(node, { id: chat?.id, input, output });
    let chatOutput;
    try {
        const path = createAiosPath({ path: node.path.path, action: 'chat' });
        node.api.client = await createAiosClientApp();
        chatOutput = await node.api.client.send({ path, input });
        output.override(chatOutput);
    } catch (exception) {
        output.setFail({ text, exception: exception as AiosException });
    } finally {
        node.api.client = undefined;
    }
    if (!is(chatOutput) || isFail(chatOutput.status)) {
        setStatus(node, {
            chatData: { do: false, done: false, doing: false },
        });
        setApi(node, { id: chat?.id, input, output });
        return false;
    }
    setStatus(node, {
        chatData: { do: false, done: true },
        working: false
    });
    setApi(node, { id: chat?.id, input, output });
    const outputJson = chatOutput.item?.text;
    if (is(outputJson)) {
        const process: ChatProcess = JSON.parse(outputJson) as ChatProcess;
        const { text, files } = process;
        output.text = await setContent(node, text, files);
    }
    return true;
}