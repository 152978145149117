export enum AiosStatus {
  None = '',
  Ok = 'Ok',
  OkCreated = 'OkCreated',
  OkExists = 'OkExists',
  OkCache = 'OkCache',
  Processing = 'Processing',
  Fail = 'Fail',
  FailAuth = 'FailAuth',
  FailAccess = 'FailAccess',
  FailCancel = 'FailCancel',
  FailService = 'FailService',
  FailInvalid = 'FailInvalid',
  FailNoItems = 'FailNoItems',
  FailNotFound = 'FailNotFound',
  FailExists = 'FailExists',
  FailNotEmpty = 'FailNotEmpty',
  FailUnavailable = 'FailUnavailable',
  FailAuthExpired = 'FailAuthExpired',
  FailTimeout = 'FailTimeout',
}

export const isOk = (status?: AiosStatus): boolean => {
  switch (status) {
    case AiosStatus.Ok:
    case AiosStatus.OkCreated:
    case AiosStatus.OkExists:
    case AiosStatus.OkCache:
      return true;
  }
  return false;
};

export const isFail = (status?: AiosStatus): boolean => {
  switch (status) {
    case AiosStatus.Fail:
    case AiosStatus.FailAuth:
    case AiosStatus.FailCancel:
    case AiosStatus.FailAccess:
    case AiosStatus.FailService:
    case AiosStatus.FailInvalid:
    case AiosStatus.FailNoItems:
    case AiosStatus.FailNotFound:
    case AiosStatus.FailExists:
    case AiosStatus.FailUnavailable:
    case AiosStatus.FailAuthExpired:
      return true;
  }
  return false;
};

export const isWait = (status?: AiosStatus): boolean => {
  switch (status) {
    case AiosStatus.Processing:
      return true;
  }
  return false;
};
