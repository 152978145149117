import { AiosStatus, getId, is } from 'aios';
import { type AiosNode } from 'app/node';
import { removeNodeData } from './removeNodeData';
import { getStatus, setStatus } from 'app/status';
import { getPath, setApi } from 'app';

export async function removeNodeLast(node: AiosNode): Promise<boolean | undefined> {
    const { removeLast, removeData, removeSkip } = getStatus(node);
    if (!is(removeLast?.do)) {
        return removeLast?.done;
    }
    if (is(removeLast?.done)) {
        return true;
    }
    if (!is(removeLast?.doing)) {
        setStatus(node, {
            removeLast: { doing: true },
        });
        setApi(node, {
            id: removeLast?.id,
            output: { text: `deleting. ${getPath(node)}`, status: AiosStatus.Processing }
        });
        return undefined;
    }
    if (is(node.nodes)) {
        const results = await Promise.all(node.nodes.map(async child => {
            setStatus(child, { removeLast: { id: removeLast?.id, do: true } });
            return await removeNodeLast(child);
        }));
        if (is(results.some(result => result === false))) {
            setStatus(node, { removeLast: { do: false, done: false, doing: false } });
            return false;
        }
        if (is(results.some(result => result === undefined))) {
            return undefined;
        }
    }
    if (is(removeSkip?.do)) {
        setStatus(node, { removeLast: { do: false, done: true, doing: false } });
        return true;
    }
    if (!is(removeData?.do)) {
        const id = getId();
        setStatus(node, {removeData: { id, do: true }});
        setApi(node, { id, output: { text: `deleting data ${getPath(node)}`, status: AiosStatus.Processing }});
        return undefined;
    }
    const done = await removeNodeData(node);
    if (done === undefined) {
        return undefined;
    }
    if (!is(done)) {
        setStatus(node, { removeLast: { id: removeLast?.id, do: false, done: false, doing: false } });
        return false;
    }
    setStatus(node, { removeLast: { id: removeLast?.id, do: false, done: true, doing: false } });
    return true;
}
