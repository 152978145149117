import { type AiosClient, type AiosAuth, createAiosClient, is } from 'aios';
import { getConfig } from './getConfig';
import { getAuth } from 'app/node/aios/authNode';

export async function createAiosClientApp(options?: Partial<AiosClient>): Promise<AiosClient> {
  let auth;
  if (!is(auth) && is(options?.auth)) {
    auth = options?.auth as AiosAuth;
  } else {
    auth = await getAuth();
  }
  const aiosClient = createAiosClient({ ...options, auth, url: getConfig('REACT_APP_AIOS_API') });
  return aiosClient;
}

export default createAiosClientApp;
