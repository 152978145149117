import { type AiosNode } from '../node';
import { getData } from '../data';
import { is, isFolder } from 'aios';
import { sortByName, sortByVersion } from './util';

export function sortNode(node: AiosNode): void {
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    node.nodes = sortNodes(nodes);
};

export function sortNodes(nodes: AiosNode[]): AiosNode[] {
    const files: AiosNode[] = [];
    const folders: AiosNode[] = [];
    const versions: AiosNode[] = [];
    for (const item of nodes) {
        if (is(item.path.version)) {
            versions.push(item);
            continue;
        }
        const { assetFileType } = getData(item);
        if (isFolder(assetFileType)) {
            folders.push(item);
            continue
        }
        files.push(item);
    }
    files.sort(sortByName);
    folders.sort(sortByName);
    versions.sort(sortByVersion);
    const sorted = [...folders, ...files, ...versions];
    return sorted;
};