import { AiosStatus, getId, is } from 'aios';
import { type AiosNode } from '../../node';
import { loadNodeNext } from './loadNodeNext';
import { getStatus, setStatus } from '../..//status';
import { setApi } from '../../api';
import { getPath } from 'app/util';
import { updateOwner } from '../aios/authNode';
import { setCache } from 'app/cache';

export async function loadNode(node: AiosNode): Promise<void> {
  const { load, loadData, loadEx } = getStatus(node);
  if (is(loadData?.doing)) {
    setStatus(node, { working: false, refresh: false });
    return;
  }
  if (load?.do === false || is(load?.done)) {
    setStatus(node, { working: false, refresh: false });
    return;
  }
  if (!is(load?.doing)) {
    if (load?.doing === undefined) {
      const id = getId();
      setStatus(node, {
        load: { id, do: true, doing: true },
        loadNext: { id, do: true, doing: undefined, done: undefined },
        loadMore: is(loadEx) ? { do: true, doing: undefined, done: undefined } : undefined,
        working: true,
      });
      setApi(node, {
        id,
        output: {
          text: `loading ${getPath(node)}`,
          status: AiosStatus.Processing,
        }
      });
    }
    return;
  }
  const done = await loadNodeNext(node);
  if (done === undefined) {
    return;
  }
  if (!is(done)) {
    setStatus(node, {
      load: { do: false, doing: false }, working: false, refresh: true
    });
    return;
  }
  updateOwner(node);
  setStatus(node, {
    load: { do: false, doing: false, done: true },
    loadData: { do: false },
    loadNext: { do: false },
    loadMore: { do: false, doing: false },
    working: false,
    refresh: true
  });
  setApi(node, {
    id: load?.id, output: {
      text: `loaded ${getPath(node)}`,
      status: AiosStatus.Ok
    }
  });
  await setCache(node);
}

