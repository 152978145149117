import { Grid10Row, Grid10Cell } from 'components';
import { AppState } from 'signals/AppState/AppState';
import { type AppDataProps, type AppDataType } from '../AppData/AppDataType';
import { getData, setData, getUi, setCache } from 'app';
import { AppDataForm, AppDataCode, AppDataMarkdown } from '../AppDataFiles';
import { is, isText } from 'aios';

export const AppDataNodeBody: AppDataType = ({ node }: AppDataProps) => {
  const { form, body, view, mode, more, expand, disabled } = getUi(node);
  if (is(form)) {
    return form();
  }
  if (!is(body) || !is(view) || more === 'more') {
    return <></>;
  }
  const { assetFileType, assetFileText, assetFileBase, assetFileMime, jsonText } = getData(node);
  let diffText: string | undefined;
  let diffJsonText: string | undefined;
  const copy = node.copies?.[0];
  if (is(copy)) {
    const { assetFileText: storedText, assetFileBase: storedBase, jsonText: storedJson } = getData(copy);
    diffText = isText(assetFileType) ? storedText : storedBase;
    diffJsonText = storedJson;
  }
  const renderBody = (): JSX.Element => {
    if (mode === 'data') {
      const onValue = (v?: string): void => {
        setData(node, { jsonText: v });
        void setCache(node);
        AppState.current.refresh();
      };
      switch (view) {
        case 'form':
          return (<AppDataForm node={node} />);
        case 'code':
          return (<AppDataCode node={node} type='json' diff={diffJsonText} view={view} value={jsonText as string} onValue={onValue} disabled={disabled} expand={expand} />);
        case 'diff':
          return (<AppDataCode node={node} type='json' diff={diffJsonText} view={view} value={jsonText as string} onValue={onValue} disabled={disabled} expand={expand} />);
      }
      return (<div>view not supported</div>);
    }
    const onValue = (v?: string): void => {
      if (isText(assetFileType)) {
        setData(node, { assetFileText: v });
      } else {
        setData(node, { assetFileBase: v });
      }
      void setCache(node);
      AppState.current.refresh();
    };
    const useText = isText(assetFileType) ? assetFileText : assetFileBase;
    switch (view) {
      case 'code':
        return (<AppDataCode node={node} diff={diffText} view={view} value={useText as string} onValue={onValue} disabled={disabled} expand={expand} />);
      case 'diff':
        return (<AppDataCode node={node} diff={diffText} view={view} value={useText as string} onValue={onValue} disabled={disabled} expand={expand} />);
      case 'markdown':
        return (<AppDataMarkdown node={node} value={useText as string} onValue={onValue} disabled={disabled} />);
      case 'text':
        return (<p>{useText as string}</p>);
      case 'image':
        return (<img src={`data:${assetFileMime as string};base64,${assetFileBase as string}`} />);
    }
    return (<div>view not supported</div>);
  }
  let spany = 4;
  if (expand === 'collapse') {
    spany = 14;
  }
  let border = '1px solid white';
  if (is(disabled)) {
    border = '1px solid grey';
  }
  return (
    <Grid10Row>
      <Grid10Cell spanx={10} spany={spany}>
        <div style={{ border, padding: '10px', height: '100%', overflowY: 'auto' }}>
          {renderBody()}
        </div>
      </Grid10Cell>
    </Grid10Row>
  );
}
