import { AiosType } from '../AiosType';
import type { AiosData } from './AiosData';
import { set } from '../functions';

export enum AiosDataCommand {
  None = '',
  Auth = 'Auth',
  Save = 'Save',
  Load = 'Load',
  Remove = 'Remove',
  Chat = 'Chat',
  Code = 'Code',
  Mail = 'Mail',
}

export interface AiosDataInput<T extends AiosData> {
  text?: string;
  type?: AiosType;
  item?: T;
  command?: AiosDataCommand;
}

export function createAiosDataInput<T extends AiosData>(
  options?: Partial<AiosDataInput<T>>,
): AiosDataInput<T> {
  const result = {
    text: set(options?.text, undefined),
    type: set(options?.type, AiosType.None),
    item: set(options?.item, undefined),
    command: set(options?.command, undefined),
  };
  return result;
}
