import { is, isFolder } from 'aios';
import { type AiosNode } from '../../node';
import { getData } from '../../data';

export function getContent(node: AiosNode): Array<{ name: string; text: string }> {
    const files: Array<{ name: string; text: string }> = [];
    const { assetPath, assetFileText, assetFileType } = getData(node);
    if (!isFolder(assetFileType)) {
        if (is(assetPath) && is(assetFileText)) {
            files.push({ name: assetPath, text: assetFileText });
        }
        return files;
    }
    const { nodes } = node;
    if (!is(nodes)) {
        return files;
    }
    for (let i = 0; i < nodes.length; i++) {
        const child = nodes[i];
        const { assetPath, assetFileText } = getData(child);
        if (is(assetPath) && is(assetFileText)) {
            files.push({ name: assetPath, text: assetFileText });
        }
    }
    return files;
}