import { is, set, isFail, isFolder, AiosType } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { type AiosNodeUi } from './AiosNodeUi';
import { getUiSchema } from './getUiSchema';
import { getIcon, getModeAndViewEx, getPath } from '../../util';
import { getFunctions } from './getFunctions';
import { getApi } from 'app/api';
import { getData } from 'app/data';

export function getUiEditEx(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const parent = node.parent;
    if (!is(parent)) {
        return ui;
    }
    if (!is(parent.status.editEx)) {
        const { assetFileType } = getData(node);
        if (isFolder(assetFileType)) {
            return getUiEditExFolder(node, ui, wrap);
        }
        return getUiEditExFile(node, ui, wrap);
    }
    const { assetFileType } = getData(parent);
    if (isFolder(assetFileType)) {
        return getUiEditExFolderFile(node, ui);
    }
    return getUiEditExFileItem(node, ui);

}

export function getUiEditExFolder(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const functions = getFunctions(node);
    const { status } = getApi(node);
    const { createEx } = getStatus(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.onPath = functions.onPath;
    if (!isFail(status)) {
        if (is(createEx)) {
            ui.note = '[new+]';
        } else {
            ui.note = '[edit+]';
        }
    } else {
        ui.note = '[error+]';
    }
    ui.mode = set(ui.mode, 'data');
    ui.onMode = functions.onMode;
    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.body = true;
    ui.schema = getUiSchema(node);
    ui.foot = true;
    if (wrap !== false) {
        const { copies, copyIndex } = node;
        if (is(copies) && copyIndex !== undefined) {
            ui.onBack = functions.onBack;
            ui.index = `${copyIndex + 1} of ${copies.length}`;
            ui.onNext = functions.onNext;
            if (copyIndex === 0) {
                ui.footNote = 'original';
            } else {
                ui.footNote = 'draft';
            }
        }
    }
    if (wrap !== true) {
        if (ui.mode === 'data') {
            ui.view = 'form';
        } else {
            if (ui.view === 'form') {
                ui.view = undefined;
            }
            ui.view = set(ui.view, 'code');
            ui.onView = functions.onView;
        }
        ui.expand = set(ui.expand, 'expand');
        ui.onExpand = functions.onExpand;
    }
    if (is(node.api?.action?.output?.fields)) {
        if (!is(ui.flagged)) {
            ui.mode = 'data';
            ui.flagged = true;
        }
    }
    return ui;
}

export function getUiEditExFolderFile(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    if (!is(node.parent?.status.loadEx)) {
        return getUiEditExFile(node, ui);
    }
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    if (!is(ui.keep) || ui.keep === 'include') {
        const { status } = getApi(node);
        const { createEx } = getStatus(node);
        ui.onPath = functions.onPath;
        if (!isFail(status)) {
            if (is(createEx)) {
                ui.note = '[new+]';
            } else {
                ui.note = '[edit+]';
            }
        } else {
            ui.note = '[error+]';
        }
        ui.body = true;
        ui.foot = true;

        ui.mode = set(ui.mode, 'file');
        ui.onMode = functions.onMode;
        //
        getModeAndViewEx(node);
        //
        ui.onView = functions.onView;
        ui.more = set(ui.more, 'less');


        ui.onMore = functions.onMore;
        ui.expand = set(ui.expand, 'expand');
        ui.onExpand = functions.onExpand;
        // ui.onRemove = functions.onRemove;
        ui.onUpload = functions.onUpload;
        ui.keep = set(ui.keep, 'include');
        ui.onKeep = functions.onKeep;
        return ui;
    }
    switch (ui.keep) {
        case 'include':
            ui.onKeep = functions.onKeep;
            break;
        case 'exclude':
            ui.note = '[ignore]';
            ui.onKeep = functions.onKeep;
            break;
        case 'remove':
            ui.onKeep = functions.onKeep;
            ui.note = '[delete]';
            break;
    }
    return ui;
}

export function getUiEditExFile(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    if (is(wrap)) {
        return ui;
    }
    const functions = getFunctions(node);
    const { status } = getApi(node);
    const { createEx } = getStatus(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.onPath = functions.onPath;
    if (!isFail(status)) {
        if (is(createEx)) {
            ui.note = '[new+]';
        } else {
            ui.note = '[edit+]';
        }
    } else {
        ui.note = '[error+]';
    }

    ui.mode = set(ui.mode, 'data');
    ui.onMode = functions.onMode;
    ui.body = true;
    if (ui.mode === 'data') {
        ui.view = 'form';
        ui.schema = getUiSchema(node);
        ui.expand = 'expand';
    } else {
        if (ui.view === 'form') {
            ui.view = 'code';
            // if (assetFileType !== AiosFileType.Image) {
            // ui.view = set(ui.view, 'code');
            // } else {
            //     if (ui.view !== 'markdown') {
            //         ui.view = set(ui.view, 'image');
            //     }
            //     else {
            //         ui.view = 'image';
            //     }
            // }
        }
    }

    ui.foot = true;
    // ui.onCreate = functions.onCreate;
    ui.onUpload = functions.onUpload;
    // if (!is(node.path.version)) {
    //     // root
    //     ui.keep = undefined;
    //     // ui.mode = 'data';
    //     // ui.onMode = undefined;

    //     ui.more = set(ui.more, 'less');
    //     ui.onMore = functions.onMore;

    //     ui.onView = functions.onView;
    //     // ui.view = 'form';
    //     return ui;
    // }
    // ui.onView = functions.onView;
    // switch (ui.keep) {
    //     // case 'include':
    //     //     // ui.note = '[keep+]';
    //     //     break;
    //     case 'exclude':
    //         ui.onKeep = functions.onKeep;
    //         ui.note = '[ignore]';
    //         return ui;
    //     case 'remove':
    //         ui.onKeep = functions.onKeep;
    //         ui.note = '[delete]';
    //         return ui;
    //     // case 'restore':
    //     //     break;
    // }
    // ui.keep = set(ui.keep, 'include');
    // ui.keep = set(ui.keep, 'include');
    // ui.onKeep = functions.onKeep;

    // if (ui.keep === 'exclude') {
    //     ui.mode = undefined;
    //     ui.onMode = undefined;
    //     ui.note = '[ignore+]';
    //     return ui;
    // }

    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.expand = set(ui.expand, 'expand');
    ui.onExpand = functions.onExpand;
    return ui;
}

export function getUiEditExFileItem(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    if (!is(ui.keep) || ui.keep === 'include') {
        const { status } = getApi(node);
        const { createEx } = getStatus(node);
        ui.onPath = undefined;
        if (!isFail(status)) {
            if (is(createEx)) {
                ui.note = '[new+]';
            } else {
                ui.note = '[edit+]';
            }
        } else {
            ui.note = '[error+]';
        }
        ui.body = true;
        ui.schema = getUiSchema(node);
        if (node.type === AiosType.Asset) {
            ui.disabled = true;
        }
        ui.foot = true;
        // ui.onRemove = functions.onRemove;
        if (node.type === AiosType.Asset) {
            ui.onCreate = functions.onCreate;
            ui.mode = 'file';
            ui.view = 'code';
        } else {
            ui.mode = 'data';
            ui.view = 'form';
        }
        if (!is(node.path.version)) {
            ui.keep = undefined;
            ui.more = set(ui.more, 'less');
            ui.onMore = functions.onMore;
            return ui;
        }

        ui.more = set(ui.more, 'less');
        ui.onMore = functions.onMore;
        ui.expand = set(ui.expand, 'expand');
        ui.onExpand = functions.onExpand;
        return ui;
    }
    switch (ui.keep) {
        case 'include':
            ui.onKeep = functions.onKeep;
            break;
        case 'exclude':
            ui.note = '[ignore]';
            ui.onKeep = functions.onKeep;
            break;
        case 'remove':
            ui.onKeep = functions.onKeep;
            ui.note = '[delete]';
            break;
    }
    return ui;
}