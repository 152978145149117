import { is } from 'aios';
import { type AiosNode } from 'app/node';
import { createAppCache } from 'utl';

export async function remCache(node: AiosNode): Promise<void> {
    try {
        const key = node.link as string;
        if (!is(key)) {
            return;
        }
        const cache = createAppCache();
        await cache.rem(key);
    } catch (error) {
        console.error('Error in remCache:', error);
    }
}