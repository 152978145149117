import { type AiosData, createAiosData, set } from '../AiosUtil';

export enum AiosAccessType {
  None = 0,
  Read = 1 << 0,
  Chat = 1 << 1,
  Code = 1 << 2,
  Cref = 1 << 3,
  All = ~0,
}

export interface AiosAccess extends AiosData {
  actorId?: string;
  accessType?: AiosAccessType;
}

export function createAiosAccess(options?: Partial<AiosAccess>): AiosAccess {
  const base = createAiosData(options);
  const access: AiosAccess = {
    ...base,
    actorId: set(options?.actorId, undefined),
    accessType: set(options?.accessType, AiosAccessType.None),
  };
  return access;
}

export function isRead(access: AiosAccess): boolean {
  return access.accessType !== undefined &&
    (access.accessType & AiosAccessType.Read) === AiosAccessType.Read;
}

export function isChat(access: AiosAccess): boolean {
  return access.accessType !== undefined &&
    (access.accessType & AiosAccessType.Chat) === AiosAccessType.Chat;
}

export function isCode(access: AiosAccess): boolean {
  return access.accessType !== undefined &&
    (access.accessType & AiosAccessType.Code) === AiosAccessType.Code;
}

export function isCref(access: AiosAccess): boolean {
  return access.accessType !== undefined &&
    (access.accessType & AiosAccessType.Cref) === AiosAccessType.Cref;
}
