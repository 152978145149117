import { type AiosObject, set } from 'aios';

export interface AiosNodeUi {
    icon?: string;
    spin?: boolean;
    onTick?: (value: boolean) => void;
    tick?: boolean;
    path?: string;
    onPath?: (path: string) => void;
    note?: string;

    keep?: string;
    onKeep?: (keep: string) => void;

    mode?: string;
    onMode?: (mode: string) => void;
    more?: string;
    onMore?: (more?: string) => void;

    form?: () => JSX.Element;
    body?: boolean;
    schema?: AiosObject;
    disabled?: boolean;

    foot?: boolean;
    onCreate?: () => Promise<void>;
    onRemove?: () => Promise<void>;
    onUpload?: (files: FileList) => Promise<boolean>;
    onBack?: () => Promise<void>;
    index?: string;
    onNext?: () => Promise<void>;
    footNote?: string;
    view?: string;
    onView?: (view: string) => void;
    expand?: string;
    onExpand?: (expand?: string) => void;

    progress?: boolean;
    choose?: boolean;
    chooseEx?: boolean;
    chooseMore?: boolean;
    utilities?: boolean;
    advanced?: boolean;    

    // inputValue?: string;
    // inputIndex?: number;

    flagged?: boolean;
}

export function cleanUi(ui: AiosNodeUi): AiosNodeUi {
    const cleaned: AiosNodeUi = {};    
    Object.entries(ui).forEach(([key, value]) => {
        if (typeof value !== 'function') {
            (cleaned as Record<string, unknown>)[key] = value;
        }
    });
    return cleaned;
}

export function createUi(options?: AiosNodeUi): AiosNodeUi {
    const ui: AiosNodeUi = {
        icon: set(options?.icon, undefined),
        spin: set(options?.spin, undefined),
        onTick: set(options?.onTick, undefined),
        tick: set(options?.tick, undefined),
        path: set(options?.path, undefined),
        onPath: set(options?.onPath, undefined),
        note: set(options?.note, undefined),
        keep: set(options?.keep, undefined),
        onKeep: set(options?.onKeep, undefined),        
        mode: set(options?.mode, undefined),
        onMode: set(options?.onMode, undefined),
        more: set(options?.more, undefined),
        onMore: set(options?.onMore, undefined),

        form: set(options?.form, undefined),
        body: set(options?.body, undefined),
        schema: set(options?.schema, undefined),
        disabled: set(options?.disabled, undefined),

        foot: set(options?.foot, undefined),
        onCreate: set(options?.onCreate, undefined),
        onRemove: set(options?.onRemove, undefined),
        onUpload: set(options?.onUpload, undefined),
        onBack: set(options?.onBack, undefined),
        index: set(options?.index, undefined),
        onNext: set(options?.onNext, undefined),
        footNote: set(options?.footNote, undefined),
        view: set(options?.view, undefined),
        onView: set(options?.onView, undefined),
        expand: set(options?.expand, undefined),
        onExpand: set(options?.onExpand, undefined),

        progress: set(options?.progress, undefined),
        choose: set(options?.choose, undefined),
        chooseEx: set(options?.chooseEx, undefined),
        chooseMore: set(options?.chooseMore, undefined),
        utilities: set(options?.utilities, undefined),
        advanced: set(options?.advanced, undefined),

        // inputValue: set(options?.inputValue, ''),
        // inputIndex: set(options?.inputIndex, -1),

        flagged: set(options?.flagged, undefined),
        
    }
    return ui;
}