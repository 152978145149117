import { AiosFileType, AiosType, append, is, isFolder, isText } from 'aios';
import { type AiosNode } from '../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { createNodeFile, generateNewNodeName, getFileTypeFromExtension } from './createNode';
import { setCache } from '../../cache';
import { setStatus } from '../../status';
import { sortNode } from '../../util';
import { newUi, setUi } from '../../ui';
import { getData, setData } from 'app/data';
import { createCopy } from '../copy';

export async function doUpload(node: AiosNode, files: FileList): Promise<void> {
  const file = files[0];
  if (!is(file)) {
    return;
  }
  const fileType = getFileTypeFromExtension(file.name);
  let fileText: string | undefined;
  let fileBase: string | undefined;
  if (fileType === AiosFileType.Image || fileType === AiosFileType.Audio || fileType === AiosFileType.Video || fileType === AiosFileType.Data) {
    fileBase = await readFileAsBase64(file);
  } else {
    fileText = await readFileAsText(file);
  }
  const { assetFileType } = getData(node);
  if (node.type === AiosType.Account || isFolder(assetFileType)) {
    const newNode = createNodeFile(node, file.name, fileType === AiosFileType.Image || fileType === AiosFileType.Audio || fileType === AiosFileType.Video || fileType === AiosFileType.Data ? fileBase : fileText);
    const name = generateNewNodeName(node, 'new');
    newNode.link = `${node.link as string}/${name}`;
    createCopy(newNode);
    setStatus(newNode, { load: { done: true }, edit: true, create: true });
    newUi(newNode);
    await setCache(newNode);
    node.nodes = append(node.nodes, newNode);
    sortNode(node);
    await setCache(node);
    AppState.current.go(newNode.link);
    return;
  }
  if (isText(assetFileType) && is(fileText)) {
    createCopy(node);
    setData(node, { assetFileText: fileText });
    setStatus(node, { edit: true });
    setUi(node, { choose: false, utilities: false });
    await setCache(node);
    AppState.current.refresh();
    return;
  }

  if (assetFileType === AiosFileType.Image && is(fileBase)) {
    createCopy(node);
    setData(node, { assetFileBase: fileBase });
    setStatus(node, { refresh: true });
  }
  await setCache(node);
  AppState.current.refresh();
}

export async function getFileData(file: File): Promise<{ name: string, text?: string, base?: string } | undefined> {
  if (!is(file)) {
    return undefined;
  }
  const fileType = getFileTypeFromExtension(file.name);
  let text: string | undefined;
  let base: string | undefined;
  if (fileType === AiosFileType.Image || fileType === AiosFileType.Audio || fileType === AiosFileType.Video || fileType === AiosFileType.Data) {
    base = await readFileAsBase64(file);
  } else {
    text = await readFileAsText(file);
  }
  return { name: file.name, text, base };
}

// Helper functions to read file content
async function readFileAsText(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => { resolve(reader.result as string); };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

async function readFileAsBase64(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      // Remove data URL prefix (e.g., "data:image/png;base64,")
      const base64 = result.substring(result.indexOf(',') + 1);
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}