import { type AiosAsset, createAiosPath, is } from 'aios';
import { findAiosNode } from 'app';
import { Grid10Cell, Grid10Row } from 'components/Grid10';
import { useApp } from 'signals';
import { AppDataMarkdown } from 'view/AppDataFiles/AppDataMarkdown';
import { AiosPage } from './AiosPage';

export const AiosForm = (): JSX.Element => {
  const { root } = useApp();
  const aios = findAiosNode(root, createAiosPath({ path: 'aios' }));
  let text = (aios?.item as AiosAsset)?.file?.text;
  if (!is(text)){
    text = AiosPage;
  }
  return (
    <Grid10Row>
      <Grid10Cell spanx={10} spany={15}>
        <div style={{ border: '1px solid grey', padding: '10px', height: '100%', overflowY: 'auto' }}>
          {is(aios) ? (<AppDataMarkdown node={aios} value={text} disabled />) : (<></>)}
        </div>
      </Grid10Cell>
    </Grid10Row>
  );
};
