import {
  type AiosAccountSetting,
  AiosAccountSettingTheme,
} from './AiosAccountSetting';
import {
  type AiosData,
  append,
  createAiosData,
  is,
  set,
} from '../AiosUtil';
import { type AiosAsset, createAiosAsset } from '../AiosAsset';
import { type AiosAccountFinance } from './AiosAccountFinance';

export interface AiosAccount extends AiosData {
  email?: string;
  assets?: AiosAsset[];
  finance?: AiosAccountFinance;
  setting?: AiosAccountSetting;
  forward?: string;
  forwardAsset?: AiosAsset;
  vectorStore?: string;
}

export function createAiosAccount(options?: Partial<AiosAccount>): AiosAccount {
  const base = createAiosData(options);
  const account: AiosAccount = {
    ...base,
    email: set(options?.email, ''),
    forward: set(options?.forward, ''),
    forwardAsset: set(options?.forwardAsset, undefined),
    setting: set(options?.setting, { theme: AiosAccountSettingTheme.Dark }),
    finance: set(options?.finance, undefined),
    vectorStore: set(options?.vectorStore, undefined),
  };
  if (is(options)) {
    const { assets } = options;
    if (is(assets)) {
      for (let i = 0; i < assets.length; i++) {
        account.assets = append(account.assets, createAiosAsset(assets[i]));
      }
    }
  }
  return account;
}

export function updateAiosAccount(
  account: AiosAccount,
  options?: Partial<AiosAccount>,
): AiosAccount {
  if (is(options)) {
    if (account.path !== options?.path) {
      account.path = set(options?.path, '');
    }
    account.forward = set(options?.forward, account.forward);
  }
  return account;
}
