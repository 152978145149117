import { append, createAiosObject, is } from 'aios';
import { type AiosNode } from 'app/node';
import { cleanUi } from 'app/ui';
import { createAppCache } from 'utl';

export async function setCacheAll(node: AiosNode): Promise<void> {
    await setCache(node);
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    for (const child of nodes) {
        await setCacheAll(child);
    }
}

export async function setCacheEx(node: AiosNode): Promise<void> {
    await setCache(node);
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    for (const child of nodes) {
        await setCacheEx(child);
    }
}

export async function setCache(node: AiosNode): Promise<void> {
    try {
        const key = node.link as string;
        if (!is(key)) {
            return;
        }
        const json = saveJson(node);
        const cache = createAppCache();
        await cache.set(key, json);
    } catch (error) {
        console.error('Error in setCache:', error);
    }
}

function saveJson(node: AiosNode): string {
    let nodes;
    if (is(node.nodes)) {
        for (const child of node.nodes) {
            const { link } = child;
            nodes = append(nodes, { link });
        }
    }
    const obj = createAiosObject({
        auth: node.auth,
        account: node.account,
        link: node.link,
        type: node.type,
        path: node.path.full as string,
        item: node.item,
        index: node.index,
        status: node.status,
        nodes,

        actions: node.api.actions,

        edit: node.status.edit,
        create: node.status.create,
        loadEx: node.status.loadEx,
        editEx: node.status.editEx,
        createEx: node.status.createEx,

        ui: cleanUi(node.ui),

        forwardLink: node.forwardLink,
        indexOpen: node.indexOpen,
    });
    const json = JSON.stringify(obj);
    return json;
}