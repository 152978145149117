import { is } from 'aios';
import { type AiosNode } from 'app/node';
import { AppState } from 'signals/AppState/AppState';
import { getStatus, setStatus, setStatusAll } from 'app/status';
import { getUi, setUi } from 'app/ui';
import { cancelApi } from 'app/api';
import { setCacheAll } from 'app/cache';
import { removeNodeOk } from './removeNodeOk';

export async function doRemove(node: AiosNode): Promise<void> {
  setStatusAll(node, { remove: { do: true } });
  await setCacheAll(node);
  AppState.current.refresh();
}

export async function doRemoveCancel(node: AiosNode): Promise<void> {
  cancelApi(node);
  setStatusAll(node, {
    remove: { do: undefined, doing: undefined },
    removeConfirm: { do: undefined }
  });
  await setCacheAll(node);
  AppState.current.refresh();
}

export async function doRemoveConfirm(node: AiosNode): Promise<void> {
  setStatusAll(node, { removeConfirm: { done: true } });
  await setCacheAll(node);
  AppState.current.refresh();
}

export async function doRemoveStop(node: AiosNode): Promise<void> {
  cancelApi(node);
  setStatus(node, { remove: { doing: false } });
  await setCacheAll(node);
  AppState.current.refresh();
}

export async function doRemoveOk(node: AiosNode): Promise<void> {
  const redirect = await removeNodeOk(node);
  if (is(redirect)) {
    AppState.current.go(redirect);
  } else {
    AppState.current.refresh();
  }
}

export async function doRemoveSelect(node: AiosNode): Promise<void> {
  const { tick } = getUi(node);
  if (is(tick)) {
    selectNode(node);
  } else {
    deselectNode(node);
  }
  await setCacheAll(node);
}

function selectNode(node: AiosNode): void {
  setUi(node, { tick: true });
  setStatus(node, { removeSkip: { do: false } });
  const { nodes } = node;
  if (is(nodes)) {
    nodes.forEach(child => {
      selectNode(child)
    });
  }
}

function deselectNode(node: AiosNode): void {
  setUi(node, { tick: false });
  setStatus(node, { removeSkip: { do: true } });
  const parent = node.parent;
  if (is(parent)) {
    const { remove, removeSkip } = getStatus(parent);
    if (is(remove?.do) && !is(removeSkip?.do)) {
      deselectNode(parent);
    }
  }
}