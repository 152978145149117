import { createAiosPath } from 'aios';
import { setData } from 'app/data';
import { type AiosNode, doCreateEx, doRemoveSelect, doUploadEx, setCopyIndex } from 'app/node';
import { AppState } from 'signals/AppState/AppState';
import { setUi } from './setUi';
import { setCache } from 'app/cache';
import { setMode } from 'app/util';

export interface AiosUiFunctions {
    onTick: (tick: boolean) => void;
    onPath: (path: string) => void;
    onKeep: (keep: string) => void;
    onMode: (mode: string) => void;
    onView: (view: string) => void;
    onMore: (more?: string) => void;
    onBack: () => Promise<void>;
    onNext: () => Promise<void>;
    onCreate: () => Promise<void>;
    onRemove: () => Promise<void>;
    onUpload: (files: FileList) => Promise<boolean>
    onExpand: (expand?: string) => void;
}

export function getFunctions(node: AiosNode): AiosUiFunctions {
    const refreshUI = (node: AiosNode): void => {
        void setCache(node);
        AppState.current.refresh();
    }
    return {
        onTick: (tick: boolean): void => {
            setUi(node, { tick });
            void doRemoveSelect(node);
            refreshUI(node);
        },
        onPath: (path: string): void => {
            setData(node, { path: createAiosPath({ path }) });
            refreshUI(node);
        },
        onKeep: (keep: string): void => {
            setUi(node, { keep });
            refreshUI(node);
        },
        onMode: (mode: string): void => {
            setUi(node, { mode });
            setMode(node);
            refreshUI(node);
        },
        onView: (view: string): void => {
            setUi(node, { view });
            refreshUI(node);
        },
        onMore: (more?: string): void => {
            setUi(node, { more: more !== 'less' ? 'less' : 'more' });
            refreshUI(node);
        },
        onBack: async () => {
            const { copyIndex } = node;
            if (copyIndex !== undefined) {
                setCopyIndex(node, copyIndex - 1);
            }
            refreshUI(node);
        },
        onNext: async () => {
            const { copyIndex } = node;
            if (copyIndex !== undefined) {
                setCopyIndex(node, copyIndex + 1);
            }
            refreshUI(node);
        },
        onCreate: async () => {
            await doCreateEx(node);
            refreshUI(node);
        },
        onUpload: async (files: FileList): Promise<boolean> => {
            await doUploadEx(node, files);
            refreshUI(node);
            return true;
        },
        onRemove: async () => {
            // await doEditExCancel(node);
            setUi(node, { keep: 'remove' });
            refreshUI(node);
        },
        onExpand: (expand?: string): void => {
            setUi(node, { expand: expand !== 'collapse' ? 'collapse' : 'expand' });
            refreshUI(node);
        },
    };
}