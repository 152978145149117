import { is, AiosType, createAiosPath } from 'aios';
import { getStatus } from '../../status';
import { type AiosNodeControl } from './AiosNodeControl';
import {
    type AiosNode, doLoadReload,
    doSignIn, doSignInCancel, doSignOut,
    doSaveOk, doSave,
    doRemoveConfirm, doRemoveCancel, doRemoveStop,
    doLoadCancel,
    doRemoveOk,
    doSignOutConfirm,
    doSignOutCancel,
    doLoadRemove,
    doCreateCancel,
    doUpdateCancel,
} from '../../node';
import { findAiosNode } from 'app/util';
import { AppState } from 'signals/AppState/AppState';
import { getControlsEx } from './getControlsEx';
import { getControlsView } from './getControlsView';

export function getControls(node: AiosNode): AiosNodeControl[] {
    const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
    if (is(aios)) {
        const { load, loadNext, auth, authOut } = getStatus(aios);
        if (is(load?.doing) || is(loadNext?.doing) || is(auth?.doing) || is(authOut?.do)) {
            return getAiosControls(aios);
        }
    }
    if (node.type === AiosType.AiosNodeAios) {
        return getAiosControls(node);
    }
    const { load, loadEx, edit, save, remove } = getStatus(node);
    if (!is(load?.done)) {
        return getLoadControls(node);
    }
    if (is(loadEx)) {
        return getControlsEx(node);
    }
    if (save?.do !== undefined || is(save?.done)) {
        return getSaveControls(node);
    }
    if (is(edit)) {
        return getEditControls(node);
    }
    if (remove?.do !== undefined || is(remove?.done)) {
        return getRemoveControls(node);
    }
    return getControlsView(node);
}

function getAiosControls(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    const { auth, authOut, authOutConfirm } = getStatus(node);
    // if (is(load?.doing) || is(loadNext?.doing)) {
    //     controls.push({ name: 'cancel', icon: 'cancel', doChange: doLoadCancel });
    //     return controls;
    // }
    if (!is(auth?.done) && !is(auth?.do)) {
        controls.push({ name: 'sign in', icon: 'login', doChange: doSignIn });
        return controls;
    }
    if (is(auth?.do) || is(auth?.doing)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doSignInCancel });
        return controls;
    }
    if (is(authOutConfirm?.do) && !is(authOutConfirm?.done)) {
        controls.push({ name: 'confirm', icon: 'confirm', doChange: doSignOutConfirm });
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doSignOutCancel });
        return controls;
    }
    if (is(authOut?.doing)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doSignOutCancel });
        return controls;
    }
    controls.push({ name: 'sign out', icon: 'logout', doChange: doSignOut });
    return controls;
}

function getLoadControls(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    const { load } = getStatus(node);
    if (is(load?.doing)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doLoadCancel });
        return controls;
    }
    controls.push({ name: 'reload', icon: 'reload', doChange: doLoadReload });
    controls.push({ name: 'remove', icon: 'stop', doChange: doLoadRemove });
    return controls;
}

function getEditControls(node: AiosNode): AiosNodeControl[] {
    const { create } = getStatus(node);
    const controls: AiosNodeControl[] = [];
    // const { version, assetFileType } = getData(node);
    // if (!is(version) && isFile(assetFileType)) {
    //     controls.push({
    //         name: 'upload', icon: 'upload', doUpload: async (files: FileList): Promise<boolean> => {
    //             await doUpload(node, files);
    //             return true;
    //         }
    //     });
    // }
    if (is(create)) {
        controls.push({ name: 'save', icon: 'save', doChange: doSave });
        controls.push({ name: 'cancel', icon: 'close', doChange: doCreateCancel });
    } else {
        controls.push({ name: 'save', icon: 'save', doChange: doSave });
        controls.push({ name: 'cancel', icon: 'close', doChange: doUpdateCancel });
    }
    return controls;
}

function getSaveControls(node: AiosNode): AiosNodeControl[] {
    const { save } = getStatus(node);
    const controls: AiosNodeControl[] = [];
    if (!is(save?.done)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doSaveOk });
        return controls;
    }
    controls.push({ name: 'ok', icon: 'ok', doChange: doSaveOk });
    return controls;
}

export function getRemoveControls(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    const { remove, removeConfirm } = getStatus(node);
    if (is(removeConfirm?.do) && !is(removeConfirm?.done)) {
        controls.push({ name: 'confirm', icon: 'confirm', doChange: doRemoveConfirm });
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doRemoveCancel });
        return controls;
    }
    if (is(remove?.doing)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doRemoveStop });
        return controls;
    }
    if (!is(remove?.done)) {
        controls.push({ name: 'retry', icon: 'reload', doChange: doRemoveConfirm });
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doRemoveCancel });
        if (remove?.do === false) {
            controls.push({ name: 'remove', icon: 'stop', doChange: doLoadRemove });
        }
        return controls;
    }
    controls.push({ name: 'ok', icon: 'ok', doChange: doRemoveOk });
    return controls;
}