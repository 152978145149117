import { AiosType, is, set, createAiosPath } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { type AiosNodeUi } from './AiosNodeUi';
import { getUiSchema } from './getUiSchema';
import { findAiosNode, getIcon, getPath } from '../../util';
import { getFunctions } from './getFunctions';
import { AppState } from 'signals/AppState/AppState';
import { getUiAios } from './getUiAios';
import { getUiEdit } from './getUiEdit';
import { getUiView } from './getUiView';
import { getUiEx } from './getUiEx';

export function getUi(node: AiosNode, wrap?: boolean): AiosNodeUi {
    const ui = getDefaultUi(node);
    const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
    if (is(aios)) {
        const { auth, authOut } = getStatus(aios);
        if (is(auth?.doing) || is(authOut?.do)) {
            return getUiAios(aios, ui);
        }
    }
    if (node.type === AiosType.AiosNodeAios) {
        return getUiAios(node, ui);
    }
    const { load, loadData, loadEx, edit, save, remove } = getStatus(node);
    if (is(remove?.do) || is(remove?.doing) || is(remove?.done)) {
        return getRemoveUi(node, ui);
    }
    if ((!is(load?.done) && !is(loadData?.done)) || is(load?.doing)) {
        return getLoadUi(node, ui);
    }
    if (is(loadEx)) {
        return getUiEx(node, ui, wrap);
    }
    if (is(save?.do) || is(save?.doing) || is(save?.done)) {
        return getSaveUi(node, ui);
    }
    if (is(edit)) {
        return getUiEdit(node, ui);
    }
    return getUiView(node, ui);
}

function getLoadUi(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const { load, loadData } = getStatus(node);
    if (load?.doing === false) {
        ui.icon = 'close';
        ui.path = getPath(node);
        ui.note = '[error]';
        return ui;
    }
    if (!is(load?.done) && !is(loadData?.done)) {
        ui.icon = 'aios';
        ui.spin = true;
        ui.path = getPath(node);
        ui.note = '[loading]';
        return ui;
    }
    if (is(load?.doing)) {
        ui.icon = getIcon(node);
        ui.path = getPath(node);
        ui.note = '[loading...]';
        ui.progress = true;
        return ui;
    }
    ui.icon = 'close';
    ui.path = getPath(node);
    ui.note = '[error]';
    return ui;
}

function getSaveUi(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    ui.schema = getUiSchema(node);
    ui.disabled = true;
    ui.body = true;
    ui.foot = true;
    const { save, saveData } = getStatus(node);
    if (!is(saveData?.done)) {
        ui.icon = 'aios';
        ui.spin = true;
        ui.path = getPath(node);
        ui.note = '[saving]';
        return ui;
    }
    if (is(save?.doing)) {
        ui.icon = 'aios';
        ui.path = getPath(node);
        ui.note = '[saving...]';
        ui.progress = true;
        return ui;
    }
    if (is(saveData?.done)) {
        ui.icon = 'ok';
        ui.path = getPath(node);
        ui.note = '[saved]';
        return ui;
    }
    ui.icon = 'close';
    ui.path = getPath(node);
    ui.note = '[error]';
    return ui;
}

function getRemoveUi(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const functions = getFunctions(node);
    const { remove, removeLoad, loadNext, removeConfirm, removeSkip, removeData } = getStatus(node);
    if (!is(removeLoad?.done) && !is(loadNext?.done)) {
        ui.icon = 'aios';
        ui.spin = true;
        ui.path = getPath(node);
        ui.note = '[staging]';
        return ui;
    }
    if (!is(removeConfirm?.done)) {
        ui.tick = set(ui.tick, true);
        ui.path = `delete ${getPath(node)} ?`;
        ui.onTick = functions.onTick;
        return ui;
    }
    if (is(removeSkip?.do)) {
        ui.icon = 'remove';
        ui.path = getPath(node);
        ui.note = '[ignore]';
        return ui;
    }
    if (removeData?.do === undefined) {
        ui.icon = 'aios';
        ui.path = getPath(node);
        ui.note = '[deleting]';
        if (is(remove?.doing)) {
            ui.progress = true;
        }
        return ui;
    }
    if (is(removeData?.do) && !is(removeData?.done)) {
        ui.icon = 'aios';
        ui.spin = true;
        ui.path = getPath(node);
        ui.note = '[deleting...]';
        return ui;
    }
    if (!is(removeData?.done)) {
        ui.icon = 'close';
        ui.path = getPath(node);
        ui.note = '[error]';
        return ui;
    }
    ui.icon = 'ok';
    ui.path = getPath(node);
    ui.note = '[deleted]';
    return ui;
}

function getDefaultUi(node: AiosNode): AiosNodeUi {
    const ui = node.ui;
    ui.icon = undefined;
    ui.spin = undefined;
    ui.tick = set(ui.tick, undefined);
    ui.onTick = undefined;
    ui.onPath = undefined;
    ui.note = undefined;
    ui.mode = set(ui.mode, undefined);
    ui.onMode = undefined;
    ui.keep = set(ui.keep, undefined);
    ui.onKeep = undefined;
    ui.more = set(ui.more, undefined);
    ui.onMore = undefined;
    ui.body = false;
    ui.form = undefined;
    ui.schema = undefined;
    ui.disabled = false;
    ui.footNote = undefined;
    ui.view = set(ui.view, undefined);
    ui.onView = undefined;
    ui.foot = undefined;
    ui.onRemove = undefined;
    ui.onUpload = undefined;
    ui.onBack = undefined;
    ui.index = undefined;
    ui.onNext = undefined;
    ui.footNote = undefined;
    ui.expand = set(ui.expand, undefined);
    ui.onExpand = undefined;
    ui.progress = undefined;
    ui.utilities = set(ui.utilities, undefined);
    return ui;
}
