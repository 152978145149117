import { AiosStatus, is, getId, AiosType, AiosDataCommand, set } from 'aios';
import { type AiosNode } from 'app/node';
import { getStatus, setStatus } from 'app/status';
import { setApi } from 'app';
import { chatNodeData } from './chatNodeData';

export async function chatNode(node: AiosNode): Promise<void> {
    const status = getStatus(node);
    if (status.chat?.do === false) {
        return;
    }
    if (is(status.chat?.done)) {
        return;
    }
    if (!is(status.chat?.doing)) {
        const id = set(status.chat?.id, getId());
        setStatus(node, {
            chat: { id, doing: true },
            chatData: { do: true, doing: undefined, done: undefined },
            working: true,
        });
        setApi(node, {
            id,
            input: {
                type: AiosType.ActionChat,
                item: { id: 'user', text: node.input },
                command: AiosDataCommand.Chat
            },
            output: {
                type: AiosType.ActionChat,
                item: { id: 'aios', text: '...' },
                status: AiosStatus.Processing,
            },
        });
        return;
    }
    const done = await chatNodeData(node);
    if (done === undefined) {
        return;
    }
    if (!is(done)) {
        setStatus(node, {
            chat: { do: false, doing: false, done: false }, working: false, refresh: true
        });
        setApi(node, {
            id: status.chat?.id,
            status: AiosStatus.Fail
        });
        return;
    }
    setApi(node, {
        id: status.chat?.id,
        status: AiosStatus.Ok
    });
    setStatus(node, {
        chat: { do: false, done: true, doing: false },
        working: false,
        refresh: true
    });

    // todo: check for redirect
}
