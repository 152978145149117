import {
    append,
    createAiosAction,
    getId,
    is,
    set
} from 'aios';
import { type AiosNode } from 'app/node';
import { type AiosNodeApiOptions } from './AiosNodeApi';

export function setApi(node: AiosNode, options?: AiosNodeApiOptions): void {
    if (!is(options)) {
        node.api.action = undefined;
        return;
    }
    const current = node.api.action;    
    const action = createAiosAction({
        id: set(options.id, getId())
    });
    const {
        input, inputText, inputType, inputItem,
        output, outputText, outputType, outputItem,
        status, progress } = options;
    action.input = {
        ...options.input,
        text: set(inputText, set(input?.text, current?.input?.text)),
        type: set(inputType, set(input?.type, current?.input?.type)),
        item: set(inputItem, set(input?.item, {...current?.input?.item})),
    };
    action.output = {
        ...options.output,
        text: set(outputText, set(output?.text, current?.output?.text)),
        type: set(outputType, set(output?.type, current?.output?.type)),
        item: set(outputItem, set(output?.item, {...current?.output?.item})),
        status: set(status, set(output?.status, current?.output?.status)),
        progress: set(progress, set(output?.progress, current?.output?.progress)),
    };
    if (!is(action)) {
        return;
    }
    node.api.action = action;
    const actions = node.api.actions;
    if (is(actions)) {
        const existing = actions.find((a) => a.id === action.id);
        if (is(existing)) {
            existing.input = action.input;
            existing.output = action.output;
            return;
        }
    }
    node.api.actions = append(actions, action);
    // node.api.inputIndex = set(options.inputIndex, node.api.inputIndex);
    // node.api.inputValue = set(options.inputValue, node.api.inputValue);
    node.api.onCancel = options.onCancel;
    node.api.onRetry = options.onRetry;
};