export function textToBase64(text: string): string {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  return btoa(Array.from(new Uint8Array(data), c => String.fromCharCode(c)).join(''));
}

export function base64ToText(base64: string): string {
  const decoder = new TextDecoder();
  const binary = atob(base64);
  const bytes = Uint8Array.from(binary, char => char.charCodeAt(0));
  return decoder.decode(bytes);
}