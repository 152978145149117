import { is } from 'aios';
import { getClass } from 'signals';

export interface Grid10CellProps {
  tip?: string;
  test?: string;
  spin?: boolean;
  swirl?: boolean;
  space?: boolean;
  spanx?: number;
  spany?: number;
  color?: string;
  right?: boolean;
  center?: boolean;
  selected?: boolean;
  disabled?: boolean;
  progress?: boolean;
  children?: React.ReactNode;  
}

export const Grid10Cell = ({ tip, space = false, spanx = 1, spany = 1, selected, children }: Grid10CellProps): JSX.Element => {
  const style = {
    gridColumn: `span ${spanx}`,
    gridRow: `span ${spany}`
  };
  let className = 'grid10-cell';
  if (is(space)) {
    className = `${className} space`;
  }
  if (is(selected)) {
    className = `${className} selected`;
  }
  // Remove the 'swirl' class from here as we don't need it on the cell itself
  // The swirl property will be handled by the Grid10CellIcon component
  className = getClass(className);
  return (
    <div title={tip} style={style} className={className}>
      {children}
    </div>
  );
}