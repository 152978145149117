import { type AiosPath } from './AiosPath';
import { AiosFileType } from '../AiosFile';
import { AiosType } from '../AiosType';
import { is, set } from '../functions';

export function createAiosPath(options?: Partial<AiosPath>): AiosPath {
  let url;
  let host;
  let full;
  let path;
  let parent;
  let name;
  let base;
  let ext;
  let input;
  let mode;
  let modeId;
  let action;
  let account;
  let version;
  let assetPath;
  let assetParent;
  let assetName;
  let assetBase;
  let assetExt;
  let fileType;
  let aiosType;
  let mimeType;
  if (is(options)) {
    if (is(options.url)) {
      url = options.url.toLowerCase();
      if (url.startsWith('https://')) {
        const domain = substring(url, 8);
        if (is(domain)) {
          let index = domain.indexOf('/');
          if (index >= 0) {
            index += 8;
            host = substring(url, 0, index);
            full = substring(url, index);
          }
        }
      } else {
        if (url.startsWith('http://')) {
          const domain = substring(url, 7);
          if (is(domain)) {
            let index = domain.indexOf('/');
            if (index >= 0) {
              index += 7;
              host = substring(url, 0, index);
              full = substring(url, index + 1);
            }
          }
        }
      }
    }
    if (is(options.host)) {
      host = host?.toLowerCase();
    }
    if (is(options.full)) {
      full = options.full.toLowerCase();
    }
    host = set(options.host, host);
    full = set(options.full, full);
    if (is(full)) {
      const paraIndex = full.indexOf('?');
      if (paraIndex < 0) {
        path = full;
      } else {
        path = substring(full, 0, paraIndex);
        input = substring(full, paraIndex + 1);
      }
    }
    if (is(options.path)) {
      path = options.path.toLowerCase();
    }
    if (is(path)) {
      if (path.startsWith('/')) {
        path = path.substring(1);
      }
      const nameIndex = path.lastIndexOf('/');
      if (nameIndex > 0) {
        parent = substring(path, 0, nameIndex);
        name = substring(path, nameIndex + 1);
      }
    }
    if (is(options.name)) {
      name = options.name.toLowerCase();
    }
    if (is(name)) {
      const extIndex = name.lastIndexOf('.');
      if (extIndex < 0) {
        base = name;
        ext = undefined;
      } else {
        base = substring(name, 0, extIndex);
        if (extIndex === name.length - 1) {
          ext = '';
        } else {
          ext = substring(name, extIndex + 1);
        }
      }
    }
    if (is(ext)) {
      const queryIndex = ext.indexOf('?');
      if (queryIndex >= 0) {
        const extQuery = ext.substring(queryIndex + 1);
        ext = ext.substring(0, queryIndex);
        if (is(input)) {
          input = `${extQuery}&${input}`;
        } else {
          input = extQuery;
        }
      }
    }
    if (is(options.base)) {
      base = options.base;
    }
    if (is(options.ext)) {
      ext = options.ext;
    }
    name = base;
    if (is(name)) {
      if (is(ext)) {
        name = `${name}.${ext}`;
      }
    } else {
      name = ext;
    }
    switch (ext) {
      case 'dat':
        mimeType = 'application/octet-stream';
        fileType = AiosFileType.Data;
        break;
      case 'txt':
        mimeType = 'text/plain';
        fileType = AiosFileType.Text;
        break;
      case 'md':
        mimeType = 'text/markdown';
        fileType = AiosFileType.Markdown;
        break;
      case 'ts':
        mimeType = 'text/typescript';
        fileType = AiosFileType.Code;
        break;
      case 'json':
        mimeType = 'application/json';
        fileType = AiosFileType.Json;
        break;
      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg';
        fileType = AiosFileType.Image;
        break;
      case 'png':
        mimeType = 'image/png';
        fileType = AiosFileType.Image;
        break;
      case 'mp3':
        mimeType = 'audio/mpeg';
        fileType = AiosFileType.Audio;
        break;
      case 'mp4':
        mimeType = 'video/mp4';
        fileType = AiosFileType.Video;
        break;
      case 'gltf':
        mimeType = 'model/gltf+json';
        fileType = AiosFileType.Model;
        break;
    }
    if (is(options.input)) {
      input = options.input.toLowerCase();
    }
    if (is(input)) {
      if (input.startsWith('?')) {
        input = input.substring(1);
      }
      const parts = input.split('&');
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i].toLowerCase();
        const valueIndex = part.indexOf('=');
        if (valueIndex >= 0) {
          const property = substring(part, 0, valueIndex);
          const value = substring(part, valueIndex + 1);
          switch (property) {
            case 'mode':
              mode = value;
              break;
            case 'modeid':
              modeId = value;
              break;
            case 'action':
              action = value;
              break;
          }
        }
      }
    }
    if (is(options.mode)) {
      mode = options.mode;
    }
    if (is(options.modeId)) {
      modeId = options.modeId;
    }
    if (is(options.action)) {
      action = options.action;
    }
    assetExt = ext;
    assetBase = base;
    assetName = name;
    if (is(path)) {
      const assetIndex = path.indexOf('/');
      if (assetIndex < 0) {
        account = path;
        assetPath = '';
      } else {
        account = substring(path, 0, assetIndex);
        assetPath = substring(path, assetIndex + 1);
      }
      if (is(account)) {
        const versionIndex = account.indexOf('@');
        if (versionIndex >= 0) {
          if (versionIndex === account.length - 1) {
            version = '';
          } else {
            version = substring(account, versionIndex + 1);
          }
          account = substring(account, 0, versionIndex);
        }
      }
    }
    if (is(assetPath)) {
      const nameIndex = assetPath.lastIndexOf('/');
      if (nameIndex < 0) {
        assetName = name;
      } else {
        assetParent = assetPath.substring(0, nameIndex);
        assetName = assetPath.substring(nameIndex + 1);
      }
    }
    if (is(assetName)) {
      const extIndex = assetName.lastIndexOf('.');
      if (extIndex < 0) {
        assetBase = assetName;
        assetExt = undefined;
      } else {
        assetBase = substring(assetName, 0, extIndex);
        if (extIndex === assetName.length - 1) {
          assetExt = '';
        } else {
          assetExt = substring(assetName, extIndex + 1);
        }
      }
    }
    if (is(options.account)) {
      account = options.account.toLowerCase();
    }
    if (options.version !== undefined) {
      if (!is(options.version)) {
        version = undefined;
      } else {
        version = options.version.toLowerCase();
      }
    }
    if (is(options.assetPath)) {
      assetPath = options.assetPath.toLowerCase();
    }
  }
  if (assetExt === undefined) {
    assetName = assetBase;
  } else {
    if (!is(assetBase)) {
      assetName = `.${assetExt}`;
    } else {
      assetName = `${assetBase}.${assetExt}`;
    }
  }
  if (!is(assetName)) {
    assetPath = assetParent;
  } else {
    if (!is(assetParent)) {
      assetPath = assetName;
    } else {
      assetPath = `${assetParent}/${assetName}`;
    }
  }
  ext = assetExt;
  base = assetBase;
  name = assetName;
  if (!is(account)) {
    parent = undefined;
  } else {
    if (version === undefined) {
      parent = account;
    } else {
      parent = `${account}@${version}`;
    }
  }
  if (is(assetParent)) {
    if (!is(parent)) {
      parent = assetParent;
    } else {
      parent = `${parent}/${assetParent}`;
    }
  }
  if (is(parent)) {
    path = parent;
  }
  if (is(name)) {
    if (!is(path)) {
      path = name;
    } else {
      path = `${path}/${name}`;
    }
  }
  if (!is(mode)) {
    input = undefined;
  } else {
    if (!is(modeId)) {
      input = `mode=${mode}`;
    } else {
      input = `mode=${mode}&modeId=${modeId}`;
    }
  }
  if (is(action)) {
    if (!is(input)) {
      input = `action=${action}`;
    } else {
      input = `${input}&action=${action}`;
    }
  }
  if (!is(path)) {
    full = undefined;
  } else {
    full = path;
  }
  if (is(input)) {
    if (!is(full)) {
      full = input;
    } else {
      full = `${full}?${input}`;
    }
  }
  url = undefined;
  if (is(host) && is(full)) {
    url = `${host}/${full}`;
  }
  switch (mode) {
    case 'access':
      aiosType = AiosType.Access;
      break;
    case 'action':
      aiosType = AiosType.Action;
      break;
    default:
      if (!is(assetPath)) {
        aiosType = AiosType.Account;
      } else {
        aiosType = AiosType.Asset;
      }
      break;
  }
  const aiosPath: AiosPath = {
    url,
    host,
    full,
    path,
    parent,
    name,
    base,
    ext,
    mimeType,
    input,
    account,
    version,
    assetPath,
    assetParent,
    mode,
    modeId,
    action,
    fileType,
    aiosType,
  };

  return aiosPath;
}

const substring = (
  value: string,
  start: number,
  finish?: number,
): string | undefined => {
  const result = value.substring(start, finish);
  if (!is(result)) {
    return undefined;
  }
  return result;
};

export default createAiosPath;
