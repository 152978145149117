import { is } from 'aios';
import { Grid10CellButton, Grid10CellIcon, Grid10CellInput, Grid10CellText, Grid10CellTick, Grid10Row } from 'components';
import { type AppDataProps, type AppDataType } from './AppDataType';
import { getUi } from 'app';

export const AppDataNodeHead: AppDataType = ({ node }: AppDataProps) => {
  const { icon, spin, path, onPath, tick, onTick, note, keep, onKeep, mode, onMode, more, onMore, progress } = getUi(node);
  const closed = more === 'more';
  const buttons: JSX.Element[] = [];
  if (is(note)) {
    buttons.push(<Grid10CellText key='head-note' spanx={2} space={closed}>{note}</Grid10CellText>);
  }
  if (is(onMode)) {
    if (mode === 'file') {
      buttons.push(<Grid10CellButton
        key={'show-file'}
        tip={'detail mode, press to switch to detail'}
        icon='file'
        onClick={async () => { is(onMode) && onMode('data') }}
        space={closed}
      />);
    } else {
      buttons.push(
        <Grid10CellButton
          key={'show-data'}
          tip={'content mode, press to switch to content'}
          icon='data'
          onClick={async () => { is(onMode) && onMode('file') }}
          space={closed}
        />
      );
    }
  }
  if (is(onKeep)) {
    switch (keep) {
      case 'include':
        buttons.push(<Grid10CellButton
          key={'include-item'}
          tip={'included, press to exclude'}
          icon='exclude'
          onClick={async () => { is(onKeep) && onKeep('exclude') }}
          space={closed}
        />);
        break;
      case 'exclude':
        buttons.push(
          <Grid10CellButton
            key={'include-item'}
            tip={'excluded, press to include'}
            icon='include'
            onClick={async () => { is(onKeep) && onKeep('include') }}
            space={closed}
          />
        );
        break;
      case 'remove':
        buttons.push(
          <Grid10CellButton
            key={'include-item'}
            tip={'deleting, press to restore'}
            icon='include'
            onClick={async () => { is(onKeep) && onKeep('include') }}
            space={closed}
          />
        );
        break;
    }
  }
  if (is(onMore) && is(more)) {
    buttons.push(<Grid10CellButton key='head-more' tip={more} icon={more} onClick={async () => { onMore(more) }} space={closed} />);
  }
  let span = 9 - buttons.length;
  if (is(note)) {
    span = span - 1;
  }
  const renderIcon = (): JSX.Element => {
    if (!is(onTick)) {
      return (<Grid10CellIcon icon={icon} spin={spin} space={closed} />);
    }
    return (<Grid10CellTick value={tick} onChange={onTick} space={closed} />);
  }
  return (
    <Grid10Row>
      {renderIcon()}
      {(!is(onPath)) ? (
        <Grid10CellText spanx={span} space={closed} progress={progress}>{path}</Grid10CellText>
      ) : (
        <Grid10CellInput spanx={span} value={path} onChange={onPath} space={closed} />
      )}
      {buttons}
    </Grid10Row>
  );
};
