import { cancelApi, startState, setApi, setCache, setStatus } from 'app';
import { type AiosNode } from 'app/node'
import { AppState } from 'signals/AppState/AppState';

export async function doChat(node: AiosNode, input?: string): Promise<void> {  
  node.input = input;
  setStatus(node, { chat: startState, working: true });
  setApi(node);
  await setCache(node);
  AppState.current.refresh();
}

export async function doChatCancel(node: AiosNode): Promise<void> {
  cancelApi(node);
  setStatus(node, { chat: startState, working: true });
  await setCache(node);
  AppState.current.refresh();
}
