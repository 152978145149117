import {
    type AiosAccount,
    type AiosAsset,
    type AiosAccess,
    type AiosPath,
    type AiosAuth,
    AiosType,
    set,
    createAiosPath,
} from 'aios';
import { type AiosNodeApi } from '../api';
import { type AiosNodeStatus } from '../status';
import { createUi, type AiosNodeUi } from '../ui';

export type AiosNodeItem = AiosAccount | AiosAsset | AiosAccess;

export interface AiosNode {
    auth?: AiosAuth;
    account?: AiosAccount;
    parent?: AiosNode;
    link?: string;
    path: AiosPath;
    type: AiosType;
    item?: AiosNodeItem;
    nodes?: AiosNode[];
    status: AiosNodeStatus;
    api: AiosNodeApi;
    ui: AiosNodeUi;
    input?: string;
    copies?: AiosNode[];
    copyIndex?: number;    
    forwardLink?: string;
    forwardNode?: AiosNode;

    index?: string;
    indexOpen?: boolean;
}

export function createAiosNode(options?: Partial<AiosNode>): AiosNode {
    const node: AiosNode = {
        auth: set(options?.auth, undefined),
        account: set(options?.account, undefined),
        parent: set(options?.parent, undefined),
        link: set(options?.link, set(options?.path?.full, '')),
        path: set(options?.path, createAiosPath()),
        type: set(options?.type, AiosType.None),
        item: set(options?.item, undefined),
        nodes: set(options?.nodes, undefined),
        status: set(options?.status, {}),
        api: {
            client: set(options?.api?.client, undefined),
            action: set(options?.api?.action, undefined),
            actions: set(options?.api?.actions, undefined),
        },
        ui: createUi(options?.ui),
        input: set(options?.input, undefined),
        copies: set(options?.copies, undefined),
        copyIndex: set(options?.copyIndex, undefined),        
        forwardLink: set(options?.forwardLink, undefined),
        forwardNode: set(options?.forwardNode, undefined),

        index: set(options?.index, undefined),
        indexOpen: set(options?.indexOpen, undefined),
    };
    return node;
}