import { type AiosAction, type AiosType, type AiosAccount, type AiosAuth, append, createAiosPath, is } from 'aios';
import { type AiosNode, type AiosNodeItem, createAiosNode } from '../node';
import { type AiosNodeStatus, setStatus } from 'app/status';
import { createAppCache } from 'utl';
import { type AiosNodeUi } from 'app/ui';

export async function getCache(node: AiosNode): Promise<boolean> {
    try {
        const key = node.link as string;
        if (!is(key)) {
            return false;
        }
        const cache = createAppCache();
        const cached = await cache.get(key);
        if (!is(cached)) {
            return false;
        }
        loadJson(node, cached);
        const { nodes, forwardLink } = node;
        if (is(nodes)) {
            for (const child of nodes) {
                await getCache(child);
            }
        }
        if (is(forwardLink)) {
            node.forwardNode = createAiosNode({ parent: node, link: forwardLink });
            await getCache(node.forwardNode);
        }
        return true;
    } catch (error) {
        console.error('Error in getCache:', error);
        return false;
    }
}

function loadJson(node: AiosNode, json: string): void {
    const nodeJson = JSON.parse(json) as NodeJson;
    node.link = nodeJson.link;
    node.type = nodeJson.type;
    node.path = createAiosPath({ full: nodeJson.path });
    node.item = nodeJson.item;
    node.index = nodeJson.index;
    if (is(nodeJson.auth)) {
        node.auth = nodeJson.auth;
    }
    if (is(nodeJson.account)) {
        node.account = nodeJson.account;
    }
    if (is(nodeJson.status)) {
        node.status = nodeJson.status;
    }
    const { nodes } = nodeJson;
    if (is(nodes)) {
        for (const child of nodes) {
            if (is(node.nodes)) {
                const found = node.nodes.find((n) => n.link === child.link);
                if (is(found)) {
                    continue;
                }
            }
            const created = createAiosNode({ parent: node, link: child.link });
            node.nodes = append(node.nodes, created);
        }
    }

    if (is(nodeJson.actions)) {
        node.api.actions = nodeJson.actions;
    }

    if (is(nodeJson.edit)) {
        setStatus(node, { edit: true });
    }
    if (is(nodeJson.create)) {
        setStatus(node, { create: true });
    }
    if (is(nodeJson.loadEx)) {
        setStatus(node, { loadEx: true });
    }
    if (is(nodeJson.editEx)) {
        setStatus(node, { editEx: true });
    }
    if (is(nodeJson.createEx)) {
        setStatus(node, { createEx: true });
    }
    if (is(nodeJson.ui)) {
        node.ui = nodeJson.ui;
    }

    if (is(nodeJson.forwardLink)) {
        node.forwardLink = nodeJson.forwardLink;
    }
    if (is(nodeJson.indexOpen)) {
        node.indexOpen = nodeJson.indexOpen;
    }
}

interface NodeJson {
    auth?: AiosAuth;
    account?: AiosAccount;
    link: string;
    type: AiosType;
    path: string;
    item?: AiosNodeItem;
    status?: AiosNodeStatus;
    nodes?: AiosNode[];
    index: string;

    actions?: AiosAction[];
    edit?: boolean;
    create?: boolean;
    loadEx?: boolean;
    editEx?: boolean;
    createEx?: boolean;

    ui?: AiosNodeUi;

    forwardLink?: string;
    indexOpen?: boolean;
}