import Editor, { DiffEditor } from '@monaco-editor/react';
import { AiosFileType, is } from 'aios';
import { type AiosNode, getData } from 'app';
import { ProgressBar } from 'components';
import { useState } from 'react';
import { useApp } from 'signals';

export interface AppDataCodeProps {
  node: AiosNode;
  type?: string;
  view?: string;
  diff?: string | undefined;
  value: string;
  onValue?: (value?: string) => void;
  disabled?: boolean;
  expand?: string;
}

export const AppDataCode: (props: AppDataCodeProps) => JSX.Element = ({ node, type, view, diff, value, onValue, disabled, expand }: AppDataCodeProps) => {
  const [size, setSize] = useState<string | undefined>('init');
  const { settings } = useApp();
  if (size !== expand) {
    setTimeout(() => {
      setSize(expand);
    }, 1);
    return (<></>);
  }
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const onChange = (v: string | undefined): void => {
    if (is(onValue)) {
      onValue(v);
    }
  };
  if (!is(type)) {
    const { assetFileType } = getData(node);
    switch (assetFileType) {
      case AiosFileType.Markdown:
        type = 'markdown';
        break;
      default:
        type = 'typescript';
        break;
    }

  }
  if (view === 'diff') {
    // eslint-disable-next-line
    const onMount = (editor: { getModifiedEditor: () => any; }): void => {
      const modifiedEditor = editor.getModifiedEditor();
      modifiedEditor.onDidChangeModelContent(() => {
        const newValue = modifiedEditor.getValue();
        onChange(newValue);
      });
    };
    return (
      <DiffEditor
        height='100%'
        language={type}
        original={diff}
        modified={value}
        theme={theme}
        loading={<ProgressBar />}
        onMount={onMount}
        options={{
          readOnly: disabled
        }}
      />
    )
  }
  return (
    <Editor
      height='100%'
      language={type}
      value={value}
      theme={theme}
      loading={<ProgressBar />}
      onChange={onChange}
      options={{
        readOnly: disabled
      }}
    />
  );
};
