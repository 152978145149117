import { is, AiosType, createAiosPath, AiosDataCommand, AiosStatus, type AiosException, createAiosDataInput, createAiosDataOutput, type AiosData, isFail, isOk } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus, setStatus } from '../../status';
import { setApi, setCache } from 'app';
import { createAiosClientApp } from 'utl';
import { AppState } from 'signals/AppState/AppState';

export async function codeNodeData(node: AiosNode): Promise<boolean> {
  const { code, codeData } = getStatus(node);
  if (codeData?.do === false || is(codeData?.done)) {
    return false;
  }
  const text = node.input;
  const input = createAiosDataInput({
    type: AiosType.ActionCode,
    item: { id: 'user', text },
    command: AiosDataCommand.Code
  });
  const output = createAiosDataOutput<AiosData>({
    type: AiosType.ActionCode,
    item: { id: 'aios', text: '...' },
    status: AiosStatus.Processing,
  });
  setApi(node, { id: code?.id, input, output });
  let codeOutput;
  try {
    const path = createAiosPath({ path: node.path.path, action: 'code' });
    node.api.client = await createAiosClientApp();
    codeOutput = await node.api.client.send({ path, input });
    output.override(codeOutput);
  } catch (exception) {
    output.setFail({ text, exception: exception as AiosException });
  } finally {
    node.api.client = undefined;
  }
  if (!is(codeOutput) || isFail(codeOutput.status)) {
    setStatus(node, {
      codeData: { do: false, done: false, doing: false },
    });
    setApi(node, { id: code?.id, input, output });
    return false;
  }
  const outputText = codeOutput.item?.text;
  if (is(outputText)) {
    output.text = outputText;
  }
  setStatus(node, {
    codeData: { do: false, done: true },
    working: false
  });
  setApi(node, { id: code?.id, input, output });
  await setCache(node);
  AppState.current.refresh();
  return isOk(codeOutput.status);
}