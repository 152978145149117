import { AiosFileType } from 'aios';
import { type AiosNode } from '../../node';
import { type AiosNodeUi } from './AiosNodeUi';
import { getPath, getPublicIcon, getView } from '../../util';
// import { getFunctions } from './getFunctions';
import { getData } from '../../data';
import { getUiSchema } from './getUiSchema';

export function getUiViewGuest(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    // const functions = getFunctions(node);
    const { assetFileType } = getData(node);
    
    // Set basic UI properties
    ui.icon = getPublicIcon(node);
    ui.path = getPath(node);
    ui.note = 'public';
    ui.disabled = true; // Always disabled for guest view
    ui.body = true;
    // ui.foot = true;
    
    if (assetFileType === AiosFileType.Folder) {
        // Handle folder view with data mode and form
        ui.mode = 'data';
        ui.view = 'form';
        ui.schema = getUiSchema(node);
    } else {
        // Handle file views based on type
        ui.mode = 'file';
        switch (assetFileType) {
            case AiosFileType.Image:
                ui.view = 'image';
                break;
            case AiosFileType.Markdown:
                ui.view = 'markdown';
                break;
            case AiosFileType.Code:
            case AiosFileType.Text:
            case AiosFileType.Json:
                ui.view = 'code';
                break;
            default:
                ui.view = getView(node); // Fallback to default view
        }
    }

    // ui.onView = functions.onView;
    ui.more = 'less';
    ui.expand = 'collapse';
    return ui;
}
