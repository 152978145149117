import { AppPage } from 'view';
import { is } from 'aios';
import { useEffect } from 'react';
import { useApp, appSignal } from 'signals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './AppContainer.css';

export const AppContainer = (): JSX.Element => {
  const { refresh } = useApp();
  useEffect(() => {
    function handleResize(): void {
      appSignal.value.narrow = false;
      appSignal.value.large = false;
      appSignal.value.indexMenu = false;      
      if (window.innerWidth > window.innerHeight) {
        if (window.innerWidth >= 2000) {
          appSignal.value.large = true;
        }
        appSignal.value.indexMenu = true;
      } else {
        appSignal.value.narrow = true;
        if (window.innerHeight >= 2500) {
          appSignal.value.large = true;
        }
      }
      refresh();
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => { window.removeEventListener('resize', handleResize) };
  }, []);
  const light = appSignal.value.light;
  const theme = createTheme({
    palette: {
      mode: !is(light) ? 'dark' : 'light',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppPage />
    </ThemeProvider>
  );
};
