import { type AiosNode } from '../node';
import { is } from 'aios';

export interface AiosNodeStatus {

    aios?: AiosNodeState;
    authCheck?: AiosNodeState;

    auth?: AiosNodeState;
    authenticate?: AiosNodeState;
    authorize?: AiosNodeState;
    authOut?: AiosNodeState;
    authOutConfirm?: AiosNodeState;

    load?: AiosNodeState;
    loadData?: AiosNodeState;
    loadNext?: AiosNodeState;
    loadMore?: AiosNodeState;
    loadAll?: AiosNodeState;

    edit?: boolean;
    create?: boolean;
    loadEx?: boolean;
    editEx?: boolean;
    createEx?: boolean;
    versionEx?: boolean;

    save?: AiosNodeState;
    saveData?: AiosNodeState;
    saveNext?: AiosNodeState;
    saveMore?: AiosNodeState;

    remove?: AiosNodeState;
    removeLoad?: AiosNodeState;
    removeConfirm?: AiosNodeState;
    removeLast?: AiosNodeState;
    removeSkip?: AiosNodeState;
    removeData?: AiosNodeState;

    chat?: AiosNodeState;
    chatData?: AiosNodeState;

    code?: AiosNodeState;
    codeData?: AiosNodeState;

    working?: boolean;
    refresh?: boolean;

    async?: AiosNodeState;
}

export function setStatusEx(node: AiosNode, status: AiosNodeStatus): void {
    setStatus(node, status);
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    for (const child of nodes) {
        setStatusEx(child, status);
    }
}

export function setStatusAll(node: AiosNode, status: AiosNodeStatus): void {
    setStatus(node, status);
    const { nodes } = node;
    if (is(nodes)) {
        for (const child of nodes) {
            setStatusAll(child, status);
        }
    }
}

export function setStatus(node: AiosNode, status: Partial<AiosNodeStatus>): void {
    if (!is(node.status)) {
        node.status = {};
    }

    node.status.aios = setState(node.status.aios, status.aios);
    node.status.authCheck = setState(node.status.authCheck, status.authCheck);

    node.status.auth = setState(node.status.auth, status.auth);
    node.status.authenticate = setState(node.status.authenticate, status.authenticate);
    node.status.authorize = setState(node.status.authorize, status.authorize);
    node.status.authOut = setState(node.status.authOut, status.authOut);
    node.status.authOutConfirm = setState(node.status.authOutConfirm, status.authOutConfirm);

    node.status.load = setState(node.status.load, status.load);
    node.status.loadData = setState(node.status.loadData, status.loadData);
    node.status.loadNext = setState(node.status.loadNext, status.loadNext);
    node.status.loadMore = setState(node.status.loadMore, status.loadMore);
    node.status.loadAll = setState(node.status.loadAll, status.loadAll);

    setStatusFlag(node.status, status, 'edit');
    setStatusFlag(node.status, status, 'create');
    setStatusFlag(node.status, status, 'loadEx');
    setStatusFlag(node.status, status, 'editEx');
    setStatusFlag(node.status, status, 'createEx');
    setStatusFlag(node.status, status, 'versionEx');

    node.status.save = setState(node.status.save, status.save);
    node.status.saveData = setState(node.status.saveData, status.saveData);
    node.status.saveNext = setState(node.status.saveNext, status.saveNext);
    node.status.saveMore = setState(node.status.saveNext, status.saveMore);

    node.status.remove = setState(node.status.remove, status.remove);
    node.status.removeLoad = setState(node.status.removeLoad, status.removeLoad);
    node.status.removeConfirm = setState(node.status.removeConfirm, status.removeConfirm);
    node.status.removeLast = setState(node.status.removeLast, status.removeLast);
    node.status.removeSkip = setState(node.status.removeSkip, status.removeSkip);
    node.status.removeData = setState(node.status.removeData, status.removeData);

    node.status.chat = setState(node.status.chat, status.chat);
    node.status.chatData = setState(node.status.chatData, status.chatData);

    node.status.code = setState(node.status.code, status.code);
    node.status.codeData = setState(node.status.codeData, status.codeData);

    setStatusFlag(node.status, status, 'working');
    setStatusFlag(node.status, status, 'refresh');

    node.status.async = setState(node.status.async, status.async);
}

export function getStatus(node: AiosNode): AiosNodeStatus {
    if (!is(node.status)) {
        node.status = {};
    }
    return node.status;
}

export function setStatusFlag<T>(
    target: T,
    source: Partial<T>,
    key: keyof T
): void {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key] as T[typeof key];
    }
}

export interface AiosNodeState {
    id?: string;
    do?: boolean;
    done?: boolean;
    doing?: boolean;
}

export const nullState: AiosNodeState = { id: undefined, do: undefined, doing: undefined, done: undefined };
export const startState: AiosNodeState = { id: undefined, do: true, doing: undefined, done: undefined };

export function setState(state?: AiosNodeState, newState?: AiosNodeState): AiosNodeState | undefined {
    if (!is(newState)) {
        return state;
    }
    if (!is(state)) {
        return { ...newState };
    }
    if (Object.prototype.hasOwnProperty.call(newState, 'id')) {
        state.id = newState.id;
    }
    if (Object.prototype.hasOwnProperty.call(newState, 'do')) {
        state.do = newState.do;
    }
    if (Object.prototype.hasOwnProperty.call(newState, 'done')) {
        state.done = newState.done;
    }
    if (Object.prototype.hasOwnProperty.call(newState, 'doing')) {
        state.doing = newState.doing;
    }
    return state;
}
