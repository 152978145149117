import { getClass } from 'signals';
import './Grid10.css';
import { is } from 'aios';

export interface Grid10Props {
  middle?: boolean;
  full?: boolean;
  children: React.ReactNode;
}

export const Grid10 = ({ middle, full, children }: Grid10Props): JSX.Element => {
  let className = 'grid10';
  if (is(middle)) {
    className = 'grid10 middle';
  }
  if (is(full)) {
    className = `${className} full`;
  }
  return (
    <div className={getClass(className)}>
      {children}
    </div>
  );
}