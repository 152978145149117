import { useState, useRef, useEffect } from 'react';
import { is, isFail, AiosType, AiosAccessType, type AiosAccess, type AiosAsset } from 'aios';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { type ErrorSchema } from '@rjsf/utils';
import { type IChangeEvent } from '@rjsf/core';
import { AppState } from 'signals/AppState/AppState';
import { getData, getUi, setData, getApi, doSave, setCache } from 'app';
import { type AppDataType, type AppDataProps } from '../AppData/AppDataType';
import { widgets } from 'app/ui/data/widgets';

export const AppDataForm: AppDataType = ({ node }: AppDataProps) => {
  const { path, schema, disabled } = getUi(node);
  const ref = useRef<HTMLButtonElement>(null);
  const { item } = getData(node);
  const { status } = getApi(node);
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({});
  const [hasErrors, setHasErrors] = useState(false);
  const formData = (node.type === AiosType.Access && is(item)) ? (() => {
    const accessItem = item as AiosAccess;
    const accessType = accessItem.accessType ?? AiosAccessType.None;
    return {
      ...item,
      id: accessItem.id,
      path: accessItem.path,
      actorId: accessItem.actorId,
      accessType: {
        read: (accessType & AiosAccessType.Read) === AiosAccessType.Read,
        chat: (accessType & AiosAccessType.Chat) === AiosAccessType.Chat,
        code: (accessType & AiosAccessType.Code) === AiosAccessType.Code,
        cref: (accessType & AiosAccessType.Cref) === AiosAccessType.Cref
      }
    };
  })() : item;
  useEffect(() => {
    if (isFail(status)) {
      const fields = node.api.action?.output?.fields;
      if (is(fields)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldErrors: ErrorSchema<any> = {};
        const entries = Object.entries(fields);
        entries.forEach((fieldError) => {
          const [fkey, fmessage] = fieldError;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          fieldErrors[fkey] = {
            __errors: [fmessage],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as ErrorSchema<any>;
        });
        setExtraErrors(fieldErrors);
      }
    } else {
      setExtraErrors({});
      setHasErrors(false);
    }
  }, [status]);
  if (!is(node)) {
    return <div>AppDataItemForm no node</div>;
  }
  if (!is(path)) {
    return <div>form no path</div>;
  }
  if (!is(item)) {
    return <div>no item</div>;
  }
  const onChange = ({ formData, errors }: IChangeEvent): void => {
    // todo: make sure other types and fields
    if (node.type === AiosType.Asset) {
      const asset = node.item as AiosAsset;
      if (is(asset)) {
        // if (formData.name === undefined && is(asset.name)){
        //   formData.name = '';
        // }
        if (formData.forward === undefined && is(asset.forward)){
          formData.forward = '';
        }
      }
    }
    setData(node, { item: formData });
    setHasErrors(errors.length > 0);
    void setCache(node);
    AppState.current.refresh();
  };
  const onSubmit = ({ formData }: IChangeEvent): void => {
    if (!hasErrors) {
      setData(node, { item: formData });
      void setCache(node);
      void doSave(node);
    }
  };
  if (!is(schema)) {
    return <div>no schema</div>;
  }
  
  // Get uiSchema from node and merge with submit button options
  const baseUiSchema = is(schema.uiSchema) ? schema.uiSchema as Record<string, unknown> : {};
  const uiSchema = {
    ...baseUiSchema,
    'ui:submitButtonOptions': {
      'submitText': 'Save',
      'norender': false,
      'props': {
        'disabled': hasErrors
      }
    }
  };
  
  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      validator={validator}
      widgets={widgets}
      onChange={onChange}
      onSubmit={onSubmit}
      extraErrors={extraErrors}
      disabled={disabled}
      liveValidate={true}
      showErrorList="top"
    >
      <button ref={ref} type='submit' style={{ display: 'none' }} />
    </Form>
  );
};