import { type AiosFileType, AiosType, append, is, remove } from 'aios';
import { type AiosNode } from '../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { createNode, generateNewNodeName } from './createNode';
import { remCache, setCache } from '../../cache';
import { setStatus } from '../../status';
import { sortNode } from '../../util';
import { newUi, setUi } from '../../ui';
import { createCopy } from '../copy';
import { doCreateEx } from '../bulk';
import { getData, setData } from 'app/data';

export async function doChoose(node: AiosNode): Promise<void> {
  setUi(node, { choose: true });
  await setCache(node);
  AppState.current.refresh();
}

export async function doChooseMore(node: AiosNode): Promise<void> {
  setUi(node, { chooseMore: true });
  await setCache(node);
  AppState.current.refresh();
}

export async function doCopy(node: AiosNode): Promise<void> {
  // TODO: implement copy

}

export async function doCreate(node: AiosNode, fileType?: AiosFileType): Promise<void> {
  if (is(node.ui.chooseEx)) {
    await doCreateEx(node, fileType);
    return;
  }
  setUi(node, { choose: false, chooseMore: false });
  const newNode = createNode(node, fileType);
  const name = generateNewNodeName(node, 'new');
  newNode.link = `${node.link as string}/${name}`;
  if (newNode.type !== AiosType.Access) {
    const { assetFileText } = getData(newNode);
    setData(newNode, { assetFileText: '~' });
    createCopy(newNode);
    setData(newNode, { assetFileText });
  }
  setStatus(newNode, { load: { done: true }, loadData: { done: true }, edit: true, create: true });
  newUi(newNode);
  await setCache(newNode);
  node.nodes = append(node.nodes, newNode);
  sortNode(node);
  await setCache(node);
  AppState.current.go(newNode.link);
}

export async function doCreateCancel(node: AiosNode): Promise<void> {
  setUi(node, { advanced: false, utilities: false, choose: false, chooseMore: false });
  await remCache(node);
  const parent = node.parent;
  if (is(parent)) {
    parent.nodes = remove(parent.nodes, node);
    await setCache(parent);
    AppState.current.goTo(parent.path);
  }
}

export async function doCreateRemove(node: AiosNode): Promise<void> {
  if (!is(node)) {
    return;
  }
  const parent = node.parent;
  if (!is(parent)) {
    return;
  }
  const { nodes } = parent;
  if (is(nodes)) {
    parent.nodes = remove(nodes, node);
  }
  await setCache(parent);
  AppState.current.refresh();
}