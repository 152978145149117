import { useState } from 'react';
import { type AiosAction, AiosType, createAiosPath, is, isFail, isWait, set } from 'aios';
import { Grid10Row, Grid10Cell, Grid10CellButton, Grid10CellIcon, Grid10CellText, ProgressBar } from 'components';
import { useApp } from 'signals';
import { Editor } from '@monaco-editor/react';
import { findAiosNode, formatStatus, getStatus } from 'app';
import { AppState } from 'signals/AppState/AppState';

export interface AppActionItemProps {
  action: AiosAction;
  index?: string;
  onCancel?: () => Promise<void>;
  onRetry?: () => Promise<void>;
}

export const AppActionItem = ({ action, index = '', onCancel, onRetry }: AppActionItemProps): JSX.Element => {
  const { settings } = useApp();
  const [more, setMore] = useState('more');
  const [mode, setMode] = useState('code');
  const [expand, setExpand] = useState('expand');
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const { input, output } = action;

  const doMore = async (): Promise<void> => {
    if (more === 'more') {
      setMore('less');
    } else {
      setMore('more');
      setExpand('expand');
      setMode('code');
    }
  };

  const doExpand = async (): Promise<void> => {
    setExpand(expand === 'expand' ? 'collapse' : 'expand');
  };

  const renderHead = (): JSX.Element => {
    const space = (more === 'more') ? true : undefined;
    const renderOutput = (): JSX.Element => {
      const text = set(output?.text, set(output?.item?.text, ''));
      if (is(onCancel) && is(onRetry)) {
        const node = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
        if (!is(node)) {
          return <></>;
        }
        const { async } = getStatus(node);
        if (is(async?.doing)) {
          return (
            <>
              <Grid10CellButton space={space} icon='abandon' spin swirl onClick={onCancel} tip='press to cancel' />
              <Grid10CellText space={space} spanx={8}>
                <span>{text}</span>
              </Grid10CellText>
            </>
          );
        }
        if (!is(async?.done)) {
          return (
            <>
              <Grid10CellButton space={space} icon='close' swirl onClick={onRetry} tip='press to retry' />
              <Grid10CellText space={space} spanx={8}>
                <span>{text}</span>
              </Grid10CellText>
            </>
          );
        }
        return (
          <>
            <Grid10CellButton space={space} icon='ok' swirl onClick={onRetry} tip='press to reload' />
            <Grid10CellText space={space} spanx={8}>
              <span>{text}</span>
            </Grid10CellText>
          </>
        );
      }
      if (output?.type === AiosType.ActionChat || output?.type === AiosType.ActionCode) {
        if (isWait(output?.status)) {
          return (
            <>
              <Grid10CellIcon space={space} icon='aios' spin />
              <Grid10CellText space={space} spanx={8}>
                <span> ... </span>
              </Grid10CellText>
            </>
          )
        }
        if (isFail(output?.status)) {
          return (
            <>
              <Grid10CellIcon space={space} icon='fail' />
              <Grid10CellText space={space} spanx={8}>
                <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
              </Grid10CellText>
            </>
          )
        }
        return (
          <>
            <Grid10CellIcon space={space} icon='aios' />
            <Grid10CellText space={space} spanx={8}>
              <span>{text}</span>
            </Grid10CellText>
          </>
        )
      }
      if (isWait(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon='aios' spin />
            <Grid10CellText space={space} spanx={8}>
              <span>{text}</span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        );
      }
      if (!isFail(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon='ok' />
            <Grid10CellText space={space} spanx={8}>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={text}>
                {text}
              </span>
              <span></span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        )
      }
      return (
        <>
          <Grid10CellIcon space={space} icon='fail' />
          <Grid10CellText space={space} spanx={8}>
            <span>{text}</span>
            <span> ... </span>
            <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
          </Grid10CellText>
        </>
      )
    }

    let inputText = set(input?.item?.text, '');
    if (input?.type === AiosType.ActionChat || input?.type === AiosType.ActionCode) {
      if (!is(inputText)) {
        inputText = '~';
      }
    }

    return (
      <>
        {!is(inputText) ? (<></>) : (
          <Grid10Row>
            <Grid10Cell>&nbsp;</Grid10Cell>
            <Grid10CellText spanx={8} right>
              {inputText}
            </Grid10CellText>
            <Grid10CellIcon icon={input?.item?.id ?? ''} />
          </Grid10Row>
        )}
        <Grid10Row>
          {renderOutput()}
          <Grid10CellButton icon={more} onClick={doMore} space={space} />
        </Grid10Row>
      </>
    );
  }

  const renderMore = (): JSX.Element => {
    if (more === 'more') {
      return (<></>);
    }
    const spany = expand === 'expand' ? 4 : 10;
    const content = mode === 'json'
      ? JSON.stringify(action, undefined, 2)
      : set(output?.text, '');
    return (
      <Grid10Row>
        <Grid10Cell spanx={10} spany={spany}>
          <div style={{ height: '400px' }}>
            <Editor
              height='100%'
              loading={<ProgressBar />}
              value={content}
              theme={theme}
              options={{
                readOnly: true,
                lineNumbers: 'off',
                minimap: { enabled: false },
                wordWrap: 'on'
              }}
            />
          </div>
        </Grid10Cell>
      </Grid10Row>
    );
  }

  const renderFoot = (): JSX.Element => {
    if (more === 'more') {
      return (<></>);
    }
    return (
      <Grid10Row>
        <Grid10CellButton tip='json' icon='json' onClick={async () => {
          setMode('json');
          setExpand('collapse');
        }} selected={mode === 'json'} space />
        <Grid10CellButton tip='code' icon='code' onClick={async () => {
          setMode('code');
          setExpand('expand');
        }} selected={mode === 'code'} space />
        <Grid10Cell spanx={7} space>&nbsp;</Grid10Cell>
        <Grid10CellButton tip={expand} icon={expand} onClick={doExpand} space />
      </Grid10Row>
    );
  }

  return (
    <>
      {renderHead()}
      {renderMore()}
      {renderFoot()}
    </>
  );
};


