import { is, set, isFolder, AiosType } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { type AiosNodeUi } from './AiosNodeUi';
import { getUiSchema } from './getUiSchema';
import { getIcon, getModeAndViewEx, getPath } from '../../util';
import { getFunctions } from './getFunctions';
import { getData } from 'app/data';
import { getUiEditEx } from './getUiExEdit';

export function getUiEx(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const { editEx, save } = getStatus(node);
    if (is(save?.do) || is(save?.doing) || is(save?.done)) {
        return getUiSaveEx(node, ui);
    }
    if (is(editEx)) {
        return getUiEditEx(node, ui, wrap);
    }
    return getUiLoadEx(node, ui, wrap);
}

function getUiLoadEx(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const parent = node.parent;
    if (!is(parent)) {
        return ui;
    }
    if (!is(parent.status.loadEx)) {
        const { assetFileType } = getData(node);
        if (isFolder(assetFileType)) {
            return getUiLoadExFolder(node, ui, wrap);
        }
        return getUiLoadExFile(node, ui, wrap);
    }
    const { assetFileType } = getData(parent);
    if (isFolder(assetFileType)) {
        return getUiLoadExFolderFile(node, ui);
    }
    return getUiLoadExFileItem(node, ui);
}

function getUiLoadExFolder(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.note = '[load+]';
    ui.mode = 'data';
    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.body = true;
    ui.schema = getUiSchema(node);
    ui.disabled = true;
    if (is(wrap)) {
        return ui;
    }
    ui.foot = true;
    ui.view = 'form';
    ui.expand = set(ui.expand, 'expand');
    ui.onExpand = functions.onExpand;
    return ui;
}

function getUiLoadExFolderFile(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.note = '[load+]';
    ui.mode = set(ui.mode, 'file');
    ui.onMode = functions.onMode;
    ui.body = true;
    ui.schema = getUiSchema(node);
    getModeAndViewEx(node);
    ui.foot = true;
    ui.onView = functions.onView;
    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.expand = set(ui.expand, 'expand');
    ui.onExpand = functions.onExpand;
    ui.disabled = true;
    return ui;
}

function getUiLoadExFile(node: AiosNode, ui: AiosNodeUi, wrap?: boolean): AiosNodeUi {
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.note = '[load+]';
    ui.mode = set(ui.mode, 'file');
    ui.onMode = functions.onMode;
    ui.body = true;
    ui.schema = getUiSchema(node);
    ui.foot = true;
    getModeAndViewEx(node);
    ui.onView = functions.onView;
    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.expand = set(ui.expand, 'expand');
    ui.onExpand = functions.onExpand;
    ui.disabled = true;
    return ui;
}

function getUiLoadExFileItem(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.note = '[load+]';
    ui.body = true;
    ui.schema = getUiSchema(node);
    if (node.type === AiosType.Asset) {
        getModeAndViewEx(node);
        ui.onMode = functions.onMode;
        ui.onView = functions.onView;
    } else {
        ui.mode = 'data';
        ui.view = 'form';
    }
    ui.foot = true;
    ui.more = set(ui.more, 'less');
    ui.onMore = functions.onMore;
    ui.expand = set(ui.expand, 'expand');
    ui.onExpand = functions.onExpand;
    ui.disabled = true;
    return ui;
}

function getUiSaveEx(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const { save, saveData } = getStatus(node);
    if (!is(save?.done) && !is(saveData?.done)) {
        ui.icon = 'aios';
        ui.spin = true;
        ui.path = getPath(node);
        ui.note = '[saving]';
        return ui;
    }
    if (is(save?.doing)) {
        ui.icon = 'aios';
        ui.path = getPath(node);
        ui.note = '[saving...]';
        ui.progress = true;
        return ui;
    }
    if (is(save?.done) || is(saveData?.done)) {
        ui.icon = 'ok';
        ui.path = getPath(node);
        ui.note = '[saved]';
        return ui;
    }
    ui.icon = 'close';
    ui.path = getPath(node);
    ui.note = '[error+]';
    ui.schema = getUiSchema(node);
    ui.mode = 'data';
    ui.view = 'form';
    ui.more = 'less';
    ui.expand = 'collapse';
    return ui;
}