import { AiosType, is, isFile } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { type AiosNodeUi } from './AiosNodeUi';
import { getUiSchema } from './getUiSchema';
import { getIcon, getModeAndView, getPath } from '../../util';
import { getFunctions } from './getFunctions';
import { getData } from 'app/data';

export function getUiEdit(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    if (node.type === AiosType.Asset) {
        return getEditAssetUi(node, ui);
    }
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.body = true;
    ui.schema = getUiSchema(node);
    if (node.type === AiosType.Account) {
        ui.mode = 'data';
        ui.view = 'form';
        ui.more = 'less';
        ui.expand = 'collapse';
        return ui;
    }
    if (node.type === AiosType.Access) {
        ui.mode = 'data';
        ui.view = 'form';
        ui.more = 'less';
        ui.expand = 'collapse';
    }
    return ui;
}

function getEditAssetUi(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const { create } = getStatus(node);
    const functions = getFunctions(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    if (is(create)) {
        ui.note = '[new]';
        ui.onPath = functions.onPath;
    } else {
        ui.note = '[edit]';
    }
    ui.body = true;
    getModeAndView(node);
    const { assetFileType } = getData(node);
    if (isFile(assetFileType)) {
        ui.onUpload = functions.onUpload;
    }
    ui.more = 'less';
    const { copies, copyIndex } = node;
    if (is(copies) && copyIndex !== undefined) {
        ui.onBack = functions.onBack;
        ui.index = `${copyIndex + 1} of ${copies.length}`;
        ui.onNext = functions.onNext;
        if (copyIndex === 0) {
            ui.footNote = 'original';
        } else {
            ui.footNote = 'draft';
        }
        ui.expand = 'collapse';
        return ui;
    }
    if (is(node.api?.action?.output?.fields)) {
        if (!is(ui.flagged)) {
            ui.mode = 'data';
            ui.flagged = true;
        }
    }
    return ui;
}
