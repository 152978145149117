import {
  AiosDataCommand,
  is,
  isOk,
  set,
} from 'aios';
import { type AiosNode } from '../../node';
import { processApi, setApi } from '../../api';
import { getStatus, setStatus } from '../../status';
import { setUi } from 'app/ui';
import { getPath } from 'app/util';

export async function saveNodeData(node: AiosNode): Promise<boolean | undefined> {
  const { saveData } = getStatus(node);
  if (!is(saveData?.do) || is(saveData?.done)) {
    return saveData?.done;
  }
  if (is(saveData?.doing)) {
    return undefined;
  }
  setStatus(node, { saveData: { doing: true } });
  const action = await processApi(node, AiosDataCommand.Save);
  const text = set(action?.output?.text, '?');
  if (!isOk(action?.output?.status)) {
    setStatus(node, { saveData: { do: false, doing: false, done: false } });
    setApi(node, {
      id: saveData?.id,
      output: {
        ...action?.output,
        text: `saving data ${getPath(node)} ~ ${text}`
      }
    });
    setUi(node, { mode: 'data', view: 'form' });
    return false;
  }
  setStatus(node, { load: { done: true }, saveData: { do: false, doing: false, done: true } });
  setApi(node, { id: saveData?.id, output: { ...action?.output, text: `saved data ${getPath(node)}` } });
  return true;
}
