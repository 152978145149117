import {
    type AiosAsset,
    AiosType,
    createAiosAccount,
    createAiosAsset,
    is,
    updateAiosAccount,
    updateAiosAsset,
    createAiosPath,
    type AiosPath,
    set,
    createAiosAccess,
    AiosAccessType,
    type AiosAccess
} from 'aios';
import { type AiosNode, createAssetNode, getOriginal } from '../node';
import { type AiosNodeData } from './AiosNodeData';
import { getStatus, setStatus } from 'app/status';

export function setData(node: AiosNode, data: AiosNodeData): void {
    if (!is(node)) {
        return;
    }
    const { path } = data;
    if (is(path)) {
        setDataPath(node, path);
    }
    if (node.type === AiosType.Account) {
        setDataAccount(node, data);
        return;
    }
    if (node.type === AiosType.Asset) {
        setDataAsset(node, data);
    }
    if (node.type === AiosType.Access) {
        setDataAccess(node, data);
    }
}

function setDataPath(node: AiosNode, path: AiosPath): void {
    const newPath = node.path.path !== path.path;
    if (!is(newPath)) {
        return;
    }
    node.path = createAiosPath({ path: path.path });
    const original = getOriginal(node);
    updateDataPath(node, original);
    const { nodes } = node;
    const { editEx } = getStatus(node);
    if (!is(nodes) || !is(editEx)) {
        return;
    }
    for (const child of nodes) {
        let childOriginal;
        if (is(original)) {
            childOriginal = original.nodes?.find((o) => o.path.name === child.path.name);
        }
        const newChildPath = `${path.path as string}/${child.path.name as string}`;
        child.path = createAiosPath({ path: newChildPath });
        updateDataPath(child, childOriginal);
    }
}

function updateDataPath(node: AiosNode, original?: AiosNode): void {
    const asset = node.item as AiosAsset;
    if (!is(asset)) {
        return;
    }
    const newPath = is(original) && (node.path.path !== original.path.path);
    asset.path = node.path.path;
    asset.name = node.path.name;
    asset.version = node.path.version;
    if (is(asset.file)) {
        asset.file.path = node.path.path;
        if (is(node.path.fileType) && asset.file.type !== node.path.fileType) {
            asset.file.type = node.path.fileType;
        }
    }
    const { editEx, edit } = getStatus(node);
    if (is(newPath)) {
        asset.id = undefined;
        setStatus(node, {
            create: is(edit),
            createEx: is(editEx)
        });
    } else {
        asset.id = set(original?.item?.id, asset.id);
        setStatus(node, {
            create: undefined,
            createEx: undefined
        });
    }
}

function setDataAccount(node: AiosNode, data: AiosNodeData): void {
    const { item: setItem } = data;
    if (is(setItem)) {
        if (!is(node.item)) {
            node.item = createAiosAccount(setItem);
        } else {
            node.item = updateAiosAccount(node.item, setItem);
        }
    }
}

function setDataAsset(node: AiosNode, data: AiosNodeData): void {
    const { nodes } = node;
    const asset = node.item as AiosAsset;
    const { item, assetFileText, assetFileBase } = data;
    const { editEx, versionEx } = getStatus(node);
    if (is(assetFileText)) {
        if (is(asset?.file)) {
            if (is(versionEx) && is(nodes)) {
                for (const childNode of nodes) {
                    if (childNode.type === AiosType.Asset && is(childNode.path.version)) {
                        const childAsset = childNode.item as AiosAsset;
                        if (is(childAsset?.file)) {
                            childAsset.file.text = assetFileText;
                        }
                    }
                }
            }
            asset.file.text = assetFileText;
        }
    }
    if (is(assetFileBase)) {
        if (is(asset?.file)) {
            if (is(versionEx) && is(nodes)) {
                for (const childNode of nodes) {
                    if (childNode.type === AiosType.Asset && is(childNode.path.version)) {
                        const childAsset = childNode.item as AiosAsset;
                        if (is(childAsset?.file)) {
                            childAsset.file.base = assetFileBase;
                        }
                    }
                }
            }
            asset.file.base = assetFileBase;
        }
    }
    if (is(item)) {
        const forward = asset.forward;
        const newAsset = item as AiosAsset;
        const currentAsset = node.item as AiosAsset;
        if (newAsset?.file?.type && (!currentAsset?.file?.type || newAsset.file.type !== currentAsset.file.type)) {
            const parent = node.parent;
            if (is(parent) && is(newAsset.file?.type)) {
                const assetNode = createAssetNode(parent, newAsset.file.type);
                node.path = assetNode.path;
                node.item = assetNode.item;
            }
            return;
        }
        if (!is(currentAsset)) {
            node.item = createAiosAsset(item);
        } else {
            node.item = updateAiosAsset(currentAsset, item);
        }
        if (node.path.path !== node.item.path) {
            node.path = createAiosPath({ path: node.item.path });
        }
        if (is(node.copies) && node.copies.length > 0) {
            const latestCopy = node.copies[node.copies.length - 1];
            latestCopy.ui = node.ui;
        }
        if (is(nodes) && is(editEx) && forward !== undefined && forward !== newAsset.forward) {
            updateForwardPaths(node, createAiosPath({ path: newAsset.forward }));
        }
    }
}

function updateForwardPaths(node: AiosNode, forwardPath: AiosPath): void {
    const { nodes } = node;
    if (!is(nodes)) return;

    const original = getOriginal(node);
    const basePath = `${forwardPath.account as string}${is(forwardPath.version) ? '@' + forwardPath.version : ''}`;

    for (const child of nodes) {
        let childOriginal;
        if (is(original)) {
            childOriginal = original.nodes?.find((o) => o.path.name === child.path.name);
        }

        const pathName = child.path.name ?? '';
        const defaultPath = forwardPath.path ?? '';

        let childPath: string;
        if (child.type === AiosType.Access) {
            childPath = is(pathName)
                ? `${basePath}/${pathName}?mode=access${is(child.path.modeId) ? '&modeId=' + child.path.modeId : ''}`
                : defaultPath;
        } else {
            childPath = is(pathName)
                ? `${basePath}/${pathName}`
                : defaultPath;
        }

        child.path = createAiosPath({ path: childPath });
        updateDataPath(child, childOriginal);

        // Recursively update children's paths
        updateForwardPaths(child, forwardPath);
    }
}

interface AccessFormData extends Omit<AiosAccess, 'accessType'> {
    accessType: {
        read: boolean;
        chat: boolean;
        code: boolean;
        cref: boolean;
    };
}

function setDataAccess(node: AiosNode, data: AiosNodeData): void {
    const { item: setItem } = data;
    if (is(setItem)) {
        // Convert form boolean flags to bitmask
        const formData = setItem as AccessFormData;
        let accessFlags = AiosAccessType.None;

        if (is(formData.accessType) && typeof formData.accessType === 'object') {
            if (formData.accessType.read) accessFlags |= AiosAccessType.Read;
            if (formData.accessType.chat) accessFlags |= AiosAccessType.Chat;
            if (formData.accessType.code) accessFlags |= AiosAccessType.Code;
            if (formData.accessType.cref) accessFlags |= AiosAccessType.Cref;
        }

        // Create new access object preserving all fields
        const accessData: Partial<AiosAccess> = {
            ...node.item, // Keep existing data
            ...formData, // Apply any changes from form
            accessType: accessFlags // Override with converted flags
        };

        node.item = createAiosAccess(accessData);
    }
}