import { AiosStatus, getId, is, createAiosPath, set, createAiosAuth, createAiosDataInput, AiosType, AiosDataCommand, type AiosAuth, type AiosAccount, append, type AiosDataOutput, isFail, delay } from 'aios';
import { setApi, type AiosNode, createAiosNode, findAiosNode, setCache, getCache, remCache } from 'app';
import { getStatus, setStatus } from 'app/status';
import { AppState } from 'signals/AppState/AppState';
import { createAiosClientApp, createAppCache } from 'utl';
import { Auth } from 'aws-amplify';

export async function authNode(): Promise<boolean | undefined> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return undefined;
  }
  const { auth, authOut } = getStatus(aios);
  if (is(auth?.do)) {
    return await authNodeIn(aios);
  }
  if (is(authOut?.do)) {
    return await authNodeOut(aios);
  }
  if (!is(aios.auth)) {
    const cached = await getCache(aios);
    if (is(cached)) {
      if (is(aios.account)) {
        setStatus(aios, { auth: { do: false, doing: false, done: true } });
        await configNode(aios);
        return undefined;
      }
    }
  }
  return true;
}

async function authNodeIn(node: AiosNode): Promise<boolean | undefined> {
  const { auth, authenticate, authorize } = getStatus(node);
  if (!is(auth?.do) || is(auth?.done)) {
    return true;
  }
  if (!is(auth?.doing)) {
    const id = getId();
    node.auth = await signInAws();
    if (!is(node.auth)) {
      setStatus(node, {
        auth: { id, doing: true },
        authenticate: { do: true },
      });
      setApi(node, {
        id, output: { text: 'authenticating', status: AiosStatus.Processing }
      });
    } else {
      setStatus(node, {
        auth: { id, doing: true },
        authorize: { do: true },
      });
      setApi(node, {
        id, output: { text: 'authorizing', status: AiosStatus.Processing }
      });
    }
    return undefined;
  }
  if (is(authenticate?.do)) {
    if (is(authenticate?.done)) {
      node.auth = await signInAws();
      if (!is(node.auth)) {
        setStatus(node, { auth: { do: false, doing: false, done: false } });
        setApi(node, { id: auth?.id, output: { text: 'authentication failed', status: AiosStatus.Fail } });
        return false;
      }
      setStatus(node, {
        authenticate: { do: false, done: true },
        authorize: { do: true },
      });
      setApi(node, {
        id: auth?.id, output: { text: 'authorizing', status: AiosStatus.Processing }
      });
      return undefined;
    }
    return false;
  }
  if (is(authorize?.do)) {
    if (is(node.auth)) {
      const accountResult = await verifyWithAios(node.auth);
      const account = accountResult?.item;
      if (!is(account)) {
        setStatus(node, { auth: { do: false, doing: false, done: false } });
        setApi(node, { id: auth?.id, output: { text: 'not signed in', status: AiosStatus.Fail } });
        return true;
      }
      node.account = account;
      await configNode(node);
      setApi(node, { id: auth?.id, output: { text: 'signed in', status: AiosStatus.Ok } });
      await setCache(node);
    }
  }
  return undefined;
}

export async function authNodeOut(node: AiosNode): Promise<boolean | undefined> {
  const { authOut, authOutConfirm } = getStatus(node);
  if (is(authOut?.done) || !is(authOutConfirm?.done)) {
    return true;
  }
  if (!is(authOut?.doing)) {
    const id = getId();
    setStatus(node, { authOut: { id, doing: true } });
    setApi(node, {
      id, output: { text: 'signing out', status: AiosStatus.Processing }
    });
    return undefined;
  }
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    setApi(node, { id: authOut?.id, output: { text: 'aws sign out failed' } });
  }
  try {
    const cache = createAppCache();
    await cache.clr();
    localStorage.clear();
    node.auth = undefined;
    node.account = undefined;
    setStatus(node, {
      authOut: { do: false, doing: false, done: true },
      auth: { done: false }
    });
    setApi(node, {
      id: authOut?.id,
      output: { text: 'signed out', status: AiosStatus.Ok }
    });
    void delay(async () => {
      const root = AppState.current.root;
      const aios = findAiosNode(root, createAiosPath({ path: 'aios' }));
      if (is(aios)) {
        await remCache(aios);
      }
      window.location.href = '/';
    }, 100);
  } catch (error) {
    setStatus(node, { authOut: { do: false, doing: false, done: false } });
    setApi(node, { id: authOut?.id, output: { text: 'sign out failed', status: AiosStatus.Fail } });
  }
  return undefined;
}

export async function getAuth(): Promise<AiosAuth | undefined> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return undefined;
  }
  try {
    const authSession = await Auth.currentSession();
    if (is(authSession)) {
      aios.auth = createAiosAuth({
        ...aios.auth,
        headers: {
          ...aios.auth?.headers,
          authorization: `Bearer ${authSession.getAccessToken()?.getJwtToken()}`
        }
      });
    }
  } catch (error) {
    setStatus(aios, {
      auth: { do: false, doing: false, done: false },
    });
  }
  return aios.auth;
}

export function owner(node: AiosNode): boolean {
  const { account } = node.path;
  if (!is(account)) {
    return false;
  }
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return false;
  }
  const owner = aios.account;
  if (!is(owner)) {
    return false;
  }
  if (owner.id !== account && owner.path !== account) {
    return false;
  }
  return true;
}

export function updateOwner(node: AiosNode): void {
  if (node.type !== AiosType.Account) {
    return;
  }
  const account = node.item as AiosAccount;
  if (!is(account)) {
    return;
  }
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return;
  }
  const owner = aios.account;
  if (!is(owner)) {
    return;
  }
  if (owner.id !== account.id) {
    return;
  }
  aios.account = account;
}

async function signInAws(): Promise<AiosAuth | undefined> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (is(user)) {
      const id = user?.attributes?.sub as string;
      const authSession = await Auth.currentSession();
      const authorization = authSession.getAccessToken().getJwtToken();
      return createAiosAuth({
        id,
        email: user.attributes.email,
        headers: {
          authorization: `Bearer ${authorization}`,
        }
      });
    }
  } catch { }
  return undefined;
}

async function verifyWithAios(auth: AiosAuth): Promise<AiosDataOutput<AiosAccount> | undefined> {
  const client = await createAiosClientApp({ auth });
  // let accountResult;
  // const cachedAccountResult = localStorage.getItem('aios_cached_account');
  const cacheId = set(localStorage.getItem('aios_cache_id'), getId());
  const cacheDate = localStorage.getItem('aios_cache_date');

  // if (!is(cachedAccountResult)) {
  const path = createAiosPath();
  const input = createAiosDataInput({
    type: AiosType.Auth,
    item: {
      id: auth.id,
      email: auth.email,
      cacheId
    },
    command: AiosDataCommand.Auth,
  });
  const accountResult = await client.send({
    path,
    input,
  });
  if (isFail(accountResult.status)) {
    return;
  }
  // localStorage.setItem('aios_cached_account', JSON.stringify(accountResult));
  // } else {
  //   accountResult = JSON.parse(cachedAccountResult);
  // }

  const authResult = accountResult?.outputs?.[3] as AiosDataOutput<AiosAuth>;
  const updatedAuth = authResult?.item;

  if (is(updatedAuth) && updatedAuth.lastSignIn !== cacheDate) {
    const cache = createAppCache();
    await cache.clr();
    // localStorage.setItem('aios_cache_id', cacheId);
    // localStorage.setItem('aios_cache_date', updatedAuth.lastSignIn as string);
  }

  return accountResult;
}

async function configNode(node: AiosNode): Promise<void> {
  const { account } = node;
  if (!is(account)) {
    return;
  }
  if (account.setting?.theme === 'Light') {
    AppState.current.setSettings({ dark: true });
  } else {
    AppState.current.setSettings({ dark: false });
  }
  const path = createAiosPath({
    path: set(account.path, account.id),
  });
  const root = AppState.current.root;
  let userNode = findAiosNode(root, path);
  if (!is(userNode)) {
    userNode = createAiosNode({
      path,
      type: AiosType.Account,
      item: node.account,
      link: node.account?.path,
    });
    root.nodes = append(root.nodes, userNode);
  }
  setStatus(node, { auth: { do: false, doing: false, done: true } });    
}
