import { AiosFileType, is } from 'aios';
import { Grid10Row, Grid10CellButton, Grid10Cell, Grid10CellText } from 'components';
import { type AppDataProps, type AppDataType } from '../AppData/AppDataType';
import { getData, getUi, isCopy } from 'app';

export const AppDataNodeFoot: AppDataType = ({ node, wrap }: AppDataProps) => {
  const { foot, onCreate, onRemove, onUpload, onBack, index, onNext, mode, footNote, view, onView, more, expand, onExpand } = getUi(node, wrap);
  if (!is(foot)) {
    return <></>;
  }
  if (more === 'more') {
    if (wrap !== true) {
      return <></>;
    }
    if (!is(node.status.editEx)) {
      return <></>;
    }
  }
  let spanx = 10;
  const leftButtons: JSX.Element[] = [];
  if (is(onCreate)) {
    leftButtons.push(<Grid10CellButton key='foot-create' tip='create child' icon='add' onClick={onCreate} space />);
    spanx = spanx - 1;
  }
  if (is(onRemove)) {
    leftButtons.push(<Grid10CellButton key='foot-remove' tip='set for delete' icon='delete' onClick={onRemove} space />);
    spanx = spanx - 1;
  }
  if (is(onUpload)) {
    leftButtons.push(<Grid10CellButton key='foot-upload' tip='upload content' icon='upload' onUpload={onUpload} space />);
    spanx = spanx - 1;
  }
  while (leftButtons.length < 3) {
    leftButtons.push(<Grid10Cell key={`foot-left-${leftButtons.length}`} space >&nbsp;</Grid10Cell>);
    spanx = spanx - 1;
  }

  if (is(onBack)) {
    leftButtons.push(<Grid10CellButton key='foot-previous' tip='previous clone' icon='left' onClick={onBack} space />);
    spanx = spanx - 1;
  }
  if (is(index) || is(footNote)) {
    let display = '';
    if (is(index)) {
      display = index;
    }
    if (is(footNote)) {
      if (!is(display)) {
        display = footNote;
      } else {
        display = `${footNote} ${display}`;
      }
    }
    leftButtons.push(<Grid10CellText key='foot-text' center space>{display}</Grid10CellText>);
    spanx = spanx - 1;
  }
  if (is(onNext)) {
    leftButtons.push(<Grid10CellButton key='foot-next' tip='next clone' icon='right' onClick={onNext} space />);
    spanx = spanx - 1;
  }
  const buttons: JSX.Element[] = [];
  if (is(view) && is(onView)) {
    if (mode === 'data') {
      buttons.push(
        <Grid10CellButton
          key={'show-form'}
          tip={'view form'}
          icon='form'
          onClick={async () => { is(onView) && onView('form') }}
          selected={view === 'form'}
          space
        />
      );
      spanx = spanx - 1;
      buttons.push(
        <Grid10CellButton
          key={'show-code'}
          tip={'view code'}
          icon='code'
          onClick={async () => { is(onView) && onView('code') }}
          selected={view === 'code'}
          space
        />
      );
      spanx = spanx - 1;
    } else {
      const { assetFileType } = getData(node);
      switch (assetFileType) {
        case AiosFileType.Markdown:
          buttons.push(<Grid10CellButton
            key={'show-markdown'}
            tip={'markdown'}
            icon='markdown'
            onClick={async () => { is(onView) && onView('markdown') }}
            selected={view === 'markdown'}
            space
          />);
          spanx = spanx - 1;
          break;
        case AiosFileType.Text:
          buttons.push(<Grid10CellButton
            key={'show-text'}
            tip={'text'}
            icon='text'
            onClick={async () => { is(onView) && onView('text') }}
            selected={view === 'text'}
            space
          />);
          spanx = spanx - 1;
          break;
        case AiosFileType.Image:
          buttons.push(<Grid10CellButton
            key={'show-image'}
            tip={'image'}
            icon='image'
            onClick={async () => { is(onView) && onView('image') }}
            selected={view === 'image'}
            space
          />);
          spanx = spanx - 1;
          break;
      }
      if (assetFileType !== AiosFileType.Folder) {
        buttons.push(
          <Grid10CellButton
            key={'show-code'}
            tip={'view code'}
            icon='code'
            onClick={async () => { is(onView) && onView('code') }}
            selected={view === 'code'}
            space
          />
        );
        spanx = spanx - 1;
        if (isCopy(node)) {
          buttons.push(
            <Grid10CellButton
              key={'show-diff'}
              tip={'diff'}
              icon='diff'
              onClick={async () => { is(onView) && onView('diff') }}
              selected={view === 'diff'}
              space
            />
          );
          spanx = spanx - 1;
        }
      }
    }
  }
  if (is(onExpand)) {
    buttons.push(
      <Grid10CellButton key='foot-expand' tip={expand} icon={expand} onClick={async () => { is(onExpand) && onExpand(expand) }} space />
    );
    spanx = spanx - 1;
  }
  return (
    <Grid10Row>
      {leftButtons}
      <Grid10Cell spanx={spanx} space>&nbsp;</Grid10Cell>
      {buttons}
    </Grid10Row>
  );
};
