import {
    type AiosAsset,
    AiosType,
    createAiosAsset,
    is,
    remove,
    append,
    isFolder,
    set,
    createAiosPath,
} from 'aios';
import { getData } from '../../data';
import { type AiosNode, createAiosNode } from '../../node';
import { getStatus, setStatus } from '../../status';

export function isCopy(node: AiosNode): boolean {
    return is(node.copies) && is(node.copyIndex) && node.copyIndex > 0;
}

export function getOriginal(node: AiosNode): AiosNode | undefined {
    const { create, createEx } = getStatus(node);
    if (!is(create) && !is(createEx)) {
        return;
    }
    if (is(node.copies)) {
        return node.copies[0];
    }
}

export function createClone(node: AiosNode): AiosNode {
    const clone = {
        ...copyNode(node),
        status: { ...node.status },
        api: {
            client: undefined,
            action: undefined,
            actions: undefined,
        },
        ui: { ...node.ui },
        path: createAiosPath(node.path),
    };
    if (is(node.nodes)) {
        for (const child of node.nodes) {
            child.parent = clone;
            createClone(child);
        }
    }
    return clone;
}

export function createCopy(node: AiosNode, text?: string): AiosNode {
    const { path, type, item, nodes, api, ui, status } = node;
    let copies = node.copies;
    if (!is(copies)) {
        copies = [{
            path: createAiosPath(path),
            type,
            item: createAiosAsset(item),
            nodes,
            api: {
                ...api,
                action: {
                    ...api.action,
                    output: { ...api.action?.output }
                }
            },
            ui,
            status: { ...status }
        }];
    }
    const copy = copyNode(node, text);
    copies = append(copies, copy);
    node.copies = copies;
    const { assetFileType } = getData(node);
    if (is(nodes) && isFolder(assetFileType)) {
        let childNodes;
        let childAssets;
        for (const child of nodes) {
            const childCopy = createCopy(child);
            if (child.type === AiosType.Asset) {
                const childAsset = childCopy.item as AiosAsset;
                childAssets = append(childAssets, childAsset);
            }
            childNodes = append(childNodes, childCopy);
        }
        copy.nodes = childNodes;
        const asset = copy.item as AiosAsset;
        if (is(asset)) {
            asset.assets = childAssets;
        }
    }
    const index = node.copies?.length;
    if (is(index) && index > 1) {
        setCopyIndex(node, index - 1);
    }
    return copy;
}

function copyNode(node: AiosNode, text?: string): AiosNode {
    const copy = createAiosNode(node);
    const { type, item } = copy;
    if (type === AiosType.Asset && is(item)) {
        const asset = createAiosAsset(item);
        if (is(text)) {
            const { file } = asset;
            if (is(file)) {
                file.text = text;
            }
        }
        copy.item = asset;
    }
    return copy;
}

export function deleteCopy(node: AiosNode): void {
    setStatus(node, { refresh: true });
    const { copyIndex, copies } = node;
    if (copyIndex === undefined || !is(copies) || copyIndex === 0) {
        return;
    }
    if (copies.length > 2 && copyIndex > 0) {
        const currentCopy = copies[copyIndex];
        if (is(currentCopy)) {
            currentCopy.ui = node.ui;
        }
        node.copies = remove(node.copies, copies[copyIndex]);
        setCopyIndex(node, copyIndex - 1);
        return;
    }
    setCopyIndex(node, 0);
    node.copies = undefined;
    node.copyIndex = undefined;
}

export function setCopyIndex(node: AiosNode, index: number): void {
    setStatus(node, { refresh: true });
    const { copies } = node;
    if (!is(copies)) {
        return;
    }
    if (index < 0) {
        index = copies.length - 1;
    }
    if (index >= copies.length) {
        index = 0;
    }
    const currentIndex = set(node.copyIndex, 0);
    const currentCopy = copies[currentIndex];
    if (is(currentCopy)) {
        currentCopy.path = createAiosPath(node.path);
        currentCopy.item = node.item;
        currentCopy.nodes = node.nodes;
        currentCopy.status = node.status;
    }
    const currentNodes = node.nodes;
    node.copyIndex = index;
    const targetCopy = copies[index];
    if (!is(targetCopy)) {
        return;
    }
    node.path = createAiosPath(targetCopy.path);
    node.item = targetCopy.item;
    node.nodes = targetCopy.nodes;
    node.status = targetCopy.status;
    const { assetFileType } = getData(node);
    if (!isFolder(assetFileType)) {
        return;
    }
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    for (const child of nodes) {
        if (is(currentNodes)) {
            for (const currentChild of currentNodes) {
                if (child.link === currentChild.link) {
                    child.ui = currentChild.ui;
                }
            }
        }
    }
}

export function clearAllCopies(node: AiosNode): void {
    setStatus(node, { refresh: true });
    const original = getOriginal(node);
    if (is(original)) {
        const currentNodes = node.nodes;
        node.path = createAiosPath(original.path);
        node.item = original.item;
        node.nodes = original.nodes;
        if (is(node.nodes)) {
            for (const child of node.nodes) {
                if (is(currentNodes)) {
                    for (const currentChild of currentNodes) {
                        if (child.link === currentChild.link) {
                            child.ui = currentChild.ui;
                        }
                    }
                }
            }
        }
    }
    node.copies = undefined;
    node.copyIndex = undefined;
}

export function resetCopies(node: AiosNode): void {
    setStatus(node, { refresh: true });
    node.copies = undefined;
    node.copyIndex = undefined;
}
