import { Grid10 } from 'components';
import { AppDataNodeHead } from './AppDataNodeHead';
import { AppDataNodeBody } from './AppDataNodeBody';
import { AppDataNodeFoot } from './AppDataNodeFoot';
import { type AiosNode, getData, getStatus, getUi, owner } from 'app';
import { AiosType, is, isFolder } from 'aios';
import { type AppDataType, type AppDataProps } from '../AppData/AppDataType';

export const AppDataNode: AppDataType = ({ node }: AppDataProps) => {
  let displayNode = node;
  if (is(displayNode.forwardNode) && !owner(node)) {
    displayNode = displayNode.forwardNode;
    displayNode.status = node.status;
    displayNode.ui = node.ui;
  }
  const renderOne = (node: AiosNode): JSX.Element => {
    return (
      <Grid10 full>
        <AppDataNodeHead node={node} />
        <AppDataNodeBody node={node} />
        <AppDataNodeFoot node={node} />
      </Grid10>
    );
  }
  const { nodes } = displayNode;
  if (!is(nodes)) {
    return renderOne(displayNode);
  }
  const { remove } = getStatus(displayNode);
  if (!is(remove?.do)) {
    const { mode } = getUi(displayNode);
    const { loadEx } = getStatus(displayNode);
    if (mode === 'data' && !is(loadEx)) {
      return renderOne(displayNode);
    }
    const showNodes = getNodes(displayNode);
    if (!is(showNodes)) {
      return renderOne(displayNode);
    }
    const children: JSX.Element[] = [];
    for (const childNode of showNodes) {
      const key = `${childNode?.link as string}`;
      children.push(<AppDataNodeHead key={`${key}-1`} node={childNode} />);
      children.push(<AppDataNodeBody key={`${key}-2`} node={childNode} />);
      children.push(<AppDataNodeFoot key={`${key}-3`} node={childNode} />);
    }
    return (
      <Grid10>
        <AppDataNodeHead node={displayNode} />
        <AppDataNodeBody node={displayNode} />
        <AppDataNodeFoot node={displayNode} wrap={false} />
        {children}
        <AppDataNodeFoot node={displayNode} wrap={true} />
      </Grid10>
    );
  }
  const components: JSX.Element[] = [];
  const loadNodes = (load: AiosNode): void => {
    components.push(<AppDataNodeHead key={`${load?.link as string}-head`} node={load} />);
    if (is(load.nodes)) {
      for (const childNode of load.nodes) {
        loadNodes(childNode);
      }
    }
  };
  loadNodes(displayNode);
  return (
    <Grid10>
      {components}
    </Grid10>
  );
};
function getNodes(node: AiosNode): AiosNode[] | undefined {
  if (node.type === AiosType.AiosNodeAios) {
    return undefined;
  }
  if (node.type !== AiosType.Asset) {
    return undefined;
  }
  const { nodes } = node;
  if (!is(nodes)) {
    return undefined;
  }
  const { asset, assetFileType } = getData(node);
  if (isFolder(assetFileType)) {
    const files = nodes.filter((childNode) => {
      const { assetFileType } = getData(childNode);
      return !isFolder(assetFileType);
    });
    return files;
  }
  if (is(node.path.version)) {
    return undefined;
  }
  const { loadEx, remove } = getStatus(node);
  if (!is(loadEx) && !is(remove?.done)) {
    return undefined;
  }
  const returns = [];
  for (const childNode of nodes) {
    if (is(loadEx) && childNode.type === AiosType.Asset) {
      if (asset?.forward !== childNode.path.full) {
        continue
      }
    }
    returns.push(childNode);
    const childNodes = childNode.nodes;
    if (is(childNodes)) {
      for (const nextNode of childNodes) {
        returns.push(nextNode);
      }
    }
  }
  return returns;
}
