import { type AiosNode } from '../../node';
import { AiosFileType, is, isWait } from 'aios';
import { doChat, doCode, doCodeCancel, getStatus, doChatCancel, getData, getApi } from 'app';

const MAX_HISTORY_SIZE = 50;
const STORAGE_KEY = 'appActionCommandHistory';

export interface AiosNodeActionControls {
    working: boolean;
    inputValue?: string;
    inputIndex?: number;
    onInput?: (input: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onAddHistory?: (input: string) => void;
    onHistoryUp?: () => string | undefined;
    onHistoryDown?: () => string | undefined;
    doChat?: (input: string) => Promise<void>;
    doChatCancel?: () => Promise<void>;
    doCode?: (input: string) => Promise<void>;
    doCodeCancel?: () => Promise<void>;
}

export function getActionControls(node: AiosNode): AiosNodeActionControls {
    const { status, inputValue, inputIndex } = getApi(node);
    const { chat, code } = getStatus(node);
    const working = isWait(status) || is(chat?.do) || is(code?.do);
    // const { inputValue, inputIndex } = node.ui;
    const controls: AiosNodeActionControls = {
        working,
        inputValue,
        inputIndex,
        onInput: (value: string) => {
            node.api.inputValue = value;
        },
        onKeyDown: (e: React.KeyboardEvent) => {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                controls.onHistoryUp?.();
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                controls.onHistoryDown?.();
            } else if (e.key === 'Enter') {
                if (e.shiftKey) {
                    // Allow Enter+Shift for multiline input
                    return;
                }
                e.preventDefault();
                if (is(controls.doChat) && is(inputValue)) {
                    void controls.doChat(inputValue);
                }
            }
        },
        onAddHistory: (input: string) => {
            if (!is(input.trim())) {
                return;
            }
            const storedHistory = sessionStorage.getItem(STORAGE_KEY);
            const history = storedHistory !== null ? JSON.parse(storedHistory) as string[] : [];
            if (history.length === 0 || history[history.length - 1] !== input) {
                const newHistory = [...history, input];
                if (newHistory.length > MAX_HISTORY_SIZE) {
                    newHistory.shift();
                }
                sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newHistory));
            }
            node.api.inputIndex = -1;
            if (is(controls.onInput)) {
                controls.onInput('');
            }
        },
        onHistoryUp: () => {
            const storedHistory = sessionStorage.getItem(STORAGE_KEY);
            const history = storedHistory !== null ? JSON.parse(storedHistory) as string[] : [];
            if (history.length === 0) {
                return inputValue;
            }
            const inputIndex: number | undefined = node.api.inputIndex;
            if (inputIndex === undefined || inputIndex === -1) {
                node.api.inputIndex = 0;
                return history[history.length - 1];
            }
            if (inputIndex === history.length - 1) {
                node.api.inputIndex = -2;
                return '';
            }
            if (inputIndex === -2) {
                node.api.inputIndex = 0;
                return history[history.length - 1];
            }
            node.api.inputIndex = inputIndex + 1;
            return history[history.length - 1 - inputIndex];
        },
        onHistoryDown: () => {
            const storedHistory = sessionStorage.getItem(STORAGE_KEY);
            const history = storedHistory !== null ? JSON.parse(storedHistory) as string[] : [];
            if (history.length === 0) {
                return inputValue;
            }
            const inputIndex: number | undefined = node.api.inputIndex;
            if (inputIndex === undefined || inputIndex === -2) {
                node.api.inputIndex = history.length - 1;
                return history[0];
            }
            if (inputIndex === -1) {
                node.api.inputIndex = history.length - 1;
                return history[0];
            }
            if (inputIndex === 0) {
                node.api.inputIndex = -1;
                return '';
            }
            node.api.inputIndex = inputIndex - 1;
            return history[history.length - 1 - inputIndex];
        },
    };

    if (!is(chat?.do)) {
        controls.doChat = async (input: string) => {
            if (is(input)) {
                if (is(controls.onAddHistory)) {
                    controls.onAddHistory(input);
                }
            }
            await doChat(node, input);
            if (is(controls.onInput)) {
                controls.onInput('');
            }
        }
    } else {
        controls.doChatCancel = async () => {
            await doChatCancel(node);
        }
    }

    const { assetFileType } = getData(node);
    if (assetFileType === AiosFileType.Code) {
        if (!is(code?.do)) {
            controls.doCode = async (input: string) => {
                if (is(input)) {
                    if (is(controls.onAddHistory)) {
                        controls.onAddHistory(input);
                    }
                }
                await doCode(node, input);
                if (is(controls.onInput)) {
                    controls.onInput('');
                }
            };
        } else {
            controls.doCodeCancel = async () => {
                await doCodeCancel(node);
            }
        }
    }
    return controls;
}
