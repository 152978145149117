import { AiosStatus, getId, is } from 'aios';
import { saveNodeData } from './saveNodeData';
import { type AiosNode } from 'app/node';
import { getStatus, setStatus } from 'app/status';
import { setApi } from 'app/api';
import { getPath } from 'app/util';

export async function saveNodeNext(node: AiosNode): Promise<boolean | undefined> {
    const { save, saveNext, saveMore } = getStatus(node);
    if (!is(saveNext?.do)) {
        return saveNext?.done;
    }
    if (is(saveNext?.done)) {
        return true;
    }
    if (!is(saveNext?.doing)) {
        setStatus(node, {
            saveNext: { doing: true },
            saveData: { do: true }
        });
        setApi(node, { id: save?.id, output: { text: `saving. ${getPath(node)}`, status: AiosStatus.Processing } });
        const id = getId();
        setStatus(node, { saveData: { id, do: true } });
        setApi(node, { id, output: { text: `saving data ${getPath(node)}`, status: AiosStatus.Processing } });
        return undefined;
    }
    const done = await saveNodeData(node);
    if (done === undefined) {
        return undefined;
    }
    if (!is(done)) {
        setApi(node, {
            id: save?.id,
            output: {
                text: `saving ${getPath(node)}`,
                status: AiosStatus.Fail,
                fields: node.api.action?.output?.fields,
            }
        });
        setStatus(node, { saveNext: { done: false, doing: false } });
        return false;
    }
    const { nodes } = node;
    if (is(nodes)) {
        if (!is(saveMore?.doing)) {
            setStatus(node, { saveMore: { doing: true } });
            setApi(node, { id: save?.id, output: { text: `saving.. ${getPath(node)}`, status: AiosStatus.Processing } });
            return undefined;
        }
        if (is(saveMore?.do)) {
            const saves = nodes.filter(child => {
                const { edit, editEx } = getStatus(child);
                return is(edit) || is(editEx);
            });
            if (is(saves)) {
                const results = await Promise.all(saves.map(async child => {
                    setStatus(child, { saveNext: { do: true } });
                    return await saveNodeNext(child);
                }));
                if (results.some(result => result === false)) {
                    setStatus(node, { saveNext: { do: false, done: false, doing: false } });
                    return false;
                }
                if (results.some(result => result === undefined)) {
                    return undefined;
                }
            }
        }
    }
    setStatus(node, { saveNext: { done: true, doing: false } });
    return true;
}
