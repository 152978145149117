import { is, AiosFileType, set, isFolder } from 'aios';
import { getData } from 'app/data';
import { type AiosNode } from 'app/node';
import { getFunctions } from 'app/ui/data/getFunctions';
import { getUiSchema } from 'app/ui/data/getUiSchema';

export function getModeAndView(node: AiosNode): void {
    const functions = getFunctions(node);
    const { ui } = node;
    const { assetFileType } = getData(node);
    if (isFolder(assetFileType)) {
        ui.mode = 'data';
        ui.view = 'form';
    } else {
        if (!is(node.path.version)) {
            ui.mode = set(ui.mode, 'file');
        } else {
            ui.mode = set(ui.mode, 'data');
        }
        ui.onMode = functions.onMode;
    }
    if (ui.mode === 'data') {
        ui.view = 'form';
        ui.schema = getUiSchema(node);
    } else {
        ui.foot = true;
        if (ui.view === 'form') {
            ui.view = undefined;
        }
        if (assetFileType !== AiosFileType.Image) {
            ui.view = set(ui.view, 'code');
        } else {
            ui.view = set(ui.view, 'image');
        }
        ui.onView = functions.onView;
    }
}

export function getModeAndViewEx(node: AiosNode): void {
    const { ui } = node;
    const { assetFileType } = getData(node);
    if (assetFileType !== AiosFileType.Image) {
        ui.view = set(ui.view, 'code');
    } else {
        if (ui.view !== 'markdown') {
            ui.view = set(ui.view, 'image');
        }
        else {
            ui.view = 'image';
        }
    }
    if (ui.mode === 'data') {
        ui.view = 'form';
        
    } else {
        if (ui.view === 'form') {
            if (assetFileType !== AiosFileType.Image) {
                ui.view = 'code';
            } else {
                ui.view = 'image';
            }
        }
    }
}
