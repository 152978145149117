import { AppContainer } from 'AppContainer';
import { AppProvider } from 'signals';

export const AppRoot = (): JSX.Element => {
  return (
    <AppProvider>      
      <AppContainer />
    </AppProvider>
  );
};
