import { is, remove } from 'aios';
import { type AiosNode, cancelApi, remCache, setCache, setStatus, setStatusEx, setUi } from 'app';
import { AppState } from 'signals/AppState/AppState';

export async function doMore(node: AiosNode): Promise<void> {
  setUi(node, { utilities: true });
  await setCache(node);
  AppState.current.refresh();
}

export async function doTool(node: AiosNode): Promise<void> {
  setUi(node, { advanced: true, utilities: false });
  await setCache(node);
  AppState.current.refresh();
}

export async function doLess(node: AiosNode): Promise<void> {
  setUi(node, { advanced: false, utilities: false, choose: false, chooseMore: false });
  await setCache(node);
  AppState.current.refresh();
}

export async function doLoadReload(node: AiosNode): Promise<void> {
  setUi(node, { advanced: false, utilities: false, choose: false, chooseMore: false });
  await remCache(node);
  setStatusEx(node, {
    load: { do: true, done: undefined, doing: undefined },
    loadNext: { do: true, done: undefined, doing: undefined },
    loadData: { do: true, done: undefined, doing: undefined },
  });
  await setCache(node);
  AppState.current.refresh();
}

export async function doLoadCancel(node: AiosNode): Promise<void> {
  cancelApi(node);
  setStatus(node, {
    load: { do: true, done: undefined, doing: undefined },
    loadNext: { do: true, done: undefined, doing: undefined },
    loadData: { do: true, done: undefined, doing: undefined }
  });
  await setCache(node);
  AppState.current.refresh();
}

export async function doLoadRemove(node: AiosNode): Promise<void> {
  const parent = node.parent;
  if (is(parent)) {
    await remCache(node);
    parent.nodes = remove(parent.nodes, node);
    await setCache(parent);
    AppState.current.goTo(parent.path);
  }
}