import { isFile, is, AiosType, AiosFileType } from 'aios';
import { getData } from 'app/data';
import { type AiosNode, doCreate, doChooseMore, doLess, doLessEx, doCreateEx, doVersionEx } from 'app/node';
import { type AiosNodeControl } from './AiosNodeControl';
import { getUi } from '../data';

export function getControlOptions(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    const { version, assetFileType } = getData(node);
    const { choose, chooseEx, chooseMore } = getUi(node);
    if (is(chooseMore)) {
        controls.push({ name: 'image', icon: 'image', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Image) } });
        controls.push({ name: 'text', icon: 'text', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Text) } });
        // controls.push({ name: 'model', icon: 'model', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Data) } });
        // controls.push({ name: 'data', icon: 'data', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Data) } });
        controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
        return controls;
    }
    if (is(choose)) {
        if (node.type === AiosType.Asset && !is(version)) {
            controls.push({ name: 'version+', icon: 'bulk', doChange: doVersionEx });
            controls.push({ name: 'version', icon: 'version', doChange: async (node: AiosNode) => { await doCreate(node) } });
            // controls.push({ name: 'clone', icon: 'clone', doChange: doCopy });
        }
        if ((node.type === AiosType.Account || node.type === AiosType.Asset) && !is(version)) {
            if (!isFile(assetFileType)) {
                controls.push({ name: 'folder', icon: 'folder', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Folder) } });
                controls.push({ name: 'markdown', icon: 'markdown', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Markdown) } });
                controls.push({ name: 'code', icon: 'code', doChange: async (node: AiosNode) => { await doCreate(node, AiosFileType.Code) } });
                controls.push({ name: 'more', icon: 'data', doChange: doChooseMore });
            }
        }
        if (node.type === AiosType.Asset && is(version)) {
            controls.push({ name: 'access', icon: 'lock', doChange: async (node: AiosNode) => { await doCreate(node) } });
            // controls.push({ name: 'clone', icon: 'clone', doChange: doCopy });
        }
        controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
        return controls;
    }
    if (is(chooseEx)) {
        if (node.type === AiosType.Asset && !is(version)) {
            controls.push({ name: 'version', icon: 'version', doChange: async (node: AiosNode) => { await doCreateEx(node) } });
            // controls.push({ name: 'clone', icon: 'clone', doChange: doCopy });
        }
        if (!isFile(assetFileType)) {
            controls.push({ name: 'folder', icon: 'folder', doChange: async (node: AiosNode) => { await doCreateEx(node, AiosFileType.Folder) } });
            controls.push({ name: 'markdown', icon: 'markdown', doChange: async (node: AiosNode) => { await doCreateEx(node, AiosFileType.Markdown) } });
            controls.push({ name: 'code', icon: 'code', doChange: async (node: AiosNode) => { await doCreateEx(node, AiosFileType.Code) } });
            controls.push({ name: 'more', icon: 'data', doChange: doChooseMore });
        }
        controls.push({ name: 'close', icon: 'cancel', doChange: doLessEx });
        return controls;
    }
    controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
    return controls;
}