import { type AiosAuth } from '../AiosAuth';
import {
  type AiosData,
  type AiosDataInput,
  type AiosDataOutput,
  type AiosException,
  type AiosPath,
  AiosStatus,
  anon,
  createAiosDataOutput,
  createAiosPath,
  is,
  set,
} from '../AiosUtil';

export interface AiosClient {
  url: string;
  auth: AiosAuth;
  token?: string;
  send: AiosClientSendFunction;
  cancel: () => void;
}

export interface AiosClientSendFunctionInput {
  path: AiosPath;
  input: AiosDataInput<AiosData>;
}

export type AiosClientSendFunction = (
  input: AiosClientSendFunctionInput,
) => Promise<AiosDataOutput<AiosData>>;

export function createAiosClient(options?: Partial<AiosClient>): AiosClient {
  const controller = new AbortController();
  const client: AiosClient = {
    url: set(options?.url, 'https://prodev.ai/'),
    auth: set(options?.auth, { id: anon }),
    send: set(
      options?.send,
      async function (
        this: typeof client,
        { path, input }: AiosClientSendFunctionInput,
      ) {
        let url = path?.full as string;
        if (!is(url)) {
          url = createAiosPath(path).path as string;
        }
        if (!is(url)) {
          url = this.url;
        } else {
          url = `${this.url}/${url}`;
        }
        let output = createAiosDataOutput({
          text: `createAiosClient.send: ${url}`,
        });
        try {
          let body: string | undefined;
          if (is(input)) {
            body = JSON.stringify(input);
          }
          let authorization = set(this.auth?.headers?.authorization, '');
          if (is(authorization)) {
            if (!authorization.startsWith('Bearer ')) {
              authorization = `Bearer ${authorization}`;
            }
          }
          if (!is(authorization) && is(this.token)) {
            authorization = `Bearer ${this.token}`;
          }
          const request = {
            method: 'post',
            headers: { authorization },
            body,
            signal: controller.signal,
          };
          const response = await fetch(url, request);
          if (!response.ok) {
            if (response.status === 403) {
              output?.setFail({
                status: AiosStatus.FailAccess,
              });
              output?.setFail({
                text: `${response.status} - ${response.statusText}`,
              });
              return output;
            }
          }
          if (is(response.body)) {
            const result = await response.json();
            output = createAiosDataOutput(result);
          }
        } catch (exception) {
          output?.setException?.(exception as AiosException);
        }
        return output;
      },
    ),
    cancel: set(options?.cancel, function () {
      controller.abort();
    }),
  };
  return client;
}

export default AiosClient;
