import { is, remove, AiosType, type AiosAccount, type AiosAsset } from 'aios';
import { setCache, remCache } from 'app/cache';
import { type AiosNode } from '../AiosNode';
import { getStatus, nullState, setStatus } from 'app/status';

export async function removeNodeOk(node: AiosNode): Promise<string> {
    let redirect = '';
    const { nodes } = node;
    if (is(nodes)) {
        for (const child of nodes) {
            await removeNodeChild(node, child);
        }
    }
    const parent = node.parent;
    if (is(parent)) {
        const { removeData } = getStatus(node);
        if (is(removeData?.done) && is(parent.link)) {
            redirect = parent.link;
        }
        await removeNodeChild(parent, node);
    }
    return redirect;
}

export async function removeNodeChild(node: AiosNode, child: AiosNode): Promise<void> {
    const { nodes: childChildNodes } = child;
    if (is(childChildNodes)) {
        for (const childChildNode of childChildNodes) {
            await removeNodeChild(child, childChildNode);
        }
    }
    const removed = child.status.removeData?.done
    setStatus(child, {
        remove: nullState, 
        removeLoad: nullState, 
        removeConfirm: nullState, 
        removeLast: nullState, 
        removeSkip: nullState, 
        removeData: nullState,
    });
    if (!is(removed)) {
        return;
    }
    const nodeItem = node.item;
    const childItem = child.item;
    if (is(nodeItem) && is(childItem)) {
        removeNodeItem(node, child);
    }
    node.nodes = remove(node.nodes, child);
    await setCache(node);
    await remCache(child);
}

function removeNodeItem(node: AiosNode, child: AiosNode): void {
    const nodeItem = node.item;
    const childItem = child.item;
    if (!is(nodeItem) || !is(childItem)) {
        return;
    }
    switch (node.type) {
        case AiosType.Account:
            if (child.type === AiosType.Asset) {
                const account = nodeItem as AiosAccount;
                if (is(account.assets)) {
                    account.assets = account.assets.filter(a => a.path !== childItem.path);
                }
            }
            break;
        case AiosType.Asset:
            {
                const nodeAsset = nodeItem as AiosAsset;
                if (!is(nodeAsset.version)) {
                    const childAsset = childItem as AiosAsset;
                    if (child.type === AiosType.Asset) {
                        if (!is(childAsset.version)) {
                            if (is(nodeAsset.assets)) {
                                nodeAsset.assets = nodeAsset.assets.filter(a => a.path !== childItem.path);
                            }
                        } else {
                            if (is(nodeAsset.versions)) {
                                nodeAsset.versions = nodeAsset.versions.filter(v => v.path !== childItem.path);
                            }
                        }
                    }
                } else {
                    if (child.type === AiosType.Access) {
                        if (is(nodeAsset.accesses)) {
                            nodeAsset.accesses = nodeAsset.accesses.filter(a => a.path !== childItem.path);
                        }
                    }
                }
            }
            break;
    }
}