import { is } from 'aios';
import { getStatus } from '../../status';
import { type AiosNodeControl } from './AiosNodeControl';
import { type AiosNode, doSaveEx, doEditExCancel, doLoadCancelEx, doSaveExCancel, doVersionEx } from '../../node';
import { getRemoveControls, getUi } from '..';
import { getControlOptions } from './getControlOptions';

export function getControlsEx(node: AiosNode): AiosNodeControl[] {
    const { chooseEx } = getUi(node);
    if (is(chooseEx)) {
        return getControlOptions(node);
    }
    const controls: AiosNodeControl[] = [];
    const { save, editEx, remove } = getStatus(node);
    if (remove?.do !== undefined || is(remove?.done)) {
        return getRemoveControls(node);
    }
    if (is(save?.do) || is(save?.doing)) {
        controls.push({ name: 'cancel', icon: 'cancel', doChange: doSaveExCancel });
        return controls;
    }
    if (is(save?.done)) {
        controls.push({ name: 'ok', icon: 'ok', doChange: doSaveExCancel });
        return controls;
    }
    if (!is(editEx)) {
        // controls.push({ name: 'create+', icon: 'bulk', doChange: doChooseEx });
        // controls.push({ name: 'update+', icon: 'bulk', doChange: doUpdateEx });
        controls.push({ name: 'version+', icon: 'bulk', doChange: doVersionEx });
        controls.push({ name: 'close', icon: 'cancel', doChange: doLoadCancelEx });
        return controls;
    }
    // controls.push({ name: 'create+', icon: 'bulk', doChange: doChooseEx });
    controls.push({ name: 'save', icon: 'save', doChange: doSaveEx });
    controls.push({ name: 'cancel', icon: 'close', doChange: doEditExCancel });
    return controls;
}
