import { type Grid10CellProps, Grid10Cell } from './Grid10Cell';
import { is } from 'aios';
import TextField from '@mui/material/TextField';

export interface Grid10CellInputProps extends Grid10CellProps {
  value?: string;
  onChange?: (value: string) => void;
  onEnter?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  autoComplete?: string;
}

export const Grid10CellInput = ({ 
  value = '', 
  onChange, 
  onEnter,
  onKeyDown,
  autoComplete,
  ...props 
}: Grid10CellInputProps): JSX.Element => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (is(onKeyDown)) {
      onKeyDown(e);
    }
    if (e.key === 'Enter' && is(onEnter)) {
      onEnter();
    }
  };

  return (
    <Grid10Cell {...props}>
      <TextField
        fullWidth
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={props?.disabled}
        autoComplete={autoComplete}
        sx={{
          height: '100%',
          minHeight: 'auto',
          '& .MuiInputBase-root': {
            height: '100%',
            minHeight: 'auto',
          },
          '& .MuiInputBase-input': {
            textAlign: !is(props.center) ? 'left' : 'center',
          },
        }}
      />
    </Grid10Cell>
  );
};