import { is, AiosType, isFolder, isFile } from 'aios';
import { type AiosNodeControl } from './AiosNodeControl';
import {
    type AiosNode, doLoadReload,
    doSignIn,
    doUpdate,
    doRemove,
    doLess,
    doChoose,
    doLoadRemove,
    doUpload,
    doTool,
    doMore,
} from '../../node';
import { getUi } from '..';
import { getData } from '../../data';
import { owner } from 'app/node/aios/authNode';
import { doLoadEx } from 'app/node/bulk';
import { getControlOptions } from './getControlOptions';

export function getControlsView(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    if (!is(owner(node))) {
        controls.push({ name: 'sign in', icon: 'login', doChange: doSignIn });
        const { assetFileName, assetFileDataUrl } = getData(node);
        if (is(assetFileName) && is(assetFileDataUrl)) {
            controls.push({ name: 'download', icon: 'download', downloadUrl: assetFileDataUrl, downloadName: assetFileName });
        }
        return controls;
    }
    const { choose, chooseEx, utilities, advanced } = getUi(node);
    if (is(choose) || is(chooseEx)) {
        return getControlOptions(node);
    }
    if (is(advanced)) {
        return getControlsAdv(node);
    }
    if (is(utilities)) {
        return getControlsUtil(node);
    }
    if (node.type === AiosType.Account || node.type === AiosType.Asset) {
        controls.push({ name: 'create', icon: 'add', doChange: doChoose });
        controls.push({ name: 'update', icon: 'edit', doChange: doUpdate });
    }
    controls.push({ name: 'delete', icon: 'delete', doChange: doRemove });
    controls.push({ name: 'more', icon: 'dots', doChange: doMore });
    return controls;
}

function getControlsUtil(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    const { version, assetFileType, assetFileName, assetFileDataUrl } = getData(node);
    if (node.type === AiosType.Account) {
        // controls.push({ name: 'create+', icon: 'bulk', doChange: doChooseEx });
        controls.push({
            name: 'upload', icon: 'upload', doUpload: async (files: FileList): Promise<boolean> => {
                await doUpload(node, files);
                return true;
            }
        });
        if (is(assetFileName) && is(assetFileDataUrl)) {
            controls.push({ name: 'download', icon: 'download', downloadUrl: assetFileDataUrl, downloadName: assetFileName });
        }
        controls.push({ name: 'utility', icon: 'settings', doChange: doTool });
        controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
        return controls;
    }
    if (node.type === AiosType.Asset) {
        if (isFolder(assetFileType) || (isFile(assetFileType) && !is(version))) {
            controls.push({ name: 'load+', icon: 'bulk', doChange: doLoadEx });
        }
        // if (!is(version)) {
        //     controls.push({
        //         name: 'upload', icon: 'upload', doUpload: async (files: FileList): Promise<boolean> => {
        //             await doUpload(node, files);
        //             return true;
        //         }
        //     });
        // }
        if (is(assetFileName) && is(assetFileDataUrl)) {
            controls.push({ name: 'download', icon: 'download', downloadUrl: assetFileDataUrl, downloadName: assetFileName });
        }
        controls.push({ name: 'utility', icon: 'settings', doChange: doTool });
        controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
        return controls;
    }
    if (node.type === AiosType.Access) {
        controls.push({ name: 'utility', icon: 'settings', doChange: doTool });
        controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
        return controls;
    }
    controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
    return controls;
}

function getControlsAdv(node: AiosNode): AiosNodeControl[] {
    const controls: AiosNodeControl[] = [];
    controls.push({ name: 'reload', icon: 'reload', doChange: doLoadReload });
    controls.push({ name: 'remove', icon: 'stop', doChange: doLoadRemove });

    // const { assetFileName, jsonDataUrl } = getData(node);
    // if (is(assetFileName) && is(jsonDataUrl)) {
    //     controls.push({ name: 'json', icon: 'download', downloadUrl: jsonDataUrl, downloadName: `${assetFileName}.json` });
    // }
    // controls.push({ name: 'set json', icon: 'upload', doChange: async (node: AiosNode) => { await doCreate(node) } });
    // controls.push({ name: 'get json', icon: 'download', doChange: async (node: AiosNode) => { await doCreate(node) } });
    // controls.push({ name: 'set zip', icon: 'upload', doChange: async (node: AiosNode) => { await doCreate(node) } });
    // controls.push({ name: 'get zip', icon: 'download', doChange: async (node: AiosNode) => { await doCreate(node) } });

    controls.push({ name: 'close', icon: 'cancel', doChange: doLess });
    return controls;
}