import { getData, setApi, type AiosNode } from 'app';
import { AiosStatus, AiosType, is, isFolder } from 'aios';
import { type AiosNodeState, getStatus, setStatus } from 'app/status';
import { loadNodeData } from './loadNodeData';

export async function loadNodeNext(node: AiosNode): Promise<boolean | undefined> {
  const { loadNext, loadMore, loadAll } = getStatus(node);
  if (!is(loadNext?.do)) {
    return loadNext?.done;
  }
  if (is(loadNext?.done)) {
    return true;
  }
  if (!is(loadNext?.doing)) {
    setStatus(node, {
      loadNext: { doing: true },
      loadData: { id: loadNext?.id, do: true }
    });
    setApi(node, { id: loadNext?.id, output: { text: 'loading data', status: AiosStatus.Processing } });
    return undefined;
  }
  const done = await loadNodeData(node);
  if (done === undefined) {
    return undefined;
  }
  if (!is(done)) {
    setStatus(node, { loadData: { id: loadNext?.id, do: false, done: false, doing: false } });
    return false;
  }
  if (is(node.nodes)) {
    if (is(loadMore?.do)) {
      if (!is(loadMore?.doing)) {
        setStatus(node, { loadMore: { doing: true } });
        setApi(node, { id: loadNext?.id, output: { text: 'loading more', status: AiosStatus.Processing } });
        return undefined;
      }
      const results = await Promise.all(node.nodes.map(async child => {
        const loadMoreChild = getLoadMore(node, child);
        if (!is(loadMoreChild?.do)) {
          return true;
        }
        setStatus(child, {
          loadData: { id: loadNext?.id, do: true },
          loadNext: { id: loadNext?.id, do: true },
          loadMore: loadMoreChild,
          loadAll
        });
        return await loadNodeNext(child);
      }));
      if (is(results.some(result => result === false))) {
        setStatus(node, { loadNext: { do: false, done: false, doing: false } });
        return false;
      }
      if (is(results.some(result => result === undefined))) {
        return undefined;
      }
    }
  }
  setStatus(node, {
    loadNext: { do: false, done: true, doing: false },
    loadMore: { do: false, done: true, doing: false }
  });
  return true;
}

function getLoadMore(node: AiosNode, child: AiosNode): AiosNodeState | undefined {
  const { loadEx, loadAll } = getStatus(node);
  if (is(loadAll?.do)) {
    return { do: true };
  }
  if (is(loadEx)) {
    if (child.type === AiosType.Asset) {
      const { asset, assetFileType } = getData(node);
      if (isFolder(assetFileType)) {
        return { do: true };
      }
      if (asset?.forward === child.path.full) {
        return { do: true };
      }
    }
    if (child.type === AiosType.Access) {
      return { do: true };
    }
  }
}