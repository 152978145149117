import { type AiosPath, is, set } from 'aios';
import { getClass, useRoute } from 'signals';
import { Grid10Cell } from './Grid10Cell';
import { getGrid2IconProps, type Grid10CellIconProps } from './Grid10CellIcon';
import { getIcon } from 'components/getIcon';
import { useRef } from 'react';

export interface Grid10CellButtonProps extends Grid10CellIconProps {
  path?: AiosPath;
  link?: string;
  indent?: string;
  onClick?: () => Promise<void>;
  onUpload?: (files: FileList) => Promise<boolean>;
}

export const Grid10CellButton = (props: Grid10CellButtonProps): JSX.Element => {
  const { goTo, go } = useRoute();
  const { icon } = getGrid2IconProps(props);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onClick = (): void => {
    if (is(props.onUpload)) {
      if (is(fileInputRef?.current)) {
        fileInputRef.current.click();
      }
      return;
    }
    if (is(props.onClick)) {
      void props.onClick();
    }
    if (is(props.path)) {
      goTo(props.path)
    }
    if (is(props.link)) {
      go(props.link)
    }
  }
  const doUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!is(props.onUpload)) {
      return;
    }
    const handled = await props.onUpload(event.currentTarget.files as FileList);
    if (is(fileInputRef?.current)) {
      fileInputRef.current.value = '';
    }
    if (!is(handled) && is(props.path)) {
      goTo(props.path);
    }
  };
  const swirlIcon = is(props?.swirl) ? getIcon('swirl') : null;
  const className = is(props.spin) ? 'swirl-overlay swirl' : 'swirl-overlay';
  return (
    <Grid10Cell {...props}>
      <div className={getClass('grid10-cell-button')} onClick={onClick}>
        {is(props.icon) ? (
          <div style={{ width: is(props.name) ? '80%' : undefined }} className={getClass('grid10-cell-icon')}>
            {is(props?.swirl) ? <span className="icon">{icon}</span> : icon}
            {is(props?.swirl) && (
              <div className={className}>
                {swirlIcon}
              </div>
            )}
          </div>
        ) : (<></>)}
        {is(props.name) ? (
          <div className={getClass('grid10-cell-text')}>
            <span className={getClass('grid10-cell-span')} data-testid={props.test}>{`${set(props.indent, '')}${set(props.name, '')}`}</span>
          </div>
        ) : (<></>)}
      </div>
      {is(props.onUpload) ? (
        <input
          hidden
          ref={fileInputRef}
          type='file'
          name={props.name}
          onChange={(e) => {
            void doUpload(e);
          }}
          data-test='file-upload'
        />
      ) : (<></>)}
    </Grid10Cell>
  );
}
