import { AiosDataCommand, is, isOk, set } from 'aios';
import { type AiosNode } from 'app/node';
import { getStatus, setStatus } from 'app/status';
import { processApi, setApi } from 'app/api';
import { getPath } from 'app/util';

export async function removeNodeData(node: AiosNode): Promise<boolean | undefined> {
  const { removeData } = getStatus(node);
  if (!is(removeData?.do) || is(removeData?.done)) {
    return removeData?.done;
  }
  if (is(removeData?.doing)) {
    return undefined;
  }
  setStatus(node, { removeData: { doing: true } });
  const action = await processApi(node, AiosDataCommand.Remove);
  const text = set(action?.output?.text, '?');
  if (!isOk(action.output?.status)) {
    setStatus(node, { removeData: { do: false, doing: false, done: false } });
    setApi(node, {
      id: removeData?.id,
      output: {
        ...action?.output,
        text: `deleting data ${getPath(node)} ~ ${text}`
      }
    });
    return false;
  }
  setStatus(node, { removeData: { do: false, doing: false, done: true } });
  setApi(node, { id: removeData?.id, output: { ...action?.output, text: `deleted data ${getPath(node)}` } });
  return true;
}
