import { append, is, isFolder } from 'aios';
import { createClone, createCopy, generateNewNodeName, type AiosNode } from '../../node';
import { setStatus } from '../../status';
import { getData, setData } from '../../data';
import { newUi, setCache, sortNode } from 'app';
import { AppState } from 'signals/AppState/AppState';

export async function setContent(node: AiosNode, text: string, files?: Array<{ name: string; text: string }>): Promise<string> {
    if (!is(files)) {
        return text;
    }
    let edit = node;
    if (!is(edit.status.edit)) {
        edit = createClone(node);
        const name = generateNewNodeName(node, 'edit');
        edit.parent = node;
        edit.link = `${node.link as string}/${name}`;
        newUi(edit);
        createCopy(edit);
        setStatus(edit, { load: { done: true }, loadData: { done: true }, edit: true });
    }
    let copied = false;
    const createFiles = [];
    const { assetFileType } = getData(edit);
    for (const file of files) {
        let handled = false;
        if (is(file.name)) {
            file.name = file.name.toLowerCase();
        }
        const { assetPath } = getData(edit);
        if (is(nameMatch(file.name, assetPath))) {
            copied = true;
            setData(edit, { assetFileText: file.text });
            handled = true;
        } else {
            if (isFolder(assetFileType)) {
                const { nodes } = edit;
                if (is(nodes)) {
                    for (const child of nodes) {
                        const { assetPath } = getData(child);
                        if (is(nameMatch(file.name, assetPath))) {
                            copied = true;
                            setData(child, { assetFileText: file.text });
                            handled = true;
                            break;
                        }
                    }
                }
            }
        }
        if (!is(handled)) {
            createFiles.push(file);
        }
    }
    if (is(copied)) {
        await setCache(edit);
        node.nodes = append(node.nodes, edit);
        sortNode(node);
        await setCache(node);
        AppState.current.go(edit.link as string);
    }

    // let go;
    // let root = node;
    // copied = false;
    // for (const file of createFiles) {
    //     if (node.type === AiosType.Account) {
    //         if (createFiles.length === 1) {
    //             const newNode = createNodeFile(root, file.name, file.text);
    //             const name = generateNewNodeName(node, 'new');
    //             newNode.link = `${node.link as string}/${name}`;
    //             setStatus(newNode, { load: { done: true }, edit: true, create: true });
    //             node.nodes = append(node.nodes, newNode);
    //             sortNode(node);
    //             await setCache(node);
    //             await setCache(newNode);
    //             go = newNode.link;
    //             continue;
    //         }
    //         if (!is(go)) {
    //             root = createNode(node, AiosFileType.Folder);
    //             const name = generateNewNodeName(node, 'new');
    //             root.link = `${node.link as string}/${name}`;
    //             setStatus(root, { load: { done: true }, loadData: { done: true }, loadEx: true, createEx: true, editEx: true });
    //             node.nodes = append(node.nodes, root);
    //             sortNode(root);
    //             await setCache(node);
    //             await setCache(root);
    //             const { copies, copyIndex } = node;
    //             if (is(copies) && is(copyIndex)) {
    //                 const currentCopy = copies[copyIndex];
    //                 if (is(currentCopy)) {
    //                     currentCopy.nodes = append(currentCopy.nodes, root);
    //                 }
    //             }
    //             go = root.link;
    //         }
    //         const newNode = createNodeFile(root, file.name, file.text);
    //         newNode.link = newNode.path.full as string;
    //         setStatus(newNode, { load: { done: true }, loadEx: true, createEx: true, editEx: true });
    //         root.nodes = append(root.nodes, newNode);
    //         sortNode(root);
    //         await setCache(root);
    //         await setCache(newNode);
    //         if (!is(go)) {
    //             go = newNode.link;
    //         }
    //         continue;
    //     }
    //     if (node.type === AiosType.Asset) {
    //         if (isFolder(assetFileType)) {
    //             if (!is(copied)) {
    //                 createCopy(node);
    //                 setStatusEx(node, { loadEx: true, editEx: true });
    //                 copied = true;
    //             }
    //             const newNode = createNodeFile(node, file.name, file.text);
    //             setStatus(newNode, { load: { done: true }, loadEx: true, editEx: true, createEx: true });
    //             node.nodes = append(node.nodes, newNode);
    //             sortNode(node);
    //             await setCache(node);
    //             await setCache(newNode);
    //             const { copies, copyIndex } = node;
    //             if (is(copies) && is(copyIndex)) {
    //                 const currentCopy = copies[copyIndex];
    //                 if (is(currentCopy)) {
    //                     currentCopy.nodes = append(currentCopy.nodes, newNode);
    //                 }
    //             }
    //             continue;
    //         }
    //         const parent = node.parent;
    //         if (is(parent)) {
    //             const { assetFileType: parentType } = getData(parent);
    //             if (isFolder(parentType)) {
    //                 if (!is(copied)) {
    //                     createCopy(node);
    //                     setStatusEx(node, { loadEx: true, editEx: true });
    //                     copied = true;
    //                 }
    //             }
    //             const newNode = createNodeFile(parent, file.name, file.text);
    //             setStatus(newNode, { load: { done: true }, loadEx: true, editEx: true, createEx: true });
    //             parent.nodes = append(parent.nodes, newNode);
    //             sortNode(parent);
    //             await setCache(parent);
    //             await setCache(newNode);
    //             go = newNode.link;
    //         }
    //     }
    // }
    // if (is(go)) {
    //     AppState.current.go(go);
    // }
    return text;
}

function nameMatch(name1: string | undefined, name2: string | undefined): boolean {
    if (!is(name1) || !is(name2)) {
        return false;
    }
    const lastName1 = name1.split('/').pop()?.toLowerCase();
    const lastName2 = name2.split('/').pop()?.toLowerCase();
    return is(lastName1) && is(lastName2) && lastName1 === lastName2;
}