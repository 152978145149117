import { createAiosNode, type AiosNode } from '../AiosNode';
import { getStatus, setStatus } from '../../status';
import { AiosFileType, createAiosAsset, createAiosFile, is, append, createAiosPath, AiosType, set } from 'aios';
import { chatNode } from '../chat';

export async function adminNode(node: AiosNode): Promise<void> {
  await loadContent(node, 'system', '/docs/prodev.md');
  node.nodes = [];
  const core = await createContent(node, 'core', '/docs/core/core.md');
  if (is(core)) {
    await createContent(core, 'core/account', '/docs/core/account.md', 'account');
    await createContent(core, 'core/asset', '/docs/core/asset.md', 'asset');
    await createContent(core, 'core/publishing', '/docs/core/pubshing.md', 'publishing');
    await createContent(core, 'core/redirection', '/docs/core/redirection.md', 'redirection');
  }
  await createContent(node, 'express', '/docs/express/express.md');
  await createContent(node, 'code', '/docs/code/code.md');
  await createContent(node, 'chat', '/docs/chat/chat.md');
  const { chat } = getStatus(node);
  if (is(chat?.do)) {
    await chatNode(node);
  }
}

async function createContent(node: AiosNode, name: string, path: string, index?: string): Promise<AiosNode | undefined> {
  const link = `aios/${name}`;
  const existingNode = node.nodes?.find(childNode => childNode.link === link);
  if (is(existingNode)) {
    return existingNode;
  }
  const response = await fetch(path);
  if (response.ok) {
    const text = await response.text();
    const asset = createAiosAsset({
      name,
      path: link,
      file: createAiosFile({ type: AiosFileType.Markdown, text })
    });
    const childNode = createAiosNode({
      parent: node,
      type: AiosType.Asset,
      path: createAiosPath({ path: asset.path }),
      index: set(index, name),
      item: asset,
      link
    });
    setStatus(childNode, { load: { done: true } });
    node.nodes = append(node.nodes, childNode);
    return childNode;
  }
}

async function loadContent(node: AiosNode, name: string, path: string): Promise<void> {
  const response = await fetch(path);
  const text = await response.text();
  const asset = createAiosAsset({
    name,
    path,
    file: createAiosFile({ type: AiosFileType.Markdown, text })
  });
  node.item = asset;
  setStatus(node, { load: { done: true } });
}
