import { createAiosNode } from 'app';
import { type AppSettings } from './AppSettings';
import { AiosStatus, createAiosPath, createAiosOutput, type AiosPath, createAiosAction, AiosType } from 'aios';

export class AppState {
  static current: AppState;

  constructor() {
    AppState.current = this;
  }

  root = createAiosNode({
    path: createAiosPath({ path: '/' }),
    type: AiosType.AiosNodeRoot,
    api: {
      action: createAiosAction({ output: createAiosOutput({ status: AiosStatus.Ok }) }),
    },
    nodes: [
      createAiosNode({
        path: createAiosPath({ path: 'aios' }),
        type: AiosType.AiosNodeAios,
      }),
    ],
  });

  settings: AppSettings = {
    menu: false,
    dark: false,
    thin: false,
    wapp: false,
  };

  setSettings = (values: Partial<AppSettings>): void => {
    // const settings = { ...this.settings, ...values };
    // this.refresh({ settings });
  };

  full?: string;

  goTo = (path: AiosPath): void => {
    console.log('AppState goto not implemented');
    console.log(path);
  }

  go = (link: string): void => {
    console.log('AppState go not implemented');
    console.log(link);
  }

  refresh = (app?: Partial<AppState>): void => {
    console.log('AppState refresh not implemented');
    console.log(app);
  };
}
