import { AiosType, append, is, set } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { getIcon, getName, sortNodes } from '../../util';
import { type AiosNodeIndexItemUi } from './IndexUi';
import { AppState } from 'signals/AppState/AppState';
import { setCache } from 'app/cache';

export async function setIndexUi(node: AiosNode, options?: AiosNodeIndexItemUi): Promise<void> {
    const indexOpen = options?.openIndex;
    if (indexOpen !== undefined) {
        node.indexOpen = indexOpen;
        await setCache(node);
    }
}

export function getIndexUi(node: AiosNode, depth = 0): AiosNodeIndexItemUi {
    const ui: AiosNodeIndexItemUi = {};
    if (node.type === AiosType.AiosNodeRoot) {
        ui.show = false;
        ui.showNodes = node.nodes;
        return ui;
    }
    ui.selected = checkSelected(node, AppState.current.full as string);
    ui.openIndex = set(node.indexOpen, ui.selected);
    ui.showIndex = is(node.nodes);
    const { load, loadMore } = getStatus(node);
    const { path, link, index } = node;
    ui.show = true;
    ui.icon = getIcon(node);
    if (is(node.status.removeData?.done)){
        ui.icon = 'cancel';
    }
    if (is(load?.doing)) {
        ui.icon = 'aios';
        ui.spin = true;
    }
    if (node.type === AiosType.AiosNodeAios) {
        ui.icon = 'aios';
        ui.link = 'aios';
        ui.label = 'system';
        if (is(node.nodes) && is(ui.openIndex)) {
            ui.showNodes = node.nodes;
        }
        return ui;
    }
    if (node.parent?.type === AiosType.AiosNodeAios) {
        ui.icon = 'markdown';
        ui.link = node.link;
        ui.label = node.index;
        if (is(node.nodes) && is(ui.openIndex)) {
            ui.showNodes = node.nodes;
        }
        return ui;
    }
    ui.name = getName(node);
    ui.test = set(path.assetPath, '');
    if (is(ui.test)) {
        ui.test = ui.test.replaceAll('/', '-').replaceAll('.', '-');
    }
    ui.link = link;
    ui.index = index;
    ui.label = ui.name;
    const editMatch = link?.match(/\/edit(\d+)$/);
    if (is(editMatch)) {
        ui.label = `edit${editMatch[1]}`;
    }
    if (is(index)) {
        ui.label = index;
    }
    ui.indent = '';
    for (let i = 0; i < depth - 1; i++) {
        ui.indent = `/ ${ui.indent}`;
    }
    if (is(ui.indent) && is(ui.index)) {
        ui.index = `${ui.indent} ${ui.index}`;
    }
    ui.loadingNodes = is(loadMore?.doing);
    return getChildren(node, ui);
}

function getChildren(node: AiosNode, ui: AiosNodeIndexItemUi): AiosNodeIndexItemUi {
    const currentPath = AppState.current.full as string;
    ui.showNodes = undefined;
    if (!is(ui.openIndex)) {
        return ui;
    }
    if (is(node.status.editEx)) {
        return ui;
    }
    const { nodes } = node;
    if (!is(nodes)) {
        return ui;
    }
    for (const child of nodes) {
        const nodePath = node.path.path;
        const childPath = child.path.path;
        if (!is(nodePath) || !is(childPath)) {
            continue;
        }
        if (!childPath.startsWith(nodePath)) {
            if (child.path.assetPath !== node.path.assetPath) {
                continue;
            }
        }
        const childSelected = checkSelected(child, currentPath);
        if (is(childSelected)) {
            ui.showNodes = append(ui.showNodes, child);
            continue;
        }
        if (node.link !== currentPath) {
            continue;
        }
        ui.showNodes = append(ui.showNodes, child);
    }
    if (is(ui.showNodes)) {
        ui.showNodes = sortNodes(ui.showNodes);
    }
    return ui;
}

function checkSelected(node: AiosNode, path?: string): boolean {
    if (!is(path)) {
        return false;
    }
    if (node.link === path) {
        return true;
    }
    const { nodes } = node;
    if (!is(nodes)) {
        return false;
    }
    for (const child of nodes) {
        if (child.link === path) {
            return true;
        }
        if (is(checkSelected(child, path))) {
            return true;
        }
    }
    return false;
}