import is from '../is';

export function append<T>(
  array: T[] | undefined,
  item: T | T[] | undefined | null,
): T[] | undefined {
  if (!is(item)) {
    return array;
  }

  if (array === undefined || array === null) {
    return Array.isArray(item) ? [...item] : [item];
  }

  return Array.isArray(item) ? [...array, ...item] : [...array, item];
}

export default append;
