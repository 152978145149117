import { type AiosNode } from 'app/node';
import { type AiosNodeUi } from './AiosNodeUi';
import { is } from 'aios';

export function newUiEx(node: AiosNode, ui?: Partial<AiosNodeUi>): void {
    node.ui = {};
    if (is(ui)) {
        setUiValues(node, ui);
    }
    const { nodes } = node;
    if (!is(nodes)) {
        return;
    }
    for (const child of nodes) {
        if (is(child)) {
            newUiEx(child, ui);
        }
    }
}

export function newUi(node: AiosNode, ui?: Partial<AiosNodeUi>): void {
    node.ui = {};
    if (is(ui)) {
        setUiValues(node, ui);
    }
}

export function setUi(node: AiosNode, ui: Partial<AiosNodeUi>): void {
    if (!is(node.ui)) {
        node.ui = {};
    }
    setUiValues(node, ui);
}

function setUiValues(node: AiosNode, ui: Partial<AiosNodeUi>): void {
    function setndef<T>(value: T, def: T): T {
        return value === undefined ? def : value;
    }
    node.ui.icon = setndef(ui.icon, node.ui.icon);
    node.ui.spin = setndef(ui.spin, node.ui.spin);
    node.ui.onTick = setndef(ui.onTick, node.ui.onTick);
    node.ui.tick = setndef(ui.tick, node.ui.tick);
    node.ui.path = setndef(ui.path, node.ui.path);
    node.ui.onPath = setndef(ui.onPath, node.ui.onPath);
    node.ui.note = setndef(ui.note, node.ui.note);
    node.ui.onBack = setndef(ui.onBack, node.ui.onBack);
    node.ui.index = setndef(ui.index, node.ui.index);
    node.ui.onNext = setndef(ui.onNext, node.ui.onNext);
    node.ui.keep = setndef(ui.keep, node.ui.keep);
    node.ui.onKeep = setndef(ui.onKeep, node.ui.onKeep);
    node.ui.mode = setndef(ui.mode, node.ui.mode);
    node.ui.onMode = setndef(ui.onMode, node.ui.onMode);
    node.ui.more = setndef(ui.more, node.ui.more);
    node.ui.onMore = setndef(ui.onMore, node.ui.onMore);
    node.ui.schema = setndef(ui.schema, node.ui.schema);
    node.ui.disabled = setndef(ui.disabled, node.ui.disabled);
    node.ui.view = setndef(ui.view, node.ui.view);
    node.ui.onView = setndef(ui.onView, node.ui.onView);
    node.ui.expand = setndef(ui.expand, node.ui.expand);
    node.ui.onExpand = setndef(ui.onExpand, node.ui.onExpand);
    node.ui.progress = setndef(ui.progress, node.ui.progress);
    node.ui.choose = setndef(ui.choose, node.ui.choose);
    node.ui.chooseEx = setndef(ui.chooseEx, node.ui.chooseEx);
    node.ui.chooseMore = setndef(ui.chooseMore, node.ui.chooseMore);
    node.ui.utilities = setndef(ui.utilities, node.ui.utilities);
    node.ui.advanced = setndef(ui.advanced, node.ui.advanced);
    node.ui.flagged = setndef(ui.flagged, node.ui.flagged);
}