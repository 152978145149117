import { type AiosAccess, type AiosAsset, AiosFileType, AiosType, append, is, isFolder, isText, remove } from 'aios';
import { type AiosNode } from '../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { appendChild, createAccessNode, createAssetNode, createAssetVersionNode, createNode, createNodeFile, generateNewNodeName, getFileTypeFromExtension } from '../create';
import { remCache, setCache, setCacheAll, setCacheEx } from '../../cache';
import { nullState, setStatus, setStatusAll, setStatusEx, startState } from '../../status';
import { newUiEx, setUi } from '../../ui';
import { createClone, createCopy } from '../copy';
import { setApi } from 'app/api';
import { sortNode } from 'app/util';
import { getData, setData } from 'app/data';

export async function doLoadEx(node: AiosNode): Promise<void> {
  newUiEx(node);
  setStatusEx(node, {
    load: { do: true, doing: undefined, done: undefined },
    save: nullState,
    loadEx: true,
    working: true,
  });
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doLoadCancelEx(node: AiosNode): Promise<void> {
  setStatusEx(node, { loadEx: false, editEx: false, createEx: false, versionEx: false });
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doChooseEx(node: AiosNode): Promise<void> {
  setUi(node, { chooseEx: true });
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doLessEx(node: AiosNode): Promise<void> {
  setUi(node, { chooseEx: false });
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doCreateEx(node: AiosNode, fileType?: AiosFileType): Promise<void> {
  setUi(node, { choose: false, chooseEx: false, chooseMore: false });
  let cnew;
  let copy = node;
  if (!is(node.status.editEx)) {
    cnew = true;
    copy = await copyEx(node);
  }
  const newNode = createNode(copy, fileType);
  if (newNode.type !== AiosType.Access) {
    const name = generateNewNodeName(copy, 'new');
    newNode.link = `${copy.link as string}/${name}`;
  } else {
    newNode.link = newNode.path.full as string;
  }
  setStatus(newNode, { load: { done: true }, loadData: { done: true }, loadEx: true, editEx: true, createEx: true });
  await appendChild(copy, newNode);
  const { copies, copyIndex } = node;
  if (is(copies) && is(copyIndex)) {
    const currentCopy = copies[copyIndex];
    if (is(currentCopy)) {
      currentCopy.nodes = append(currentCopy.nodes, newNode);
    }
  }
  await setCacheEx(copy);
  if (is(cnew) && is(copy?.link)) {
    AppState.current.go(copy.link);
    return;
  }
  AppState.current.refresh();
}

export async function doUpdateEx(node: AiosNode): Promise<void> {
  const copy = await copyEx(node);
  if (is(copy?.link)) {
    AppState.current.go(copy.link);
    return;
  }
  AppState.current.refresh();
}

function getNextVersion(node: AiosNode): string {
  if (node.type !== AiosType.Asset) {
    return '0.0.0';
  }
  const asset = node.item as AiosAsset;
  if (!is(asset)) {
    return '0.0.0';
  }
  if (!is(node.nodes)) {
    return '0.0.0';
  }
  let highestVersion = { major: 0, minor: 0, patch: 0 };
  let versionFound = false;
  for (const child of node.nodes) {
    const version = child.path.version;
    if (is(version)) {
      const match = version.match(/(\d+)\.(\d+)\.(\d+)/);
      if (is(match)) {
        versionFound = true;
        const [, major, minor, patch] = match.map(Number);
        if (major > highestVersion.major ||
          (major === highestVersion.major && minor > highestVersion.minor) ||
          (major === highestVersion.major && minor === highestVersion.minor && patch > highestVersion.patch)) {
          highestVersion = { major, minor, patch };
        }
      }
    }
  }
  if (versionFound) {
    return `${highestVersion.major}.${highestVersion.minor}.${highestVersion.patch + 1}`;
  }
  return '0.0.0';
}

export async function doVersionEx(node: AiosNode): Promise<void> {
  if (node.type !== AiosType.Asset) {
    return;
  }
  const asset = node.item as AiosAsset;
  if (!is(asset)) {
    return;
  }
  const newVersion = getNextVersion(node);
  const forward = `${node.path.account as string}@${newVersion}/${node.path.assetPath as string}`;
  const copy = await copyExEx(node);
  const version = createAssetVersionNode(copy, newVersion);
  copy.nodes = append(copy.nodes, version);
  const access = createAccessNode(version);
  version.nodes = append(version.nodes, access);
  if (is(node.nodes)) {
    const oldVersion = node.nodes.find(child => child.path.path === asset.forward);
    if (is(oldVersion)) {
      const oldAccess = oldVersion.nodes?.[0];
      if (is(oldAccess) && oldAccess.type === AiosType.Access) {
        const accessItem = access.item as AiosAccess;
        const oldAccessItem = oldAccess.item as AiosAccess;
        if (is(accessItem) && is(oldAccessItem)) {
          accessItem.actorId = oldAccessItem.actorId;
          accessItem.accessType = oldAccessItem.accessType;
        }
      }
    }
  }
  setData(copy, { item: { forward } });
  setStatusEx(copy, {
    load: { done: true },
    loadData: { done: true },
    loadEx: true,
    editEx: true,
    versionEx: true
  });
  setStatus(version, { createEx: true });
  setStatus(access, { createEx: true });
  await setCache(node);
  await setCacheEx(copy);
  if (is(copy?.link)) {
    AppState.current.go(copy.link);
    return;
  }
  AppState.current.refresh();
}

export async function doEditExCancel(node: AiosNode): Promise<void> {
  await remCache(node);
  const parent = node.parent;
  if (is(parent)) {
    parent.nodes = remove(parent.nodes, node);
    setStatusEx(parent, { loadEx: false, createEx: false, versionEx: false });
    await setCache(parent);
    AppState.current.goTo(parent.path);
  }
}

export async function doSaveEx(node: AiosNode): Promise<void> {
  setStatusEx(node, {
    save: { do: true, doing: undefined, done: undefined },
    saveData: startState,
    saveNext: startState,
    saveMore: startState,
    working: true
  });
  setApi(node);
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doSaveExCancel(node: AiosNode): Promise<void> {
  setStatusEx(node, { save: nullState, saveData: nullState, loadEx: false, editEx: false, createEx: false, versionEx: false, working: false });
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doUploadEx(node: AiosNode, files: FileList): Promise<void> {
  let loadInto = node;
  if (loadInto.type === AiosType.AiosNodeAios) {
    const root = AppState.current.root;
    const { nodes } = root;
    if (is(nodes)) {
      for (let i = 0; i < nodes.length; i++) {
        const accountNode = nodes[i];
        if (accountNode.type === AiosType.Account) {
          loadInto = accountNode;
          break;
        }
      }
    }
  }
  if (files.length === 0) {
    return;
  }
  if (files.length === 1) {
    const file = files[0];
    // Create a file node and add file, status is edit, create
    const fileType = getFileTypeFromExtension(file.name);
    let fileText: string | undefined;
    let fileBase: string | undefined;

    if (fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
      fileType === AiosFileType.Video || fileType === AiosFileType.Data) {
      fileBase = await readFileAsBase64(file);
    } else {
      fileText = await readFileAsText(file);
    }

    const { assetFileType } = getData(loadInto);
    if (loadInto.type === AiosType.Account || isFolder(assetFileType)) {
      const newNode = createNodeFile(loadInto, file.name,
        fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
          fileType === AiosFileType.Video || fileType === AiosFileType.Data ? fileBase : fileText);
      const name = generateNewNodeName(loadInto, 'new');
      newNode.link = `${loadInto.link as string}/${name}`;
      createCopy(newNode);
      setStatus(newNode, { load: { done: true }, edit: true, create: true });
      newUiEx(newNode);
      await setCache(newNode);
      loadInto.nodes = append(loadInto.nodes, newNode);
      sortNode(loadInto);
      await setCache(loadInto);
      AppState.current.go(newNode.link);
      return;
    }

    if (isText(assetFileType) && is(fileText)) {
      createCopy(loadInto);
      setData(loadInto, { assetFileText: fileText });
      setStatus(loadInto, { edit: true });
      setUi(loadInto, { choose: false, utilities: false });
      await setCache(loadInto);
      AppState.current.refresh();
      return;
    }

    if (assetFileType === AiosFileType.Image && is(fileBase)) {
      createCopy(loadInto);
      setData(loadInto, { assetFileBase: fileBase });
      setStatus(loadInto, { refresh: true });
      await setCache(loadInto);
      AppState.current.refresh();
    }
    return;
  }

  // Create a folder node and add files, status is editEx
  const folderNode = createAssetNode(loadInto, AiosFileType.Folder);
  const folderName = generateNewNodeName(loadInto, 'folder');
  folderNode.link = `${loadInto.link as string}/${folderName}`;
  createCopy(folderNode);
  setStatusAll(folderNode, { load: { done: true }, loadData: { done: true }, loadEx: true, editEx: true, createEx: true });
  newUiEx(folderNode);

  // Make sure the folder loadInto is properly added to parent
  await appendChild(loadInto, folderNode);
  await setCache(folderNode);
  sortNode(loadInto);
  await setCache(loadInto);

  // Process all files and add them to the folder
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileType = getFileTypeFromExtension(file.name);
    let fileText: string | undefined;
    let fileBase: string | undefined;

    if (fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
      fileType === AiosFileType.Video || fileType === AiosFileType.Data) {
      fileBase = await readFileAsBase64(file);
    } else {
      fileText = await readFileAsText(file);
    }

    const newNode = createNodeFile(folderNode, file.name,
      fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
        fileType === AiosFileType.Video || fileType === AiosFileType.Data ? fileBase : fileText);

    await appendChild(folderNode, newNode);
    await setCache(newNode);
  }

  sortNode(folderNode);
  setStatusAll(folderNode, { load: { done: true }, loadEx: true, editEx: true, createEx: true });
  await setCacheAll(folderNode);
  AppState.current.go(folderNode.link);
}

async function copyEx(node: AiosNode): Promise<AiosNode> {
  const copy = createClone(node);
  const name = generateNewNodeName(node, 'edit');
  copy.parent = node;
  copy.link = `${node.link as string}/${name}`;
  createCopy(copy);
  setStatusEx(copy, { load: { done: true }, loadData: { done: true }, loadEx: true, editEx: true });
  await setCacheEx(copy);
  node.nodes = append(node.nodes, copy);
  sortNode(node);
  await setCache(node);
  return copy;
}

async function copyExEx(node: AiosNode): Promise<AiosNode> {
  const copy = createClone({ ...node, nodes: undefined });
  const name = generateNewNodeName(node, 'edit');
  copy.parent = node;
  copy.link = `${node.link as string}/${name}`;
  createCopy(copy);
  setStatusEx(copy, { load: { done: true }, loadData: { done: true }, loadEx: true, editEx: true });
  await setCacheEx(copy);
  node.nodes = append(node.nodes, copy);
  sortNode(node);
  await setCache(node);
  return copy;
}

// Helper functions to read file content
async function readFileAsText(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => { resolve(reader.result as string); };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

async function readFileAsBase64(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      // Remove data URL prefix (e.g., "data:image/png;base64,")
      const base64 = result.substring(result.indexOf(',') + 1);
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
