import { Grid10Cell, Grid10CellButton, Grid10CellIcon, Grid10CellInput, Grid10Row } from 'components';
import { AiosFileType, is } from 'aios';
import { useState } from 'react';
import { type AppDataType, type AppDataProps } from '../AppData/AppDataType';
import { getData, getActionControls } from 'app';

export const AppActionFootInput: AppDataType = ({ node }: AppDataProps) => {
  const [input, setInput] = useState('');
  const [more, setMore] = useState('expand');

  if (!is(node)) {
    return (<div>AppChatFootInput no node</div>);
  }

  const { assetFileType } = getData(node);
  const controls = getActionControls(node);

  const onMore = async (): Promise<void> => {
    setMore(more === 'expand' ? 'collapse' : 'expand');
  }

  const handleChat = async (): Promise<void> => {
    if (is(controls.doChat)) {
      await controls.doChat(input);
      setInput('');
    }
  };

  const handleCode = async (): Promise<void> => {
    if (is(controls.doCode)) {
      await controls.doCode(input);
      setInput('');
    }
  };

  const renderIcon = (): JSX.Element => {
    if (is(controls.working)) {
      return (
        <Grid10CellIcon icon='aios' spin />
      );
    }
    return (
      <Grid10CellIcon icon='aios' />
    );
  };

  const renderInput = (): JSX.Element => {
    return (
      <Grid10CellInput
        spanx={8}
        value={input}
        spany={2}
        onChange={value => {
          setInput(value);
          if (is(controls.onInput)) {
            controls.onInput(value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (is(controls.onHistoryUp)) {
              const newInput = controls.onHistoryUp();
              if (is(newInput)) {
                setInput(newInput);
              } else {
                setInput('');
              }
            }
          } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (is(controls.onHistoryDown)) {
              const newInput = controls.onHistoryDown();
              if (is(newInput)) {
                setInput(newInput);
              } else {
                setInput('');
              }
            }
          } else if (e.key === 'Enter' && !e.shiftKey && is(controls.doChat)) {
            e.preventDefault();
            void handleChat();
          }
        }}
        disabled={controls.working}
        autoComplete='off'
      />
    );
  };

  const renderChatButton = (): JSX.Element => {
    if (is(controls.doChat)) {
      return (<Grid10CellButton icon='send' onClick={handleChat} disabled={controls.working} />);
    }
    if (is(controls.doChatCancel)) {
      return (<Grid10CellButton icon='stop' onClick={controls.doChatCancel} />);
    }
    return (<></>);
  };

  const renderMoreButton = (): JSX.Element => {
    return (
      <Grid10CellButton icon={more} onClick={onMore} disabled={controls.working} />
    );
  };

  const renderCodeButton = (): JSX.Element => {
    if (assetFileType !== AiosFileType.Code) {
      return (
        <Grid10Cell>&nbsp;</Grid10Cell>
      );
    }
    const onClick = is(controls.doCodeCancel) ? controls.doCodeCancel : handleCode;
    const icon = is(controls.doCodeCancel) ? 'stop' : 'run';
    return (
      <Grid10CellButton icon={icon} onClick={onClick} disabled={controls.working} />
    );
  };

  return (
    <>
      <Grid10Row>
        {renderIcon()}
        {renderInput()}
        {renderChatButton()}
      </Grid10Row>
      <Grid10Row>
        {renderMoreButton()}
        {renderCodeButton()}
      </Grid10Row>
    </>
  );
};