export const AiosPage = `
# prodev.ai

prodev.ai is a front end for aios, which provides secure, controlled access to digital assets and other features. The system supports multiple file types, versioning, and fine-grained access control. The system also has a built in Deno runtime.

## aios path structure

Assets in aios follow this path structure:
https://[host]/[account]@[version]/[assetPath]?mode=[mode]&modeId=[modeId]&action=[action]

Where:
- host: The server domain (prodev.ai)
- account: Account identifier
- version: Optional version tag
- assetPath: Path to the asset within the account
- mode: Additional mode type (access, action)
- modeId: Mode-specific identifier
- action: Optional action to perform

### Account
Accounts are the top-level containers for assets. Each account can contain multiple assets and provides user authentication and management features.

### Assets
Assets are the core resources, they can be files of any type including folders, markdown documents, code, images and more. Assets support versioning and access control.

### Version and Access Control
The system implements version and access controls.

### Alias and Forward
The system supports aliases for accounts. It also provides forwarding capabilities to redirect access to specific assets or versions.

### Code
The system provides code editing, execution and debugging features in a secure Deno runtime environment.
`;