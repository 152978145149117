import { is } from 'aios';
import { type AiosNode } from '../../node';
import { getStatus } from '../../status';
import { type AiosNodeUi } from './AiosNodeUi';
import { AuthFormAws } from 'components';
import { AiosForm } from 'components/Auth/AiosForm';

export function getUiAios(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const { auth, authOut, authOutConfirm } = getStatus(node);
    ui.icon = 'aios';
    ui.path = 'system';
    if (!is(auth?.done) && !is(auth?.do)) {
        ui.path = 'system';
        ui.form = AiosForm;
        ui.body = true;
        return ui;
    }
    if (is(auth?.do) || is(auth?.doing)) {
        ui.spin = true;
        ui.path = 'system';
        ui.note = '[signing]';
        ui.form = AuthFormAws;
        return ui;
    }
    if (is(authOutConfirm?.do) && !is(authOutConfirm?.done)) {
        ui.spin = true;
        ui.path = 'sign out (cache will be cleared) ?';
        return ui;
    }
    if (is(authOut?.doing)) {
        ui.spin = true;
        ui.path = 'signing out...';
        return ui;
    }
    ui.path = 'system';
    ui.form = AiosForm;
    ui.body = true;
    return ui;
}
