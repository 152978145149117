import { createAiosPath, is } from 'aios';
import { cancelApi, findAiosNode, setApi, setCache, setStatus } from 'app';
import { AppState } from 'signals/AppState/AppState';

export async function doSignIn(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return;
  }
  setApi(aios);
  setStatus(aios, {
    auth: { id: undefined, do: true, doing: false, done: false },
    authenticate: { do: false, doing: false, done: false },
    authorize: { do: false, doing: false, done: false }
  });
  await setCache(aios);
  AppState.current.refresh();
}

export async function doSignInCancel(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios)) {
    return;
  }
  cancelApi(aios);
  setStatus(aios, {
    auth: { do: false, doing: false, done: false },
    authenticate: { do: false, doing: false, done: false },
    authorize: { do: false, doing: false, done: false }
  });
  await setCache(aios);
  AppState.current.refresh();
}

export async function doSignInReload(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios) || !is(aios.account)) {
    return;
  }
  setStatus(aios, { load: { do: undefined } });
  await setCache(aios);
  AppState.current.refresh();
}

export async function doSignOut(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios) || !is(aios.account)) {
    return;
  }
  setStatus(aios, { authOut: { do: true, done: false }, authOutConfirm: { do: true, done: false } });
  await setCache(aios);
  AppState.current.refresh();
}

export async function doSignOutCancel(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios) || !is(aios.account)) {
    return;
  }
  setStatus(aios, {
    authOut: { do: false },
    authOutConfirm: { do: false }
  });
  await setCache(aios);
  AppState.current.refresh();
}

export async function doSignOutConfirm(): Promise<void> {
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (!is(aios) || !is(aios.account)) {
    return;
  }
  setStatus(aios, { authOutConfirm: { done: true } });
  await setCache(aios);
  AppState.current.refresh();
}
