import { type WidgetProps } from '@rjsf/utils';
import { anon } from 'aios';
import { Button, TextField, Box } from '@mui/material';

export const widgets = {
  ActorIdWidget: (props: WidgetProps): JSX.Element => {
    const { value, onChange } = props;
    
    const handleMakePublic = (): void => {
      onChange(anon);
    };
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          fullWidth
          value={value ?? ''}
          onChange={(e): void => {
            onChange(e.target.value);
          }}
          placeholder="Public or Account Id"
        />
        <Button 
          variant="outlined" 
          onClick={handleMakePublic}
        >
          Make Public
        </Button>
      </Box>
    );
  }
};

export default widgets;