import { isWait, isFail, AiosStatus, AiosType, set } from 'aios';
import { type AiosAction } from 'aios/AiosAction';

export interface AiosNodeActionUi {
    mode: 'json' | 'code';
    more: 'more' | 'less';
    expand: 'expand' | 'collapse'; 
    disabled: boolean;
    isProcessing: boolean;
    theme?: string;
    inputText?: string;
    outputText?: string;
    textOverflow?: boolean;
    onMode?: (mode: string) => Promise<void>;
    onMore?: () => Promise<void>;
    onExpand?: () => Promise<void>;
}

export function formatStatus(status: AiosStatus | undefined): string {
    return status ?? AiosStatus.None;
}

export function getActionUi(action: AiosAction): AiosNodeActionUi {
    const ui: AiosNodeActionUi = {
        mode: 'code',
        more: 'more',
        expand: 'expand',
        disabled: isWait(action.status),
        isProcessing: action.status === AiosStatus.Processing,
        theme: undefined,
        textOverflow: true,

        onMode: async (mode: string) => {
            if (mode === 'json' || mode === 'code') {
                ui.mode = mode;
                ui.expand = mode === 'code' ? 'expand' : 'collapse';
                ui.textOverflow = mode === 'json';
            }
        },

        onMore: async () => {
            ui.more = ui.more === 'more' ? 'less' : 'more';
            if (ui.more === 'more') {
                ui.expand = 'expand';
                ui.mode = 'code';
            }
        },

        onExpand: async () => {
            ui.expand = ui.expand === 'expand' ? 'collapse' : 'expand';
            ui.textOverflow = ui.expand === 'collapse';
        }
    };

    // Handle input text based on action state
    if (action.type === AiosType.ActionChat || action.type === AiosType.ActionCode) {
        ui.inputText = set(action.inputText, '~');
    }

    // Handle output text based on action state
    if (action.output !== null && action.output !== undefined) {
        const output = action.output;
        if (isWait(output.status)) {
            ui.outputText = '...';
            ui.textOverflow = true;
        } else if (isFail(output.status)) {
            const text = output.text ?? '';
            ui.outputText = `${text} ... ${formatStatus(output.status)}`;
            ui.textOverflow = true;
        } else {
            const text = set(output.text, set(output.item?.text, ''));
            if (output.type === AiosType.ActionChat || output.type === AiosType.ActionCode) {
                ui.outputText = `${text} ... ${formatStatus(output.status)}`;
            } else {
                ui.outputText = text;
            }
        }
    }

    // Handle collapsed state
    if (ui.expand === 'collapse') {
        ui.textOverflow = false;
    }

    // Handle more state
    if (ui.more === 'more') {
        ui.mode = 'code';
        ui.expand = 'expand';
    }

    return ui;
}
