import { setApi, setCacheEx, type AiosNode } from 'app';
import { AppState } from 'signals/AppState/AppState';
import { startState, setStatusEx } from 'app/status';

export async function doSave(node: AiosNode): Promise<void> {
  setStatusEx(node, {
    save: { do: true, doing: undefined, done: undefined },
    saveData: startState,
    saveNext: startState,
    saveMore: startState,
    edit: false,
    working: true
  });
  setApi(node);
  await setCacheEx(node);
  AppState.current.refresh();
}

export async function doSaveOk(node: AiosNode): Promise<void> {
  setStatusEx(node, {
    save: { do: undefined, done: undefined, doing: undefined },
    edit: false,
    editEx: false,
    create: false,
    createEx: false,
    working: false
  });
  await setCacheEx(node);
  AppState.current.refresh();
}
