import { type AiosPath } from './AiosPath';
import { is } from '..';

export function getNextPath(path: AiosPath, target: AiosPath): string {
  const nodePath = getNextNode(path, target);
  if (!is(target.mode) && !is(target.modeId) && !is(target.action)) {
    return nodePath;
  }
  if (path.path !== nodePath) {
    return nodePath;
  }
  let append = '';
  if (is(target.mode)) {
    append = `mode=${target.mode}`;
  }
  if (is(target.modeId)) {
    if (!is(append)) {
      append = `modeId=${target.modeId}`;
    } else {
      append = `${append}&modeId=${target.modeId}`;
    }
    if (!is(path.modeId)) {
      return `${nodePath}?${append}`;
    }
  }
  if (is(target.action)) {
    if (!is(append)) {
      append = `action=${target.action}`;
    } else {
      append = `${append}&action=${target.action}`;
    }
  }
  return `${nodePath}?${append}`;
}

function getNextNode(path: AiosPath, target: AiosPath): string {
  if (!is(path.full) || !is(target.assetPath)) {
    if (path.account === target.account) {
      if (!is(path.version) && is(target.version)) {
        return `${target.account as string}@${target.version}`;
      }
    }
    return target.account as string;
  }
  if (!is(target.version) || path.assetPath !== target.assetPath) {
    let assetPath = target.assetPath;
    if (is(path.assetPath)) {
      assetPath = assetPath.substring(path.assetPath.length);
    }
    if (assetPath.includes('/')) {
      const parts = assetPath.split('/');
      while (parts.length > 0) {
        const part = parts.shift();
        if (is(part)) {
          assetPath = part;
          break;
        }
      }
    }
    if (!is(assetPath)) {
      if (is(path.assetPath)) {
        return `${target.account as string}/${path.assetPath}`;
      }
      return `${target.account as string}`;
    }
    if (is(path.assetPath)) {
      return `${target.account as string}/${path.assetPath}/${assetPath}`;
    }
    return `${target.account as string}/${assetPath}`;
  }
  return `${target.account as string}@${target.version}/${target.assetPath}`;
}

export default getNextPath;
