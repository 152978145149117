import { append, is, remove } from 'aios';
import { type AiosNode } from '../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { generateNewNodeName } from './createNode';
import { remCache, setCache } from '../../cache';
import { setStatus } from '../../status';
import { newUi } from '../../ui';
import { createClone, createCopy } from '../copy';
import { sortNode } from 'app/util';

export async function doUpdate(node: AiosNode): Promise<void> {  
  const newNode = createClone(node);
  const name = generateNewNodeName(node, 'edit');
  newNode.parent = node;
  newNode.link = `${node.link as string}/${name}`;
  newUi(newNode);
  createCopy(newNode);
  setStatus(newNode, { load: { done: true }, loadData: { done: true }, edit: true });
  await setCache(newNode);
  node.nodes = append(node.nodes, newNode);
  sortNode(node);
  await setCache(node);  
  AppState.current.go(newNode.link);
}

export async function doUpdateCancel(node: AiosNode): Promise<void> {
  await remCache(node);
  const parent = node.parent;
  if (is(parent)) {
    parent.nodes = remove(parent.nodes, node);
    await setCache(parent);
    AppState.current.goTo(parent.path);
  }
}
