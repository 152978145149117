import { useApp, useRoute } from 'signals';
import { is } from 'aios';
import { useState } from 'react';
import { AppDataFoot } from './AppDataFoot';
import { doUploadEx, findAiosNode } from 'app';
import { AppDataNode } from './AppDataNode';

export const AppData = (): JSX.Element => {
  const { root } = useApp();
  const { path } = useRoute();
  const [isDragging, setIsDragging] = useState(false);
  const node = findAiosNode(root, path);
  function handleDragOver(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(true);
  }
  function handleDragLeave(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
  }
  function handleDrop(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
    if (is(node) && e.dataTransfer.files.length > 0) {
      void doUploadEx(node, e.dataTransfer.files);
    }
  };
  if (!is(node)) {
    return (<div>AppData no node</div>);
  }
  return (
    <div
      className={`app-data${!isDragging ? '' : ' drag'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className='app-data-container'>
        <AppDataNode node={node} />
      </div>
      <AppDataFoot />
    </div>
  );
};