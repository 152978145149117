import {
  type AiosObject,
  type AiosOutput,
  AiosStatus,
  AiosType,
  createAiosObject,
  createAiosOutput,
} from '../AiosType';
import type { AiosData } from './AiosData';
import { is, set } from '../functions';

export interface AiosDataOutput<T extends AiosData> extends AiosOutput {
  type?: AiosType;
  item?: T;
  items?: T[];

  fields?: Record<string, unknown>;
  outputs?: Array<Partial<AiosDataOutput<AiosData>>>;

  setOk: (options?: Partial<AiosDataOutput<T>>) => AiosDataOutput<T>;
  setFail: (options?: Partial<AiosDataOutput<T>>) => AiosDataOutput<T>;
  override: (result: AiosDataOutput<T>) => AiosDataOutput<T>;
  format: () => AiosObject;
}

export function createAiosDataOutput<T extends AiosData>(
  options?: Partial<AiosDataOutput<T>>,
): AiosDataOutput<T> {
  const base = createAiosOutput(options) as AiosDataOutput<T>;
  const result = {
    ...base,
    type: set(options?.type, AiosType.None),
    item: set(options?.item, undefined),
    items: set(options?.items, undefined),
    fields: set(options?.fields, undefined),
    outputs: set(options?.outputs, base.outputs),
    setOk: set(
      options?.setOk,
      function (
        this: AiosDataOutput<T>,
        values?: Partial<AiosDataOutput<T>>,
      ): AiosDataOutput<T> {
        base.setOk.call(this, values);
        this.type = set(values?.type, this.type);
        this.item = set(values?.item, this.item);
        this.items = set(values?.items, this.items);
        return this;
      },
    ),
    setFail: set(
      options?.setFail,
      function (
        this: AiosDataOutput<T>,
        values?: Partial<AiosDataOutput<T>>,
      ): AiosDataOutput<T> {
        base.setFail.call(this, values);
        if (this.status !== AiosStatus.FailAccess) {
          this.type = set(values?.type, this.type);
          this.item = set(values?.item, this.item);
          this.items = set(values?.items, this.items);
        } else {
          this.type = undefined;
          this.item = undefined;
          this.items = undefined;
        }
        this.fields = set(values?.fields, result.fields);
        return this;
      },
    ),
    override: set(
      options?.override,
      function (
        this: AiosDataOutput<T>,
        values: AiosDataOutput<T>,
      ): AiosDataOutput<T> {
        base.override.call(this, values);
        if (this.status !== AiosStatus.FailAccess) {
          this.type = set(values?.type, this.type);
          this.item = set(values?.item, this.item);
          this.items = set(values?.items, this.items);
        } else {
          this.type = undefined;
          this.item = undefined;
          this.items = undefined;
        }
        this.fields = set(values.fields, result.fields);
        return this;
      },
    ),
    format: set(options?.format, function (this: AiosDataOutput<T>) {
      const format = base.format.call(this);
      if (is(this.item)) {
        format.item = JSON.stringify(createAiosObject(this.item));
      }
      if (is(this.items)) {
        const items: string[] = [];
        for (const item of items) {
          const child = createAiosObject(item) as AiosObject;
          items.push(JSON.stringify(child));
        }
        format.items = items;
      }
      return format;
    }),
  };
  return result;
}

export function formatAiosDataOutput<T extends AiosData>(
  output: Partial<AiosDataOutput<T>>,
  root?: boolean,
): Partial<AiosDataOutput<T>> {
  if (root === undefined) {
    root = true;
  }
  let formattedOutputs: Array<Partial<AiosDataOutput<AiosData>>> | undefined;
  let childCosts = 0;
  let childSizes = 0;
  let childTimes = 0;
  if (is(output.outputs)) {
    formattedOutputs = [];
    for (const nestedOutput of output.outputs) {
      const formattedChild = formatAiosDataOutput(nestedOutput, false);
      childCosts += set(formattedChild.cost, 0);
      childSizes += set(formattedChild.size, 0);
      childTimes += set(formattedChild.time, 0);
      formattedOutputs.push(formattedChild);
    }
  }

  const totalCost = set(output.cost, 0) + childCosts;
  const totalSize = set(output.size, 0) + childSizes;
  const totalTime = set(output.time, 0) + childTimes;

  return {
    text: output.text,
    type: output.type,
    item: root ? output.item : undefined,
    status: output.status,
    cost: totalCost,
    size: totalSize,
    time: totalTime,
    outputs: formattedOutputs,
  };
}
