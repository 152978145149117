import { type AiosPath, AiosStatus, AiosType, append, createAiosPath, getNextPath, is } from 'aios';
import { type AiosNode, createAiosNode } from '../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { getStatus, setStatus } from '../../status';
import { adminNode } from '../aios';
import { chatNode } from '../chat';
import { codeNode } from '../code';
import { removeNode } from '../remove';
import { saveNode } from '../save';
import { loadNode } from './loadNode';
import { getCache, setCacheEx } from '../../cache';
import { findAiosNode, sortNode } from '../../util';
import { authNode, authNodeOut } from '../aios/authNode';
import { Hub, type HubCapsule } from '@aws-amplify/core';

let loading: string | undefined;
let loaded: string | undefined;

export async function loadPage(node: AiosNode, path: AiosPath): Promise<void> {
  if (loading === path.full) {
    return;
  }
  loading = path.full;
  const authOk = await authNode();
  if (!is(authOk)) {
    loading = undefined;
    if (authOk === undefined) {
      AppState.current.refresh();
    }
    return;
  }
  const load = await loadLink(node, path.full);
  if (!is(load)) {
    loading = undefined;
    return;
  }
  const status = load.api?.action?.output?.status;
  if (status === AiosStatus.FailAuthExpired) {
    setStatus(load, {
      authOut: { done: false, doing: true },
      authOutConfirm: { done: true }
    });
    await authNodeOut(load);
  }
  if (loaded !== loading) {
    loaded = loading;
    loading = undefined;
    AppState.current.refresh();
    return;
  }
  const { working, refresh } = getStatus(load);
  if (!is(working) && !is(refresh)) {
    loading = undefined;
    return;
  }
  loading = undefined;
  setStatus(load, { refresh: undefined });
  await setCacheEx(load);
  AppState.current.refresh();
}

async function loadLink(node: AiosNode, link?: string): Promise<AiosNode | undefined> {
  if (is(link) && node.link !== link) {
    const target = createAiosPath({ full: link });
    const nextLink = getNextPathEx(node.path, target);
    let nextNode;
    const { nodes } = node;
    if (is(nodes)) {
      nextNode = nodes.find(child => child.link === nextLink);
    }
    if (!is(nextNode)) {
      const nextPath = createAiosPath({ full: nextLink });
      nextNode = createAiosNode({
        parent: node,
        link: nextLink,
        path: nextPath,
        type: nextPath.aiosType,
      });
      node.nodes = append(node.nodes, nextNode);
      if (node.type !== AiosType.AiosNodeRoot) {
        sortNode(node);
      }
    }
    if (!is(nextNode.status.load?.done)) {
      await getCache(nextNode);
    }
    const next = await loadLink(nextNode, link);
    return next;
  }
  if (node.type === AiosType.AiosNodeRoot) {
    return node;
  }
  // if (!is(node.status.refresh)) {
  //   await setIndexUi(node, { openIndex: true });
  // } else {
  //   setStatus(node, { refresh: false });
  //   await setCache(node);
  // }
  if (node.type === AiosType.AiosNodeAios) {
    await adminNode(node);
    return node;
  }
  if (is(node.link) && node.link.startsWith('aios')) {
    return node;
  }
  const { load, save, remove, chat, code } = getStatus(node);
  if (!is(load?.done)) {
    if (load?.do !== false) {
      setStatus(node, { load: { do: true } });
    }
    await loadNode(node);
    return node;
  }
  if (is(save?.do)) {
    await saveNode(node);
    return node;
  }
  if (is(remove?.do)) {
    await removeNode(node);
    return node;
  }
  if (is(chat?.do)) {
    await chatNode(node);
    return node;
  }
  if (is(code?.do)) {
    await codeNode(node);
    return node;
  }
  await loadNode(node);
  sortNode(node);
  return node;
}

export function getNextPathEx(path: AiosPath, target: AiosPath): string {
  const pathPath = path.path;
  const targetPath = target.full;

  if (!is(targetPath) || !is(pathPath)) {
    return getNextPath(path, target);
  }

  // If paths match exactly, return target path
  if (pathPath === targetPath) {
    return targetPath;
  }

  const segments = targetPath.split('/');
  const lastSegment = segments[segments.length - 1];

  // Check if this is a special new/edit path
  if (/^(?:new|edit)\d+$/.test(lastSegment)) {
    // Get the base path without the special suffix
    const basePath = segments.slice(0, -1).join('/');

    // If we're at the correct level, include the suffix
    if (pathPath === basePath) {
      return targetPath;
    }

    // Otherwise, resolve to the base path
    const tempTarget = createAiosPath({ full: basePath });
    return getNextPath(path, tempTarget);
  }

  return getNextPath(path, target);
}

Hub.listen('auth', (data: HubCapsule) => {
  if (data.payload.event === 'signIn') {
    const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
    if (!is(aios)) {
      return;
    }
    setStatus(aios, { authenticate: { done: true } });
    AppState.current.refresh();
  }
});
