import {
  type AiosAction,
  type AiosException,
  AiosStatus,
  createAiosAction,
  AiosDataCommand,
  AiosType,
  createAiosAccount,
  createAiosAsset,
  createAiosDataInput,
  createAiosDataOutput,
  createAiosAccess,
  is,
  isId,
  createAiosPath,
} from 'aios';
import { type AiosNode } from '../node';
import { createAiosClientApp } from 'utl';
import { getData } from '../data';

export async function processApi(node: AiosNode, command: AiosDataCommand): Promise<AiosAction> {
  let text = '';
  let path = node.path;
  const { type, item } = getData(node);
  const input = createAiosDataInput({ type, command });
  switch (command) {
    case AiosDataCommand.Load:
      text = 'loading';
      break;
    case AiosDataCommand.Save:
      text = 'saving';
      break;
    case AiosDataCommand.Remove:
      text = 'removing';
      break;
  }
  switch (type) {
    case AiosType.Account:
      text = `${text} account`;
      input.item = createAiosAccount({ ...item, assets: undefined });
      if (is(path.account) && !isId(path.account)) {
        let full = path.full;
        if (is(input.item.id)) {
          full = full?.replace(path.account, input.item.id);
        }
        path = createAiosPath({ full });
      }
      break;
    case AiosType.Asset:
      text = `${text} asset`;
      input.item = createAiosAsset({ ...item, assets: undefined });
      break;
    case AiosType.Access:
      text = `${text} access`;
      input.item = createAiosAccess({ ...item });
      break;
  }
  const output = createAiosDataOutput({ text, status: AiosStatus.Processing });
  const action = createAiosAction({ input, output });
  try {
    const client = await createAiosClientApp();
    if (!is(node.status.async?.do)){
      node.api.client = client;
    } else {
      node.api.clientEx = client;
    }    
    const apiOutput = await client.send({ path, input });
    output.override(apiOutput);
    output.text = text;
  } catch (exception) {
    output.setFail({ text, exception: exception as AiosException });
  } finally {
    node.api.client = undefined;
    node.api.clientEx = undefined;
  }
  return action;
}

export function cancelApi(node: AiosNode): void {
  const { client, clientEx, action } = node.api;
  if (is(client)) {
    client.cancel();
    node.api.client = undefined;
  }
  if (is(clientEx)) {
    clientEx.cancel();
    node.api.clientEx = undefined;
  }
  if (!is(action)) {
    return;
  }
  const { output } = action;
  if (is(output)) {
    output.status = AiosStatus.FailCancel;
    output.text = 'cancelled';
  }
}