import { useApp, useRoute } from 'signals';
import { Foot, Grid, GridCellButton } from 'components';
import { createAiosPath, is, set } from 'aios';
import { findAiosNode, getControls, owner } from 'app';

export const AppDataFoot = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (<div>AppFoot no path</div>);
  }
  const node = findAiosNode(root, path);

  if (!is(node)) {
    return (<div>AppFoot no node</div>);
  }
  let displayNode = node;
  if (is(displayNode.forwardNode) && !owner(displayNode)) {
    displayNode = displayNode.forwardNode;
    displayNode.status = node.status;
    displayNode.ui = node.ui;
  }
  const buttons: JSX.Element[] = [];
  const commands = getControls(displayNode);
  if (is(commands)) {
    for (let i = 0; i < commands.length; i++) {
      const command = commands[i];
      const icon = set(command.icon, command.name);
      const path = !is(command.path) ? undefined : createAiosPath({ path: command.path })
      buttons.push(
        <GridCellButton
          key={`app-foot-button-${command.name as string}`}
          name={command.name}
          icon={icon}
          path={path}
          onClick={async () => {
            if (is(command.doChange)) {
              await command.doChange(node);
            }
            if (is(command.path)) {
              const goToPath = createAiosPath({ full: command.path });
              goTo(goToPath);
            }
          }}
          onUpload={command.doUpload}
          downloadUrl={command.downloadUrl}
          downloadName={command.downloadName}
          options={{
            color: command.color,
            blink: command.blink,
            disabled: command.disabled,
            selected: command.selected,
            xMid: true,
            border: true,
            margin: true
          }}
          subOptions={{ yMid: true, xMid: true }}
        />
      );
    }
  }
  const renderButtons = (): JSX.Element => {
    const cancel = commands?.find(c => c?.name === 'cancel');
    if (!is(cancel) || buttons.length > 1) {
      return (
        <Grid cols={buttons.length} rows={1} width={100} height={10} center>
          {buttons}
        </Grid>)
    }
    return (
      <Grid cols={1} rows={1} width={50} height={10} center>
        <GridCellButton
          name='cancel'
          icon='cancel'
          path={createAiosPath({ path: cancel?.path })}
          onClick={async () => {
            if (is(cancel?.doChange)) {
              await cancel?.doChange(node);
            }
            if (is(cancel?.path)) {
              const goToPath = createAiosPath({ full: cancel?.path });
              goTo(goToPath);
            }
          }}
          options={{
            color: 'red',
            blink: true,
            xMid: true,
            border: true,
            margin: true
          }}
          subOptions={{ yMid: true, xMid: true }}
        />
      </Grid>);
  }
  return (
    <Foot>
      {renderButtons()}
    </Foot>
  );
};