import { useEffect } from 'react';
import { useApp, useRoute } from 'signals';
import { createAiosPath, is } from 'aios';
import { AppState } from 'signals/AppState/AppState';
import { Page } from 'components';
import { AppHead } from './AppHead';
import { AppBody } from './AppBody';
import { findAiosNode, loadPage } from 'app';
import './AppPage.css';

export const AppPage = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo, go } = useRoute();
    let account;
    let accountId;
    const aios = findAiosNode(root, createAiosPath({ path: 'aios' }));
    if (is(aios)) {
      account = aios.account;
      if (is(account)) {
        accountId = account.id;
      }
    }
  AppState.current.full = path.full;
  AppState.current.goTo = goTo;
  AppState.current.go = go;
  useEffect(() => {
    if (!is(path) || !is(path.path) || !is(path.full)) {
      goTo(createAiosPath({ path: 'aios' }));
      return;
    }
    if (path.path === '' || path.path === '/') {
      goTo(createAiosPath({ path: 'aios' }));
      return;
    }
    const load = async (): Promise<void> => {
      await loadPage(root, path);
    }
    void load();
  }, [path, goTo, account, accountId]);
  return (
    <Page>
      <AppHead />
      <AppBody />
    </Page>
  );
};
