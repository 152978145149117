import {
  AiosStatus,
  AiosDataCommand,
  createAiosDataOutput,
  is,
  createAiosData,
  createAiosAction,
  createAiosDataInput,
  type AiosAccount,
  type AiosAsset,
  createAiosAccount,
  createAiosAsset,
  createAiosAccess,
  createAiosPath,
  append,
  AiosType,
  set,
  type AiosAccess,
  isFail,
  isId,
} from 'aios';
import { type AiosNode, findAiosNode, getStatus, setApi, setStatus, sortNode } from 'app';
import { createAiosClientApp } from 'utl';
import { type AiosNodeItem, createAiosNode } from 'app/node';
import { getCache, setCache } from 'app/cache';

export async function loadNodeData(node: AiosNode): Promise<boolean | undefined> {
  const { loadData } = getStatus(node);
  if (!is(loadData?.do) || is(loadData?.done)) {
    return loadData?.done;
  }
  if (is(loadData?.doing)) {
    return undefined;
  }
  setStatus(node, { loadData: { doing: true } });
  setApi(node, { id: loadData?.id, output: { text: 'loading data', status: AiosStatus.Processing } });
  try {
    const apiInput = createAiosDataInput({
      type: set(node.type, node.path.aiosType),
      item: set(node.item, createAiosData({ path: node.path.full })),
      command: AiosDataCommand.Load,
    });
    let apiOutput;
    if (!is(loadData?.done)) {
      node.api.client = await createAiosClientApp();
      apiOutput = await node.api.client.send({ path: node.path, input: apiInput });
      node.api.client = undefined;
    } else {
      apiOutput = createAiosDataOutput({
        text: 'loaded from cache',
        item: node.item,
        status: AiosStatus.OkCache,
      });
    }
    node.api.action = createAiosAction({
      id: loadData?.id,
      input: apiInput,
      output: apiOutput
    });
    setApi(node, {
      id: loadData?.id,
      output: { ...node.api?.action?.output },
    });
    if (isFail(apiOutput?.status)) {
      setStatus(node, { loadData: { do: false, doing: false, done: false } });
      return false;
    }
    let item = apiOutput.item;
    let assets, versions, accesses;
    switch (node.type) {
      case AiosType.Account:
        {
          const account = item as AiosAccount;
          item = createAiosAccount(account);
          assets = (item as AiosAccount).assets;
        }
        break;
      case AiosType.Asset:
        item = createAiosAsset(item);
        assets = (item as AiosAsset).assets;
        versions = (item as AiosAsset).versions;
        accesses = (item as AiosAsset).accesses;
        break;
      case AiosType.Access:
        item = createAiosAccess(item);
        break;
    }
    node.item = item;
    setStatus(node, { loadData: { do: false, doing: false, done: true }, loadEx: node.parent?.status?.loadEx });
    let alias: string | undefined;
    if (is(node.path.account) && !isId(node.path.account)) {
      alias = node.path.account;
    }
    aliasNode(node, alias);
    await createChildNodes(node, { assets, versions, accesses }, alias);
    await setCache(node);    
    await loadForward(node);
  } catch (error) {
    setStatus(node, {
      loadData: { do: false, doing: false, done: false }
    });
    return false;
  }

  return true;
}

async function createChildNodes(node: AiosNode,
  { assets, versions, accesses }:
    { assets?: AiosAsset[], versions?: AiosAsset[], accesses?: AiosAccess[] },
  alias?: string
): Promise<void> {
  if (is(assets)) {
    await createNodesType(node, assets, AiosType.Asset, alias);
  }
  
  // Handle versions by attaching them to their parent assets instead of to the main node
  if (is(versions)) {
    // First, create a map of base assets by path without version
    const assetMap = new Map<string, AiosNode>();
    if (is(node.nodes)) {
      for (const childNode of node.nodes) {
        if (childNode.type === AiosType.Asset && !is(childNode.path.version)) {
          const assetPath = childNode.path.assetPath;
          if (is(assetPath)) {
            assetMap.set(assetPath, childNode);
          }
        }
      }
    }
    
    // Now process each version and attach it to its parent asset
    for (const version of versions) {
      const versionPath = createAiosPath({ full: version.path });
      const assetPath = versionPath.assetPath;
      
      if (is(assetPath)) {
        // Find the parent asset node
        const parentAssetNode = assetMap.get(assetPath);
        
        if (is(parentAssetNode)) {
          // This is a version of an existing asset, add it as a child of the asset
          let versionNode = findAiosNode(parentAssetNode, versionPath);
          if (!is(versionNode)) {
            versionNode = createAiosNode();
            parentAssetNode.nodes = append(parentAssetNode.nodes, versionNode);
          }
          
          if (is(versionNode)) {
            versionNode.parent = parentAssetNode;
            versionNode.path = versionPath;
            versionNode.link = version.path;
            versionNode.type = AiosType.Asset;
            
            if (!await getCache(versionNode)) {
              versionNode.item = version;
              aliasNode(versionNode, alias);
            }
            
            await setCache(versionNode);
          }
          
          // Keep track of versions in the asset's versions array
          const parentAsset = parentAssetNode.item as AiosAsset;
          if (is(parentAsset)) {
            if (!is(parentAsset.versions)) {
              parentAsset.versions = [version];
            } else if (!parentAsset.versions.some(v => v.path === version.path)) {
              parentAsset.versions = append(parentAsset.versions, version);
            }
          }
        } else {
          // No matching parent asset was found, add it directly to the node
          await createNodesType(node, [version], AiosType.Asset, alias);
        }
      } else {
        // No asset path, add it directly to the node
        await createNodesType(node, [version], AiosType.Asset, alias);
      }
    }
  }
  
  if (is(accesses)) {
    await createNodesType(node, accesses, AiosType.Access, alias);
  }
  
  // Sort each node's children
  if (is(node.nodes)) {
    sortNode(node);
    for (const child of node.nodes) {
      if (is(child.nodes)) {
        sortNode(child);
      }
    }
  }
}

async function createNodesType(node: AiosNode, items: AiosAsset[] | AiosAccess[], type: AiosType, alias?: string): Promise<void> {
  for (const item of items) {
    const childPath = createAiosPath({ full: item.path });
    let childNode = findAiosNode(node, childPath);
    if (!is(childNode)) {
      childNode = createAiosNode();
      node.nodes = append(node.nodes, childNode);
      sortNode(node);
    }
    if (is(childNode)) {
      childNode.parent = node;
      childNode.path = childPath;
      childNode.link = item.path;
      childNode.type = type;
      if (!await getCache(childNode)) {
        childNode.item = item;
        aliasNode(childNode, alias);
      }
    }
  }
}

export function aliasNode(node: AiosNode, alias?: string): void {
  if (!is(alias)) {
    return;
  }
  const { path, type, item } = node;
  if (!is(path)) {
    return;
  }
  let account = path?.account;
  if (type === AiosType.Account) {
    account = item?.id;
  }
  if (!is(account)) {
    return;
  }
  let nodeFull = path.full;
  if (!is(nodeFull)) {
    return;
  }
  if (!nodeFull.includes(account)) {
    return;
  }
  nodeFull = nodeFull.replace(account, alias);
  node.path = createAiosPath({ full: nodeFull });
  node.link = nodeFull;
  if (!is(type) || !is(item)) {
    return;
  }
  aliasItem(type, item, account, alias);
}

function aliasItem(type: AiosType, item: AiosNodeItem, account: string, alias: string): void {
  if (!is(type) || !is(item)) {
    return;
  }
  const { path, parent, child } = item;
  if (is(path) && path.includes(account)) {
    item.path = path.replace(account, alias);
  }
  if (is(parent) && parent.includes(account)) {
    item.parent = parent.replace(account, alias);
  }
  if (is(child) && child.includes(account)) {
    item.child = child.replace(account, alias);
  }
  if (type === AiosType.Asset) {
    const asset = item as AiosAsset;
    const { file } = asset;
    if (is(file) && is(file.path) && file.path.includes(account)) {
      file.path = file.path.replace(account, alias);
    }
  }
}

export async function loadForward(node: AiosNode): Promise<void> {
  if (is(node.forwardNode)) {
    return;
  }
  switch (node.type) {
    case AiosType.Account:
      {
        const account = node?.item as AiosAccount;
        node.forwardLink = account.forward;
        if (is(node.forwardLink) && is(account.forwardAsset)) {
          node.forwardNode = createAiosNode({
            type: AiosType.Asset,
            link: node.forwardLink,
            item: createAiosAsset(account.forwardAsset),
          });
          setStatus(node, { load: { do: false, doing: false, done: true } });
          await setCache(node.forwardNode);
        }
      }
      break;
    case AiosType.Asset:
      {
        const asset = node?.item as AiosAsset;
        node.forwardLink = asset.forward;
        if (is(node.forwardLink) && is(asset.forwardAsset)) {
          node.forwardNode = createAiosNode({
            type: AiosType.Asset,
            link: node.forwardLink,
            item: createAiosAsset(asset.forwardAsset),
          });
          setStatus(node, { load: { do: false, doing: false, done: true } });
          await setCache(node.forwardNode);
        }
      }
  }
}
