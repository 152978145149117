import { getClass } from 'signals';
import { Grid10Cell, type Grid10CellProps } from './Grid10Cell';
import { is } from 'aios';
import { LinearProgress } from '@mui/material';

export const Grid10CellText = (props: Grid10CellProps): JSX.Element => {
  let className = 'grid10-cell-text';
  if (is(props.center)) {
    className = `${className} center`;
  } else {
    if (is(props.right)) {
      className = `${className} right`;
    }
  }
  return (
    <Grid10Cell {...props}>
      <div className={getClass(className)}>
        <span className={getClass('grid10-cell-span')} data-testid={props.test}>{props.children}</span>
      </div>
      {is(props.progress) ? (
        <LinearProgress
          sx={{
            bgcolor: '#333333 !important',
            '& .MuiLinearProgress-dashed': {
              backgroundImage: 'none !important',
              backgroundColor: '#333333 !important',
            },
            '& .MuiLinearProgress-bar1Buffer': {
              backgroundColor: '#333333 !important',
            },
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#999999 !important',
              animationDuration: '3s !important',
            }
          }}
        />
      ) : (
        <></>
      )}
    </Grid10Cell>
  );
};