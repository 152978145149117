import { AiosStatus, AiosType, append, getId, is } from 'aios';
import { type AiosNode } from 'app/node';
import { getStatus, setStatus, setStatusAll } from 'app/status';
import { removeNodeLast } from './removeNodeLast';
import { setApi } from 'app/api';
import { getPath } from 'app/util';
import { loadNodeNext } from '../load/loadNodeNext';
import { authNodeOut } from '../aios/authNode';
import { setCache } from 'app/cache';

export async function removeNode(node: AiosNode): Promise<void> {
    const { remove, removeLoad, removeConfirm, removeData } = getStatus(node);
    if (is(removeData?.doing)) {
        setStatus(node, { working: false, refresh: false });
        return;
    }
    if (remove?.do === false || is(remove?.done)) {
        setStatus(node, { working: false, refresh: false });
        return;
    }
    if (!is(remove?.doing)) {
        const id = getId();
        setStatus(node, {
            remove: { id, doing: true },
            removeLast: { id, do: true },
            removeLoad: { id, do: true, done: false },
            working: true
        });
        setApi(node, {
            id,
            output: {
                text: `removing ${getPath(node)}`,
                status: AiosStatus.Processing,
            }
        });
        return;
    }
    if (is(removeLoad?.do) && !is(removeLoad?.done)) {
        if (!is(removeLoad?.doing)) {
            setStatus(node, {
                removeLoad: { doing: true },
                loadMore: { do: true },
                loadAll: { do: true },
                load: { id: remove?.id }
            });
            setStatusAll(node, {
                loadNext: { id: remove?.id, do: true, done: false }
            });
        }
        const loaded = await loadNodeNext(node);
        setStatusAll(node, { remove: { do: true }, loadNext: { do: true } });
        if (loaded === undefined) {
            return;
        }
        if (!is(loaded)) {
            setStatus(node, {
                remove: { do: false, doing: false, done: false },
                working: false,
                refresh: true
            });
            return;
        }
        setStatus(node, {
            removeLoad: { do: false, done: true },
            removeConfirm: { do: true, done: false },
            working: false,
            refresh: true
        });
        setApi(node, {
            id: remove?.id,
            output: {
                text: `confirming ${getPath(node)}`,
                status: AiosStatus.Processing,
            }
        });
    }
    if (!is(removeConfirm?.done)) {
        return;
    }
    const removed = await removeNodeLast(node);
    if (removed === undefined) {
        setStatus(node, { working: true });
        return;
    }
    if (!is(removed)) {
        setStatus(node, {
            remove: { do: false, doing: false, done: false },
            working: false,
            refresh: true
        });
        await setCache(node);
        return;
    }
    setStatus(node, {
        remove: { do: false, done: true, doing: false },
        working: false,
        refresh: true
    });
    setApi(node, {
        id: remove?.id, output: {
            text: `deleted ${getPath(node)}`,
            status: AiosStatus.Ok
        }
    });
    const parent = node.parent;
    if (is(parent)) {
        const actions = node.api.actions;
        if (is(actions)) {
            for (const action of actions) {
                parent.api.actions = append(parent.api.actions, action);
            }
        }
    }
    await setCache(node);
    if (node.type === AiosType.Account && is(removeData?.done)) {
        setStatus(node, {
            authOut: {
                done: false,
                doing: true
            },
            authOutConfirm: {
                done: true,
            }
        }
        );
        await authNodeOut(node);
        window.location.pathname = '/';
    }
}
