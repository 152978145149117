import { type ReactNode } from 'react';
import { getIndexUi, setCache, setIndexUi, type AiosNode } from 'app';
import { is } from 'aios';
import { Grid10CellButton, Grid10CellIcon, Grid10Row } from 'components';
import { AppState } from 'signals/AppState/AppState';

export interface AppIndexItemRowsProps {
  node: AiosNode;
  depth?: number;
}

export const AppIndexItem = ({ node, depth = 0 }: AppIndexItemRowsProps): JSX.Element => {
  const {
    show,
    icon,
    spin,
    test,
    link,
    label,
    indent,
    selected,
    loadingNodes,
    openIndex,
    showIndex,
    showNodes,
  } = getIndexUi(node, depth);
  const toggleMore = async (): Promise<void> => {
    if (!is(openIndex)) {
      await setIndexUi(node, { openIndex: true });
    } else {
      await setIndexUi(node, { openIndex: false });
    }
    await setCache(node);
    if (is(openIndex)) {
      const path = AppState.current.full as string;
      if (is(node.link) && node.link !== path) {
        AppState.current.go(node.link);
        return;
      }
    }
    AppState.current.refresh();
  }
  const AppIndexItemNode = (): JSX.Element => {
    if (!is(show)) {
      return <></>;
    }
    return (
      <Grid10Row>
        <Grid10CellIcon icon={icon} spin={spin} />
        {!is(showIndex) ? (
          !is(loadingNodes) ? (
            <Grid10CellButton spanx={9} test={test} name={label} link={link} indent={indent} selected={selected} />
          ) : (
            <>
              <Grid10CellButton spanx={8} test={test} name={label} link={link} indent={indent} selected={selected} />
              <Grid10CellIcon icon='aios' spin />
            </>
          )
        ) : (
          <>
            <Grid10CellButton spanx={8} test={test} name={label} link={link} indent={indent} selected={selected} />
            {is(loadingNodes) ? (
              <Grid10CellIcon icon='aios' spin />
            ) : (
              <Grid10CellButton
                tip={!is(openIndex) ? 'show' : 'hide'}
                icon={!is(openIndex) ? 'more' : 'less'}
                onClick={toggleMore}
              />
            )}
          </>
        )}
      </Grid10Row>
    );
  };
  const AppIndexItemNodes = (): JSX.Element => {
    if (!is(showNodes)) {
      return <></>;
    }
    const childItems: ReactNode[] = [];
    const childDpth = depth + 1;
    for (let i = 0; i < showNodes.length; i++) {
      const child = showNodes[i];
      childItems.push(
        <AppIndexItem
          key={`node-index-${depth}-${i}`}
          node={child}
          depth={childDpth}
        />
      );
    }
    return <>{childItems}</>;
  };
  return (
    <>
      <AppIndexItemNode />
      <AppIndexItemNodes />
    </>
  );
}

