import { AiosType, anon, is, AiosAccessType, type AiosAccess } from 'aios';
import { type RJSFSchema } from '@rjsf/utils';
import { type AiosNode, getStatus } from 'app';

export function getUiSchema(node: AiosNode): RJSFSchema {
    if (node.type === AiosType.Account) {
        const accountSchema = {
            type: 'object',
            // required: ['path'],
            properties: {
                path: {
                    type: 'string',
                    title: 'alias',
                },
                // alias: {
                //     type: 'string',
                //     title: 'alias',
                // },                
                forward: {
                    type: 'string',
                    title: 'forward',
                },
                // email: {
                //     type: 'string',
                //     title: 'email',
                // },
                // setting: {
                //     type: 'object',
                //     title: 'setting',
                //     properties: {
                //         theme: {
                //             type: 'string',
                //             title: 'theme',
                //             enum: [AiosAccountSettingTheme.Dark, AiosAccountSettingTheme.Light],
                //         },
                //     },
                // },
            },
        };
        return accountSchema as RJSFSchema;
    }

    const { create, createEx } = getStatus(node);

    if (node.type === AiosType.Asset) {
        if (!is(node.path.version)) {
            if (is(create) || is(createEx)) {
                const createAssetSchema = {
                    type: 'object',
                    required: ['file'],
                    properties: {
                        file: {
                            type: 'object',
                            title: '',
                            required: ['type'],
                            properties: {
                                type: {
                                    title: 'type',
                                    type: 'string',
                                    enum: [
                                        'Markdown',
                                        'Folder',
                                        'Image',
                                        'Code',
                                        'Text',
                                    ],
                                },
                            },
                        },
                        name: {
                            type: 'string',
                            title: 'name',
                        },
                        version: {
                            type: 'string',
                            title: 'version',
                        },
                        forward: {
                            type: 'string',
                            title: 'forward',
                        },
                    },
                };
                return createAssetSchema as RJSFSchema;
            }
            const assetSchema = {
                type: 'object',
                required: [],
                properties: {
                    forward: {
                        type: 'string',
                        title: 'forward',
                    },
                },
            };
            return assetSchema as RJSFSchema;
        }

        const versionSchema = {
            type: 'object',
            required: ['version'],
            properties: {
                version: {
                    type: 'string',
                    title: 'version',
                },
            },
        };
        return versionSchema as RJSFSchema;
    }

    if (node.type === AiosType.Access) {
        const currentAccess = (node.item as AiosAccess);
        const accessSchema = {
            title: 'Access',
            type: 'object',
            required: ['actorId', 'accessType'],
            properties: {
                actorId: {
                    type: 'string',
                    title: 'User ID',
                    default: currentAccess?.actorId ?? ''
                },
                accessType: {
                    title: 'Permissions',
                    type: 'object',
                    properties: {
                        read: {
                            type: 'boolean',
                            title: 'Read',
                            default: currentAccess?.accessType ? (currentAccess.accessType & AiosAccessType.Read) === AiosAccessType.Read : true
                        },
                        chat: {
                            type: 'boolean',
                            title: 'Chat',
                            default: currentAccess?.accessType ? (currentAccess.accessType & AiosAccessType.Chat) === AiosAccessType.Chat : false
                        },
                        code: {
                            type: 'boolean',
                            title: 'Code',
                            default: currentAccess?.accessType ? (currentAccess.accessType & AiosAccessType.Code) === AiosAccessType.Code : false
                        },
                        cref: {
                            type: 'boolean',
                            title: 'Cref',
                            default: currentAccess?.accessType ? (currentAccess.accessType & AiosAccessType.Cref) === AiosAccessType.Cref : false
                        }
                    }
                }
            },
            uiSchema: {
                actorId: {
                    'ui:widget': 'ActorIdWidget',
                    'ui:options': {
                        anonGuid: anon
                    }
                }
            }
        };
        return accessSchema as RJSFSchema;
    }
    const o = {};
    return o as RJSFSchema;
}
