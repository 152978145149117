import {
  type AiosData,
  AiosDataCommand,
  type AiosDataInput,
  type AiosDataOutput,
  AiosStatus,
  AiosType,
  createAiosData,
  set,
} from '../AiosUtil';

export interface AiosAction extends AiosData {
  command?: AiosDataCommand;
  type?: AiosType;
  inputText?: string;
  outputText?: string;
  input?: AiosDataInput<AiosData>;
  output?: Partial<AiosDataOutput<AiosData>>;
  status?: AiosStatus;
  accessId?: string;
  timeout?: number;
  size?: number;
  time?: number;
  cost?: number;
}

export function createAiosAction(options?: Partial<AiosAction>): AiosAction {
  const action = createAiosData(options) as AiosAction;
  action.command = set(options?.command, AiosDataCommand.None);
  action.type = set(options?.type, AiosType.None);
  action.inputText = set(options?.inputText, '');
  action.outputText = set(options?.outputText, '');
  action.input = set(options?.input, undefined);
  action.output = set(options?.output, undefined);
  action.status = set(options?.status, AiosStatus.None);
  action.accessId = set(options?.accessId, '');
  action.timeout = set(options?.timeout, undefined);
  action.size = set(options?.size, 0);
  action.time = set(options?.time, 0);
  action.cost = set(options?.cost, 0);
  return action;
}
