import {
  type AiosAccount,
  type AiosAsset,
  type AiosObject,
  AiosType,
  createAiosObject,
  is,
  isText,
} from 'aios';
import { type AiosNode } from '../node';
import { type AiosNodeData } from './';
import { textToBase64 } from 'utl';

export function getData(node: AiosNode): AiosNodeData {
  const data: AiosNodeData = {
    path: node.path,
    type: node.type,
    item: node.item,
  };
  switch (data.type) {
    case AiosType.Account:
      data.account = data.item as AiosAccount;
      break;
    case AiosType.Asset:
      data.asset = data.item as AiosAsset;
      data.assetPath = data.asset?.path;
      data.assetFile = data.asset?.file;
      data.assetFileName = data.asset?.name;
      data.assetFileType = data.assetFile?.type;
      data.assetFileText = data.assetFile?.text;
      data.assetFileBase = data.assetFile?.base;
      data.assetFileMime = data.assetFile?.mime;
      if (is(data.assetFileBase) && is(data.assetFileMime)) {
        data.assetFileDataUrl = `data:${data.assetFileMime};base64,${data.assetFileBase}`;
        break;
      }
      if (isText(data.assetFileType) && is(data.assetFileText) && is(data.assetFileMime)) {
        data.assetFileBase = textToBase64(data.assetFileText);
        data.assetFileDataUrl = `data:${data.assetFileMime};base64,${data.assetFileBase}`;
      }
      data.version = data.asset?.version;
      break;
  }
  const createJsonObject = (jsonNode: AiosNode): AiosObject => {
    let childNodes;
    if (is(jsonNode.nodes)) {
      childNodes = jsonNode.nodes.map(c => createJsonObject(c))
    }
    const jsonObject = createAiosObject({
      path: jsonNode.path,
      type: jsonNode.type,
      item: jsonNode.item,
      nodes: childNodes
    }) as AiosObject;
    return jsonObject;
  }
  data.jsonObject = createJsonObject(node);
  data.jsonText = JSON.stringify(data.jsonObject, undefined, 2);
  if (is(data.jsonText)) {
    const blob = new Blob([data.jsonText], { type: 'application/json' });
    data.jsonDataUrl = URL.createObjectURL(blob);
  }
  if (is(node.copies)) {
    data.copyCount = node.copies.length;
  }
  if (node.copyIndex !== undefined) {
    data.copyIndex = node.copyIndex
  }
  return data;
}