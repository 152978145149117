import { AiosStatus, is, getId, AiosType, AiosDataCommand, set } from 'aios';
import { type AiosNode } from 'app/node';
import { getStatus, setStatus } from 'app/status';
import { setApi } from 'app';
import { codeNodeData } from './codeNodeData';

export async function codeNode(node: AiosNode): Promise<void> {
    const status = getStatus(node);
    if (status.code?.do === false) {
        return;
    }
    if (is(status.code?.done)) {
        return;
    }
    if (!is(status.code?.doing)) {
        const id = set(status.code?.id, getId());
        setStatus(node, {
            code: { id, doing: true },
            codeData: { do: true, doing: undefined, done: undefined },
            working: true,
        });
        setApi(node, {
            id,
            input: {
                type: AiosType.ActionCode,
                item: { id: 'user', text: node.input },
                command: AiosDataCommand.Code
            },
            output: {
                type: AiosType.ActionCode,
                item: { id: 'aios', text: '...' },
                status: AiosStatus.Processing,
                progress: 1.0,
            },
        });
        return;
    }
    const done = await codeNodeData(node);
    if (!is(done)) {
        setStatus(node, {
            code: { do: false, doing: false, done: false }, working: false, refresh: true
        });
        setApi(node, {
            id: status.code?.id,
            status: AiosStatus.Fail
        });
        return;
    }
    setApi(node, {
        id: status.code?.id,
        status: AiosStatus.Ok
    });
    setStatus(node, {
        code: { do: false, done: true, doing: false },
        working: false,
        refresh: true
    });
}


