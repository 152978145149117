import { Grid10, Grid10CellButton, Head } from 'components';
import { createAiosPath, is, isId, set } from 'aios';
import { appSignal } from 'signals';
import { AppState } from 'signals/AppState/AppState';
import { useState } from 'react';
import { getConfig } from 'utl';
import { doSignIn, doSignOut, findAiosNode } from 'app';

export const AppHead = (): JSX.Element => {
  const [settings, setSettings] = useState(false);
  let account;
  let waiting;
  let accountString;
  const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
  if (is(aios)) {
    account = aios.account;
    waiting = aios.status?.auth?.doing;
    if (is(account)) {
      accountString = set(account.path, account.id);
    }
  }
  const display = getConfig('REACT_APP_AIOS_DNS');
  const indexToggle = async (): Promise<void> => {
    if (!is(appSignal.value.indexMenu)) {
      appSignal.value.indexMenu = true;
    } else {
      appSignal.value.indexMenu = false;
    }
    AppState.current.refresh();
  };
  const settingsToggle = async (): Promise<void> => {
    if (!is(settings)) {
      setSettings(true);
    } else {
      setSettings(false);
    }
  };

  const handleSignIn = async (): Promise<void> => {
    setSettings(false);
    void doSignIn();
  };

  const handleSignOut = async (): Promise<void> => {
    setSettings(false);
    void doSignOut();
  };

  if (is(settings)) {
    return (
      <Head>
        <Grid10>
          <Grid10CellButton tip='toggle index' icon='menu' onClick={indexToggle} />
          {!is(accountString) ? (
            <Grid10CellButton spanx={7} name={`${display}/aios`} link='aios' />
          ) : (
            <Grid10CellButton spanx={7} name={`${display}/${accountString}`} link={`/${accountString}`} />
          )}
          {!is(accountString) ? (
            <Grid10CellButton tip='sign in' icon='login' onClick={handleSignIn} disabled={waiting} />
          ) : (
            <Grid10CellButton tip='sign out' icon='logout' onClick={handleSignOut} disabled={waiting} />
          )}
          {/* <Grid10CellButton tip='dark mode' icon='dark' selected={!appSignal.value.light} onClick={async () => {
            appSignal.value.light = false;
            AppState.current.refresh();
          }} />
          <Grid10CellButton tip='light mode' icon='light' selected={appSignal.value.light} onClick={async () => {
            appSignal.value.light = true;
            AppState.current.refresh();
          }} /> */}
          <Grid10CellButton tip='close' icon='close' onClick={settingsToggle} />
        </Grid10>
      </Head>
    );
  }
  return (
    <Head>
      <Grid10>
        <Grid10CellButton tip='toggle indev' icon='menu' onClick={indexToggle} />
        {!isId(accountString) ? (
          <Grid10CellButton spanx={8} name={`${display}/aios`} link='aios' />
        ) : (
          <Grid10CellButton spanx={8} name={`${display}/${accountString}`} link={`/${accountString}`} />
        )}
        <Grid10CellButton tip='profile' icon='profile' onClick={settingsToggle} />
      </Grid10>
    </Head>
  );
};
