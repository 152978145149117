import { type AiosNode } from 'app/node/AiosNode';
import { type AiosPath, is } from 'aios';

export function findAiosNode(node: AiosNode, path: AiosPath): AiosNode | undefined {
    if (node.link === path.full) {
        return node;
    }
    if (node.path?.full === path.full) {
        return node;
    }
    if (is(node.nodes) && is(path.full)) {
        for (let i = 0; i < node.nodes.length; i++) {
            const child = node.nodes[i];
            // if (child.path?.account === 'aios' && path.account === 'aios') {
            //     return child;
            // }
            const find = findAiosNode(child, path);
            if (is(find)) {
                return find;
            }
        }
    }
    return undefined;
};