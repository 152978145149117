import {
  type AiosData,
  type AiosDataInput,
  type AiosDataOutput,
  type AiosAction,
} from 'aios';
import { type AiosNode } from '../node';
import { type AiosNodeApiOptions } from './AiosNodeApi';

export function getApi(node: AiosNode): AiosNodeApiOptions {
  const action = node.api.action as AiosAction;  
  const input = action?.input as AiosDataInput<AiosData>;
  const inputText = input?.text;
  const inputType = input?.type;
  const inputItem = input?.item;
  const output = action?.output as AiosDataOutput<AiosData>;
  const outputText = output?.text;
  const outputType = output?.type;
  const outputItem = output?.item;
  const status = output?.status;
  const progress = output?.progress as number;
  const inputValue = node.api.inputValue;
  const inputIndex = node.api.inputIndex;
  return {
    action,    
    input,
    inputText,
    inputType,
    inputItem,
    outputText,
    outputType,
    outputItem,
    status,
    progress,
    inputValue,
    inputIndex
  };
}
