import { is, AiosType, type AiosAsset, AiosFileType, createAiosPath, type AiosAccount, set } from 'aios';
import { getData } from 'app/data';
import { type AiosNode } from 'app/node';

function getFileTypeIcon(fileType: AiosFileType): string {
    switch (fileType) {
        case AiosFileType.Folder: return 'folder';
        case AiosFileType.Markdown: return 'markdown';
        case AiosFileType.Code: return 'code';
        case AiosFileType.Data: return 'data';
        case AiosFileType.Text: return 'text';
        case AiosFileType.Json: return 'json';
        case AiosFileType.Image: return 'image';
        case AiosFileType.Audio: return 'audio';
        case AiosFileType.Model: return 'model';
        case AiosFileType.Video: return 'video';
        default: return 'unknown';
    }
}

export function getIcon(node: AiosNode): string {
    const { type, item, path: { path, version, mode } } = node;
    if (path === 'aios') {
        return 'aios';
    }
    if (is(mode)) {
        return 'access';
    }
    if (is(version)) {
        return 'version';
    }
    if (type === AiosType.Account) {
        return 'account';
    }
    if (type === AiosType.Asset) {
        const asset = item as AiosAsset;
        if (is(asset) && asset.file?.type) {
            return set(getFileTypeIcon(asset.file.type), 'asset');
        }
        return 'asset';
    }
    return 'aios';
}

export function getName(node: AiosNode): string {
    if (node.type === AiosType.AiosNodeAios) {
        return 'system';
    }
    if (node.type === AiosType.Account) {
        const accountName = (node.item as AiosAccount)?.path;
        if (is(accountName)) {
            return accountName;
        }
    }
    // if (node.type === AiosType.Asset) {
    //     const assetName = (node.item as AiosAsset)?.name;
    //     if (is(assetName)) {
    //         return assetName;
    //     }
    // }
    const linkPath = createAiosPath({ path: node.link });
    const { path, name, version, mode, modeId } = linkPath;
    if (is(mode)) {
        return is(modeId) ? modeId : 'access';
    }
    if (is(version)) {
        return version;
    }
    if (is(name) && name.includes('new')) {
        return name;
    }
    if (is(name)) {
        return name;
    }
    if (is(path)) {
        return path;
    }
    return '?';
}

export function sortByName(a: AiosNode, b: AiosNode): number {
    return getName(a).localeCompare(getName(b));
}

export function sortByVersion(a: AiosNode, b: AiosNode): number {
    const versionA = getName(a);
    const versionB = getName(b);
    const aParts = versionA.split('.').map(Number);
    const bParts = versionB.split('.').map(Number);

    // Compare each part of the version number
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = set(aParts[i], 0);
        const bPart = set(bParts[i], 0);

        if (aPart > bPart) return -1; // Newer version comes first
        if (aPart < bPart) return 1;  // Older version comes later
    }

    return 0; // Same version
}

export function getPath(node: AiosNode): string {
    if (!is(node.path.full)) {
        return 'aios';
    }
    return node.path.full;
}

export function getPublicIcon(node: AiosNode): string {
    const { assetFileType } = getData(node);
    if (assetFileType) {
        return set(getFileTypeIcon(assetFileType), 'aios');
    }
    return 'aios';
}

export function setMode(node: AiosNode): void {
    const { ui } = node;
    if (ui.mode === 'data') {
        ui.view = 'form';
    } else {
        if (ui.view === 'form') {
            ui.view = undefined;
        }
        const { assetFileType } = getData(node);
        if (assetFileType !== AiosFileType.Image) {
            if (!is(node.status.edit) && !is(node.status.editEx)) {
                ui.view = getView(node);
            } else {
                ui.view = 'code';
            }
        } else {
            ui.view = 'image';
        }
    }
}

export function getView(node: AiosNode): string {
    const { assetFileType } = getData(node);
    switch (assetFileType) {
        case AiosFileType.Markdown:
            return 'markdown';
        case AiosFileType.Json:
            return 'json';
        case AiosFileType.Code:
            return 'code';
        case AiosFileType.Text:
            return 'text';
        case AiosFileType.Image:
            return 'image';
    }
    return '';
}
