import { AiosType, is, isFolder, set } from 'aios';
import { type AiosNode } from '../../node';
import { type AiosNodeUi } from './AiosNodeUi';
import { getUiSchema } from './getUiSchema';
import { getIcon, getPath, getView } from '../../util';
import { owner } from 'app/node/aios/authNode';
import { getUiViewGuest } from './getUiGuest';
import { getData } from 'app/data';
import { getFunctions } from './getFunctions';

export function getUiView(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    if (!is(owner(node))) {
        return getUiViewGuest(node, ui);
    }
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.schema = getUiSchema(node);
    ui.disabled = true;
    if (node.type === AiosType.Account) {
        ui.body = true;
        ui.mode = 'data';
        ui.view = 'form';
        ui.more = 'less';
        ui.expand = 'collapse';
        return ui;
    }
    if (node.type === AiosType.Asset) {
        return getViewAssetUi(node, ui);
    }
    if (node.type === AiosType.Access) {
        ui.body = true;
        ui.mode = 'data';
        ui.view = 'form';
        ui.more = 'less';
        ui.expand = 'collapse';
    }
    return ui;
}

function getViewAssetUi(node: AiosNode, ui: AiosNodeUi): AiosNodeUi {
    const functions = getFunctions(node);
    const { assetFileType } = getData(node);
    ui.icon = getIcon(node);
    ui.path = getPath(node);
    ui.body = true;
    ui.schema = getUiSchema(node);    
    if (isFolder(assetFileType)) {
        ui.mode = 'data';
        ui.view = 'form';
    } else {
        ui.foot = true;
        ui.mode = set(ui.mode, 'file');
        ui.onMode = functions.onMode;
        ui.view = set(ui.view, getView(node));
        ui.onView = functions.onView;
    }
    ui.more = 'less';
    ui.expand = 'collapse';
    ui.disabled = true;
    return ui;
}

