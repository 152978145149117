import {
    is,
    createAiosPath,
    AiosFileType,
    AiosType,
    createAiosAsset,
    createAiosFile,
    createAiosAccess,
    type AiosAsset,
    append,
    type AiosAccount,
    type AiosAccess,
} from 'aios';
import { createAiosNode, type AiosNode } from '../AiosNode';
import { getData } from '../../data';
import { sampleAudio } from './sampleAudio';
import { sampleCode } from './sampleCode';
import { sampleData } from './sampleData';
import { sampleImage } from './sampleImage';
import { sampleModel } from './sampleModel';
import { sampleMarkdown, sampleText, sampleJson } from './sampleText';
import { sampleVideo } from './sampleVideo';
import { sortNode } from 'app/util';

export async function appendChild(node: AiosNode, child: AiosNode): Promise<void> {
    const existing = node.nodes?.some(existingChild => existingChild.path?.path === child.path?.path);
    if (!is(existing)) {
        node.nodes = append(node.nodes, child);
        sortNode(node);
    }
    const nodeItem = node.item;
    const childItem = child.item;
    if (!is(nodeItem) || !is(childItem)) {
        return;
    }
    switch (node.type) {
        case AiosType.Account:
            if (child.type === AiosType.Asset) {
                const account = nodeItem as AiosAccount;
                if (account.assets != null && childItem.path != null &&
                    !account.assets.some(asset => asset.path === childItem.path)) {
                    account.assets = append(account.assets, childItem as AiosAsset);
                }
            }
            break;
        case AiosType.Asset:
            {
                const nodeAsset = nodeItem as AiosAsset;
                if (!is(nodeAsset.version)) {
                    const childAsset = childItem as AiosAsset;
                    if (node.type === AiosType.Asset) {
                        if (!is(childAsset.version)) {
                            if (nodeAsset.assets != null && childItem.path != null &&
                                !nodeAsset.assets.some(asset => asset.path === childItem.path)) {
                                nodeAsset.assets = append(nodeAsset.assets, childAsset);
                            }
                        } else {
                            if (nodeAsset.versions != null && childItem.path != null &&
                                !nodeAsset.versions.some(version => version.path === childItem.path)) {
                                nodeAsset.versions = append(nodeAsset.versions, childAsset);
                            }
                        }
                    }
                } else {
                    if (child.type === AiosType.Access) {
                        if (nodeAsset.accesses != null && childItem.path != null &&
                            !nodeAsset.accesses.some(access => access.path === childItem.path)) {
                            nodeAsset.accesses = append(nodeAsset.accesses, childItem as AiosAccess);
                        }
                    }
                }
            }
            break;
    }
}

export function createNode(node: AiosNode, fileType?: AiosFileType): AiosNode {
    if (is(fileType)) {
        return createAssetNode(node, fileType);
    }
    if (node.type === AiosType.Account) {
        if (!is(node.nodes)) {
            return createAssetNode(node, AiosFileType.Markdown);
        }
        return createAssetNode(node, AiosFileType.Folder);
    }
    if (node.type === AiosType.Asset) {
        if (!is(node.path?.version)) {
            const { assetFileType } = getData(node);
            if (assetFileType === AiosFileType.Folder) {
                return createAssetNode(node, AiosFileType.Markdown);
            }
            return createAssetVersionNode(node);
        }
        return createAccessNode(node);
    }
    return createAiosNode();
}

function getUniqueFileName(node: AiosNode, baseName: string, extension: string, fileText?: string, fileBase?: string): string {
    // Todo: if incomming file data set it to the file
    const { nodes } = node;
    if (!is(nodes)) return is(extension) ? `${baseName}.${extension}` : baseName;

    // Check if exact filename exists first
    const exactName = is(extension) ? `${baseName}.${extension}` : baseName;
    const hasExactFile = nodes.some(child => {
        const childName = child.path?.path?.split('/').pop();
        return is(childName) && childName === exactName;
    });

    if (!hasExactFile) {
        return exactName;
    }

    // If exact file exists, find next available number
    let counter = 0;
    const baseWithoutNumber = baseName.replace(/\d+$/, '');
    while (true) {
        const testName = is(extension) ?
            `${baseWithoutNumber}${counter}.${extension}` :
            `${baseWithoutNumber}${counter}`;
        const exists = nodes.some(child => {
            const childName = child.path?.path?.split('/').pop();
            return is(childName) && childName === testName;
        });
        if (!exists) {
            return testName;
        }
        counter++;
    }
}

function createTypedAsset(node: AiosNode, baseName: string, extension: string, type: AiosFileType, content?: string | undefined, base64?: string | undefined, mime?: string): AiosAsset {
    const parentPath = is(node?.path?.path) ? node.path.path : '';
    const fileName = getUniqueFileName(node, baseName, extension);
    const filePath = `${parentPath}/${fileName}`;

    return createAiosAsset({
        path: filePath,
        name: fileName,
        file: createAiosFile({
            type,
            path: filePath,
            text: content,
            base: base64,
            mime: is(mime) ? mime : ''
        }),
    });
}

export function createAssetNode(node: AiosNode, type: AiosFileType): AiosNode {
    let asset: AiosAsset;
    switch (type) {
        case AiosFileType.Folder:
            asset = createTypedAsset(node, 'folder', '', AiosFileType.Folder);
            break;
        case AiosFileType.Markdown:
            asset = createTypedAsset(node, 'readme', 'md', AiosFileType.Markdown, sampleMarkdown, undefined, 'application/markdown');
            break;
        case AiosFileType.Code:
            asset = createTypedAsset(node, 'code', 'ts', AiosFileType.Code, sampleCode, undefined, 'application/typescript');
            break;
        case AiosFileType.Data:
            asset = createTypedAsset(node, 'data', 'dat', AiosFileType.Data, undefined, sampleData);
            break;
        case AiosFileType.Text:
            asset = createTypedAsset(node, 'text', 'txt', AiosFileType.Text, sampleText, undefined, 'text/plain');
            break;
        case AiosFileType.Json:
            asset = createTypedAsset(node, 'json', 'json', AiosFileType.Json, sampleJson, undefined, 'application/json');
            break;
        case AiosFileType.Image:
            asset = createTypedAsset(node, 'image', 'png', AiosFileType.Image, undefined, sampleImage, 'image/png');
            break;
        case AiosFileType.Audio:
            asset = createTypedAsset(node, 'sound', 'mp3', AiosFileType.Audio, undefined, sampleAudio, 'audio/mpeg');
            break;
        case AiosFileType.Video:
            asset = createTypedAsset(node, 'video', 'mp4', AiosFileType.Video, undefined, sampleVideo, 'video/mp4');
            break;
        case AiosFileType.Model:
            asset = createTypedAsset(node, 'model', 'gltf', AiosFileType.Model, sampleModel, undefined, 'application/gltf');
            break;
        default:
            asset = createTypedAsset(node, 'text', 'txt', AiosFileType.Text, 'this is text', undefined, 'text/plain');
            break;
    }

    return createAiosNode({
        parent: node,
        type: AiosType.Asset,
        path: createAiosPath({ path: asset.path }),
        item: asset
    });
}

export function createAssetVersionNode(node: AiosNode, newVersion?: string): AiosNode {
    if (!is(newVersion)) {
        newVersion = '0.0.0';
        const { nodes } = node;
        if (is(nodes)) {
            let highestVersion = { major: 0, minor: 0, patch: 0 };
            for (const child of nodes) {
                const version = child.path.version;
                if (!is(version)) {
                    continue;
                }
                const match = version.match(/(\d+)\.(\d+)\.(\d+)/);
                if (is(match)) {
                    const [, major, minor, patch] = match.map(Number);
                    if (major > highestVersion.major ||
                        (major === highestVersion.major && minor > highestVersion.minor) ||
                        (major === highestVersion.major && minor === highestVersion.minor && patch > highestVersion.patch)) {
                        highestVersion = { major, minor, patch };
                    }
                }
            }
            newVersion = `${highestVersion.major}.${highestVersion.minor}.${highestVersion.patch + 1}`;
        }
    }
    const path = `${node.path.account as string}@${newVersion}/${node.path.assetPath as string}`;
    const { assetFile } = getData(node);
    const file = createAiosFile({ ...assetFile, path });
    const asset = createAiosAsset({
        id: undefined,
        path,
        file
    });
    const nodePath = createAiosPath({ path });
    return createAiosNode({
        parent: node,
        type: AiosType.Asset,
        path: nodePath,
        link: nodePath.full,
        item: asset
    });
}

export function createAccessNode(node: AiosNode): AiosNode {
    const path = `${node.path.account as string}@${node.path.version as string}/${node.path.assetPath as string}?mode=access&modeId=new`;
    const access = createAiosAccess({
        parent: node.path.path,
        path,
    });
    const nodePath = createAiosPath({ path });
    return createAiosNode({
        parent: node,
        type: AiosType.Access,
        path: nodePath,
        link: nodePath.full,
        item: access
    });
}

export function generateNewNodeName(node: AiosNode, prefix: string): string {
    const { nodes } = node;
    if (!is(nodes)) {
        return `${prefix}1`;
    }
    let maxNumber = 0;
    for (const child of nodes) {
        const link = child.link;
        if (is(link) && link.includes(prefix)) {
            const index = link.indexOf(prefix);
            const numStr = link.slice(index + prefix.length);
            const num = parseInt(numStr, 10);
            if (!isNaN(num)) {
                maxNumber = Math.max(maxNumber, num);
            }
        }
    }
    return `${prefix}${maxNumber + 1}`;
}

export function createNodeFile(node: AiosNode, fileName: string, fileText?: string): AiosNode {
    const fileType = getFileTypeFromExtension(fileName);
    const parentPath = is(node?.path?.path) ? node.path.path : '';
    const baseName = fileName.split('.')[0];
    const extension = fileName.split('.').pop() ?? '';
    const newName = getUniqueFileName(node, baseName, extension);
    let filePath = newName;
    if (!newName.startsWith(parentPath)) {
        filePath = `${parentPath}/${newName}`;
    }
    const file = createAiosFile({
        type: fileType,
        path: filePath,
        text: fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
            fileType === AiosFileType.Video || fileType === AiosFileType.Data
            ? undefined
            : fileText,
        base: fileType === AiosFileType.Image || fileType === AiosFileType.Audio ||
            fileType === AiosFileType.Video || fileType === AiosFileType.Data
            ? fileText
            : undefined,
        mime: getMimeType(fileName)
    });

    const asset = createAiosAsset({
        path: filePath,
        name: newName,
        file
    });

    return createAiosNode({
        parent: node,
        type: AiosType.Asset,
        path: createAiosPath({ path: filePath }),
        item: asset
    });
}

export function getFileTypeFromExtension(fileName: string): AiosFileType {
    const ext = fileName.toLowerCase().split('.').pop();
    switch (ext) {
        case 'md':
            return AiosFileType.Markdown;
        case 'ts':
        case 'js':
        case 'py':
        case 'cpp':
        case 'cs':
            return AiosFileType.Code;
        case 'txt':
            return AiosFileType.Text;
        case 'json':
            return AiosFileType.Json;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return AiosFileType.Image;
        case 'mp3':
        case 'wav':
            return AiosFileType.Audio;
        case 'mp4':
        case 'webm':
            return AiosFileType.Video;
        case 'gltf':
        case 'glb':
            return AiosFileType.Model;
        case 'dat':
            return AiosFileType.Data;
        default:
            return AiosFileType.Text;
    }
}

function getMimeType(fileName: string): string {
    const ext = fileName.toLowerCase().split('.').pop();
    switch (ext) {
        case 'md': return 'application/markdown';
        case 'ts': return 'application/typescript';
        case 'js': return 'application/javascript';
        case 'txt': return 'text/plain';
        case 'json': return 'application/json';
        case 'png': return 'image/png';
        case 'jpg':
        case 'jpeg': return 'image/jpeg';
        case 'gif': return 'image/gif';
        case 'mp3': return 'audio/mpeg';
        case 'wav': return 'audio/wav';
        case 'mp4': return 'video/mp4';
        case 'webm': return 'video/webm';
        case 'gltf': return 'application/gltf';
        default: return '';
    }
}

