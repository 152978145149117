import { AiosStatus, is, getId, isFolder, remove, createAiosPath, AiosType, isId } from 'aios';
import { appendChild, resetCopies, type AiosNode } from '../../node';
import { saveNodeNext } from './saveNodeNext';
import { getStatus, setStatus, setStatusEx, nullState } from '../..//status';
import { AppState } from 'signals/AppState/AppState';
import { getData, getPath, setApi } from '../../';
import { remCache, setCache, setCacheEx } from '../../cache';
import { updateOwner } from '../aios/authNode';
import { aliasNode } from '../load/loadNodeData';

export async function saveNode(node: AiosNode): Promise<void> {
    const { editEx, save, saveData } = getStatus(node);
    if (is(saveData?.doing)) {
        setStatus(node, { working: false, refresh: false });
        return;
    }
    if (save?.do === false || is(save?.done)) {
        setStatus(node, { working: false, refresh: false });
        return;
    }
    if (!is(save?.doing)) {
        const id = getId();
        setStatus(node, {
            save: { id, doing: true },
            saveNext: { do: true, doing: undefined, done: undefined },
            saveMore: is(editEx) ? { do: true, doing: undefined, done: undefined } : undefined,
            working: true,
        });
        setApi(node, {
            id,
            output: {
                text: `saving ${getPath(node)}`,
                status: AiosStatus.Processing
            }
        });
        return;
    }
    const done = await saveNodeNext(node);
    if (done === undefined) {
        setStatus(node, { working: true });
        return;
    }
    if (!is(done)) {
        setApi(node, {
            id: save?.id,
            output: {
                text: `saving ${getPath(node)}`,
                status: AiosStatus.Fail,
                fields: node.api.action?.output?.fields,
            }
        });
        const { assetFileType } = getData(node);
        setStatusEx(node, {
            save: nullState,
            edit: !isFolder(assetFileType),
            editEx: isFolder(assetFileType),
            working: false, refresh: true
        });
        await setCache(node);
        return;
    }
    setStatusEx(node, {
        load: { done: true },
        save: { do: false, done: true, doing: false },
        edit: false, editEx: false, working: false, refresh: true
    });
    const redirect = await config(node);
    await setCache(node);
    setApi(node, {
        id: save?.id,
        output: {
            text: `saved ${getPath(node)}`,
            status: AiosStatus.Ok
        }
    });
    resetCopies(node);
    if (is(node.nodes)) {
        for (const child of node.nodes) {
            const { create, createEx } = getStatus(child);
            if (is(create) || is(createEx)) {
                child.link = child.path.full;
                await setCache(child);
            }
        }
    }
    if (is(redirect)) {
        AppState.current.go(redirect);
    }
}

async function config(node: AiosNode): Promise<string | undefined> {
    updateNodes(node);
    updateOwner(node);
    const parent = node.parent;
    if (is(node.status.create) || is(node.status.createEx)) {
        if (is(parent)) {
            parent.nodes = remove(parent.nodes, node);
        }
        await remCache(node);
        await setCache(node);
        if (is(parent)) {
            parent.nodes = remove(parent.nodes, node);
            await appendChild(parent, node);
            await setCache(parent);
        }
        return node.link;
    }
    if (is(parent)) {
        parent.item = node.item;
        parent.status.loadEx = node.status.loadEx;
        parent.status.save = node.status.save;
        if (!is(node.status.versionEx)) {
            parent.nodes = node.nodes;
        } else {
            parent.nodes = remove(parent.nodes, node);
            if (is(node.nodes)) {
                for (const child of node.nodes) {
                    await appendChild(parent, child);
                }
            }
        }
        await setCacheEx(parent);
        return parent.link;
    }
}

function updateNodes(node: AiosNode): void {
    if (is(node.status.create) || is(node.status.createEx)) {
        const alias = node.path.account;
        const newItem = node.api.action?.output?.item;
        if (is(newItem) && is(node.item) && is(newItem?.id)) {
            node.item.id = newItem.id;
            node.item.path = newItem.path;
            if (node.type === AiosType.Access) {
                node.path = createAiosPath({ path: newItem.path });
            }
        }
        if (is(alias) && !isId(alias)) {
            aliasNode(node, alias);
        }
        node.link = node.path.full;
    }
    if (is(node.nodes)) {
        for (const child of node.nodes) {
            updateNodes(child);
        }
    }
}